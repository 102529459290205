import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";

const DesktopMenuOptionItem = ({ icon, title, onClick, referenceRoute }) => {
  const location = useLocation();
  const [active, setActive] = useState(false);

  const segments = location?.pathname.split("/");

  // Filter out empty segments
  const filteredSegments = segments.filter((segment) => segment.trim() !== "");

  // Extract the first non-empty segment
  const firstSegment = filteredSegments.length > 0 ? filteredSegments[0] : "/";

  useEffect(() => {
    if (
      referenceRoute &&
      referenceRoute.some((route) => firstSegment?.includes(route))
    ) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [location, referenceRoute]);

  return (
    <Grid
      container
      sx={{
        display: "flex",
        alignItems: "center",
        padding: "5px 0px",
        // marginBottom: window.innerHeight > 550 ? "15px" : "10px",
        // width: itemWidth,
      }}
      onClick={onClick}
    >
      <Box
        sx={{
          backgroundColor: active ? "#FFFFFF" : "transparent",
          borderRadius: active ? "30px" : "0px",
          cursor: "pointer",
          height: window?.innerHeight < 600 ? "55px" : "60px",
          padding: "0px 15px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Grid
          item
          sx={{
            width: "27px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {icon}
        </Grid>
        <Grid item>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: active ? "600" : "500",
              fontFamily: "inherit",
              color: "#3D3D3D",
              marginLeft: "10px",
            }}
          >
            {title}
          </Typography>
        </Grid>
      </Box>
    </Grid>
  );
};

export default DesktopMenuOptionItem;
