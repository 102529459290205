import React, { useEffect, useRef, useState } from "react";
import JWPlayer from "@jwplayer/jwplayer-react";
import { Box, Grid, Typography } from "@mui/material";
import CustomVideoOptions from "./CustomVideoOptions";
import { useNavigate } from "react-router-dom";
import moment from "moment";
//Redux/api
import {
  fetchPostComments,
  fetchPostReactions,
  setWatchedTimelineVideo,
} from "../../redux/slices/homeSlice";
import { useDispatch, useSelector } from "react-redux";
//Icons
import LikeIcon from "../../assets/icons/LikeIcon";
import CommentIcon from "../../assets/icons/CommentIcon";
import ShareIcon from "../../assets/icons/ShareIcon";
import { ChevronLeft } from "@mui/icons-material";
import BottomSignInPrompt from "../layout/BottomSignInPrompt";
import LoadingSpinner from "../loadingSpinner/LoadingSpinner";

const PlayerContainer = ({ postDetails }) => {
  const contentRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { postReactions, postComments } = useSelector((state) => state.home);
  const { access_token } = useSelector((state) => state.auth);
  const [loaded, setLoaded] = useState(false);
  const [readMore, setReadMore] = useState(false);
  const [players, setPlayers] = useState({});

  const [isLiked, setIsLiked] = useState(postReactions?.liked_by_user);
  const [reactionsCount, setReactionsCount] = useState(
    postReactions?.count || 0
  );

  const postDate = moment(
    postDetails?.created_at,
    "DD-MM-YYYY h:mm A"
  ).fromNow();

  useEffect(() => {
    const loadPlayerLibrary = () => {
      const src = "https://cdn.jwplayer.com/libraries/mAzEIK3H.js";
      const script = document.createElement("script");
      script.src = src;
      script.type = "text/javascript";
      script.onload = () => setLoaded(true);
      document.body.append(script);
    };

    loadPlayerLibrary();
  }, [postDetails]);

  const didMountCallback = ({ player, id }) => {
    setPlayers((prevPlayers) => ({ ...prevPlayers, [id]: player }));
    const eventLog = document.getElementById("log");

    player.on("all", (event) => {
      const li = document.createElement("li");
      li.innerText = `${id}: ${event}`;
      eventLog.prepend(li);
    });
  };

  const onBeforePlay = (event) => {
    Object.keys(players).forEach((playerId) => {
      const player = players[playerId];
      const isPlaying = player.getState() === "playing";
      if (isPlaying) {
        player?.pause();
      }
    });
  };

  const handleVideoEnded = () => {
    if (postDetails?.is_timeline_video) {
      console.log("Watched");
      dispatch(setWatchedTimelineVideo(true));
    }
  };
  useEffect(() => {
    dispatch(fetchPostComments(postDetails?.slug));
    dispatch(fetchPostReactions(postDetails?.slug));
  }, [postDetails?.slug]);

  useEffect(() => {
    if (postReactions) {
      setIsLiked(postReactions?.liked_by_user);
      setReactionsCount(postReactions?.count);
    }
  }, [postReactions]);

  const width = window.innerWidth;
  const configDefaults = {
    width: width,
    height: "100%",
    content: {
      display: "none",
    },
    displaytitle: false,
    displaydescription: false,
  };
  const hadnleReadMore = () => {
    setReadMore(!readMore);
  };

  useEffect(() => {
    if (!readMore && contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  }, [readMore]);

  return loaded ? (
    <Box sx={{ height: "100vh", position: "relative" }}>
      <Box
        sx={{
          position: "absolute",
          left: 0,
          top: 10,
          zIndex: 10,
          width: "50px",
        }}
        onClick={() => navigate(-1)}
      >
        <ChevronLeft sx={{ fontSize: "40px", color: "#FFFFFF" }} />
      </Box>
      <JWPlayer
        config={configDefaults}
        onBeforePlay={onBeforePlay}
        didMountCallback={didMountCallback}
        onComplete={handleVideoEnded}
        playlist={`https://cdn.jwplayer.com/v2/media/${postDetails.media_id}`}
      />

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          position: "absolute",
          right: 0,
          top: "30%",
        }}
      >
        <CustomVideoOptions
          type="like"
          isLiked={isLiked}
          setIsLiked={setIsLiked}
          amount={reactionsCount}
          setReactionsCount={setReactionsCount}
          postId={postDetails?.slug}
          icon={
            <LikeIcon
              color={"#FFFFFF"}
              width={"29"}
              height={"29"}
              noOpacity
              filled={isLiked}
            />
          }
        />

        <CustomVideoOptions
          type="comment"
          amount={postComments?.length > 0 ? postComments?.length : 0}
          postId={postDetails?.slug}
          icon={
            <CommentIcon
              color={"#FFFFFF"}
              width={"29"}
              height={"29"}
              noOpacity
            />
          }
        />
        <CustomVideoOptions
          type="share"
          postDetails={postDetails}
          icon={<ShareIcon />}
        />
      </Box>
      <Box
        sx={{
          color: "#FFFFFF",
          zIndex: 10,
          position: "fixed",
          bottom: 70,
          width: window.innerWidth - 20,
          paddingLeft: "10px",
        }}
      >
        <Typography
          sx={{
            fontFamily: "inherit",
            fontSize: "17px",
            fontWeight: "500",
            color: "#FBFBFB",
          }}
        >
          {postDetails?.title?.charAt(0).toUpperCase() +
            postDetails?.title?.slice(1)}
        </Typography>
        <Typography
          sx={{
            fontFamily: "inherit",
            fontSize: "13px",
            fontWeight: "500",
            opacity: 0.9,
            color: "#FBFBFB",
            margin: "5px 0px",
          }}
        >
          {postDate?.charAt(0).toUpperCase() + postDate?.slice(1)}
        </Typography>
        {postDetails?.description && (
          <Typography
            ref={contentRef}
            sx={{
              color: "rgba(255, 255, 255, 0.90)",
              fontSize: "12px",
              fontWeight: "500",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              WebkitLineClamp: !readMore && 2,
              textOverflow: "ellipsis",
              maxHeight: !readMore ? "40px" : "150px",
              overflowY: readMore && "scroll",
              "& p": {
                margin: "0 !important",
              },
            }}
            dangerouslySetInnerHTML={{
              __html: postDetails?.description,
            }}
          />
        )}

        {postDetails?.description && postDetails?.description.length > 105 && (
          <Typography
            sx={{
              color: "rgba(255, 255, 255, 0.90)",
              fontSize: "10px",
              fontWeight: "500",
              cursor: "pointer",
            }}
            onClick={() => hadnleReadMore()}
          >
            Read {readMore ? "less" : "more"}
          </Typography>
        )}

        {postDetails?.phase && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              marginTop: "5px",
            }}
          >
            <Box
              key={postDetails?.phase?.title}
              sx={{
                height: "23px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: postDetails?.phase?.color,
                borderRadius: "2px",
                padding: "0px 7px",
                marginRight: "5px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "inherit",
                  fontSize: "10px",
                  fontWeight: "500",
                  color: postDetails?.phase?.text_color
                    ? postDetails?.phase?.text_color
                    : "rgba(61, 61, 61, 0.80)",
                }}
              >
                {postDetails?.phase?.title?.charAt(0).toUpperCase() +
                  postDetails?.phase?.title?.slice(1)}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
      {!access_token && <BottomSignInPrompt />}
    </Box>
  ) : (
    <LoadingSpinner />
  );
};

export default PlayerContainer;
