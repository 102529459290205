import React, { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Grid,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

//Components
import DrawerListItem from "./DrawerListItem";

//Redux
import { logoutUser } from "../../redux/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
//Icons
import CloseIcon from "../../assets/icons/headerIcons/CloseIcon";
import HomeIcon from "../../assets/icons/headerIcons/HomeIcon";
import TimelineIcon from "../../assets/icons/headerIcons/TimelineIcon";
import ExperiencesIcons from "../../assets/icons/headerIcons/ExperiencesIcons";
import AboutUsIcon from "../../assets/icons/headerIcons/AboutUsIcon";
import SignInIcon from "../../assets/icons/SignInIcon";
import OrdersIcon from "../../assets/icons/headerIcons/OrdersIcon";
import EllipsisIcon from "../../assets/icons/headerIcons/EllipsisIcon";
import SignOutPinIcon from "../../assets/icons/SignOutPinIcon";

const HeaderLeftDrawer = ({ isDrawerOpen, setIsDrawerOpen }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, winery, access_token } = useSelector((state) => state.auth);

  const [errorImage, setErrorImage] = useState(false);
  const [popoverVisible, setPopoverVisible] = useState(false);

  const handleClick = () => {
    if (!popoverVisible) {
      setPopoverVisible(true);
    } else {
      setPopoverVisible(false);
    }
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };
  const handleOptionClick = (option) => {
    navigate(`/${option === "home" ? "" : option}`);
    setIsDrawerOpen(false);
  };
  return (
    <Drawer
      anchor="left"
      open={isDrawerOpen}
      onClose={handleDrawerClose}
      PaperProps={{
        sx: {
          width: "80%",
          maxWidth: "300px",
          backgroundColor: "#CBC2A8",
        },
      }}
    >
      <Grid
        container
        sx={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Grid
          item
          sx={{
            backgroundImage: `url(${require("../../assets/images/headerImages/header-vector.png")})`,
            backgroundSize: "100% 100%",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            height: "130px",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid
            container
            sx={{
              padding: "20px",
              alignItems: "baseline",
              justifyContent: "space-between",
            }}
          >
            <Grid item>
              {!errorImage ? (
                <>
                  <img
                    src={winery?.logo}
                    alt=""
                    style={{
                      width: "auto",
                      height: "50px",
                      color: "#3D3D3D",
                      fontSize: "18px",
                    }}
                    onError={() => setErrorImage(true)}
                  />
                </>
              ) : (
                <>
                  <Typography
                    sx={{
                      fontFamily: "inherit",
                      fontSize: "18px",
                      color: "#3D3D3D",
                    }}
                  >
                    {winery?.company_name}
                  </Typography>
                </>
              )}
            </Grid>
            <Grid item onClick={handleDrawerClose}>
              <CloseIcon />
            </Grid>
            <Divider
              sx={{
                backgroundColor: "#3D3D3D",
                width: "100%",
                marginTop: "20px",
                padding: "1px 0px",
                borderRadius: "10px",
              }}
            />
          </Grid>
        </Grid>

        <Grid
          item
          sx={{
            padding: "0px 40px",
          }}
        >
          <Grid container sx={{ position: "relative" }}>
            {!winery?.hide_home && (
              <DrawerListItem
                icon={<HomeIcon isDrawer />}
                title="Home"
                onClick={() => handleOptionClick("home")}
              />
            )}
            {!winery?.hide_timeline && (
              <DrawerListItem
                icon={<TimelineIcon isDrawer />}
                title="Timeline"
                onClick={() => handleOptionClick("timeline")}
              />
            )}
            <DrawerListItem
              icon={<ExperiencesIcons isDrawer />}
              title="Experiences"
              onClick={() => handleOptionClick("experiences")}
            />
            {access_token && (
              <DrawerListItem
                icon={<OrdersIcon />}
                title="My Orders"
                onClick={() => handleOptionClick("all-orders")}
              />
            )}
            <DrawerListItem
              icon={<AboutUsIcon />}
              title="About us"
              onClick={() => handleOptionClick("about-us")}
            />
            {!access_token && (
              <DrawerListItem
                icon={<SignInIcon width="18px" height="18px" />}
                title="Sign In/Up"
                onClick={() => navigate("/login")}
              />
            )}

            {popoverVisible && access_token && (
              <Grid
                item
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "row",
                  position: "fixed",
                  bottom: "calc(10% + 50px)",
                  left: 15,
                  width: "236px",
                  height: "42px",
                  backgroundColor: "#FFFFFF",
                  borderRadius: "16px",
                }}
              >
                <Button
                  onClick={() => dispatch(logoutUser(navigate))}
                  sx={{
                    backgroundColor: "#FFFFFF",
                    height: "30px",
                    width: "100%",
                    borderRadius: "10px",
                    fontFamily: "inherit",
                    "&:hover": {
                      backgroundColor: "#FFFFFF",
                      padding: 0,
                    },
                  }}
                >
                  <Box sx={{ position: "fixed", marginTop: "50px" }}>
                    <SignOutPinIcon />
                  </Box>

                  <Typography
                    sx={{
                      fontFamily: "inherit",
                      fontSize: "14px",
                      color: "rgba(61, 61, 61, 0.90)",
                      fontWeight: "600",
                      textTransform: "none",
                    }}
                  >
                    Sign Out
                  </Typography>
                </Button>
              </Grid>
            )}
            {access_token && (
              <Grid
                item
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  position: "fixed",
                  bottom: "10%",
                  left: 19,
                }}
              >
                <Button
                  onClick={() => {
                    navigate("/profile");
                  }}
                  sx={{
                    fontFamily: "inherit !important",
                  }}
                >
                  <Avatar
                    alt={user?.first_name}
                    src={user?.profile_picture ? user?.profile_picture : "/"}
                    sx={{ width: 37, height: 37, backgroundColor: "" }}
                  />

                  <Typography
                    sx={{
                      fontFamily: "inherit",
                      fontSize: "13px",
                      fontWeight: "700",
                      color: "rgba(61, 61, 61, 0.90)",
                      marginLeft: "5px",
                      maxWidth: { xs: "110px", sm: "160px" },
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {user?.first_name} {user?.last_name}
                  </Typography>
                </Button>
                <Button onClick={() => handleClick()}>
                  <EllipsisIcon />
                </Button>
              </Grid>
            )}
            <Grid
              item
              sx={{
                position: "fixed",
                bottom: 10,
                fontSize: "11px",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
                flexDirection: "row",
                left: 30,
              }}
            >
              <Box sx={{ margin: "0px 5px 5px 10px" }}>Powered By </Box>
              <img
                src={require("../../assets/images/winergrower-drawer-logo.png")}
                alt=""
                height={"auto"}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default HeaderLeftDrawer;
