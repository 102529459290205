import React from "react";

const EmailIcon = () => {
  return (
    <svg
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M61.0493 14.9155L37.3613 36.0265C36.8117 36.5165 36.1011 36.7873 35.3648 36.7873C34.6284 36.7873 33.9178 36.5165 33.3683 36.0265L9.58125 14.9275C10.1491 14.7543 10.7396 14.6663 11.3333 14.6665H59.3333C59.9143 14.666 60.4923 14.7499 61.0493 14.9155ZM65.1173 19.0705C65.2583 19.5805 65.3333 20.1145 65.3333 20.6665V50.6665C65.3333 52.2578 64.7011 53.7839 63.5759 54.9092C62.4507 56.0344 60.9246 56.6665 59.3333 56.6665H11.3333C9.74195 56.6665 8.21583 56.0344 7.09061 54.9092C5.96539 53.7839 5.33325 52.2578 5.33325 50.6665V20.6665C5.33325 20.1115 5.40825 19.5745 5.54925 19.0645L29.3753 40.5085C31.0238 41.9777 33.155 42.7896 35.3633 42.7896C37.5715 42.7896 39.7027 41.9777 41.3513 40.5085L65.1173 19.0705Z"
        fill="#666666"
      />
    </svg>
  );
};

export default EmailIcon;
