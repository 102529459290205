import { Grid, Typography } from "@mui/material";
import React from "react";

const DrawerListItem = ({ icon, title, onClick }) => {
  return (
    <Grid
      container
      sx={{
        display: "flex",
        alignItems: "center",
        margin: window.innerHeight > 550 ? "20px 0px" : "10px 0px",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <Grid
        item
        sx={{
          width: "30px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {icon}
      </Grid>
      <Grid item>
        <Typography
          sx={{
            fontSize: "17px",
            fontWeight: "500",
            fontFamily: "inherit",
            color: "#3D3D3D",
          }}
        >
          {title}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default DrawerListItem;
