import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

//CSS
import "./auth.css";

//Icons
import SignInIcon from "../../assets/icons/SignInIcon";
//MUI components
import { Box, Container, Grid, Button } from "@mui/material";
//Validation and Formik
import * as yup from "yup";
import { Form, Formik } from "formik";
//Components
import AuthInputField from "../../components/inputs/AuthInputFIeld";
//Redux
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../redux/slices/authSlice";
//Google Sign in component
import GoogleSignIn from "../../components/googleComponent/GoogleSignIn";
//Header Logo
import HeaderLogo from "../../components/headerLogo/HeaderLogo";
//Helmet Component
import HelmetPage from "../../components/helmetPage/HelmetPage";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { winery, isLoading } = useSelector((state) => state.auth);
  const [errorMessage, setErrorMessage] = useState("");
  const validationSchema = yup.object({
    email: yup
      .string()
      .email("Invalid email format")
      .required("Email is required"),
    password: yup.string().required("Password is required"),
  });
  const handleSubmit = (values) => {
    dispatch(loginUser(values, navigate, setErrorMessage));
  };

  return (
    <Container className="main-container">
      <HelmetPage pageTitle={"Sign In"} />
      <HeaderLogo winery={winery} />

      <Box className="form-container">
        <Box className="header-icon">
          <SignInIcon />
        </Box>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({
            values,
            handleChange,
            handleBlur,
            touched,
            errors,
            isValid,
            isSubmitting,
          }) => (
            <Form>
              <Grid container className="grid-container">
                <div className="form-title">Sign In</div>

                <Grid item xs={12}>
                  <AuthInputField
                    name="email"
                    placeholder="Enter your email"
                    type="email"
                    variant="outlined"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email ? errors.email : ""}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AuthInputField
                    name="password"
                    placeholder="Enter your password"
                    type="password"
                    variant="outlined"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.password && Boolean(errors.password)}
                    helperText={touched.password ? errors.password : ""}
                  />
                </Grid>

                {errorMessage && (
                  <Grid
                    item
                    xs={12}
                    sx={{
                      textAlign: "left",
                      width: "100%",
                      color: "#d32f2f",
                      fontSize: "15px",
                      maxWidth: { xs: "250px", sm: "310px", md: "400px" },
                    }}
                  >
                    <Box>{errorMessage}</Box>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={isLoading || !isValid}
                    sx={{
                      backgroundColor: "#3D3D3D",
                      borderRadius: "10px",
                      marginTop: "10px",
                      textTransform: "none",
                      height: { xs: "40px", sm: "45px" },
                      width: { xs: "250px", sm: "310px", md: "350px" },
                      fontSize: { xs: "16px", sm: "20px" },
                      fontFamily: "inherit",
                      "&:hover": {
                        backgroundColor: "#c88080ff",
                      },
                    }}
                  >
                    Sign In
                  </Button>
                </Grid>

                <Grid item xs={12}>
                  <Box
                    onClick={() => navigate(`/forgot-password`)}
                    sx={{
                      color: "#3D3D3D",
                      fontSize: { xs: "12px", sm: "19px" },
                      fontWeight: "600",
                      cursor: "pointer",
                      marginTop: "10px",
                    }}
                  >
                    Forgot password?
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <GoogleSignIn />
                </Grid>
                <Grid item xs={12}>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      alignItems: "flex-end",
                      justifyContent: "center",
                      flexDirection: "row",
                    }}
                  >
                    <Box
                      sx={{
                        marginTop: "10px",
                        fontSize: { xs: "11px", sm: "18px" },
                        color: "rgba(0, 0, 0, 0.60)",
                      }}
                    >
                      A new member?
                    </Box>
                    <Box
                      onClick={() => navigate(`/register`)}
                      sx={{
                        color: "#3D3D3D",
                        fontSize: { xs: "12px", sm: "19px" },
                        fontWeight: "600",
                        cursor: "pointer",
                        marginLeft: "5px",
                      }}
                    >
                      Sign Up
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </Container>
  );
};

export default Login;
