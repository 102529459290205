import React from "react";

const SignUpIcon = () => {
  return (
    <svg
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.125 7.5C26.1359 7.5 24.2282 8.29018 22.8217 9.6967C21.4152 11.1032 20.625 13.0109 20.625 15C20.625 16.9891 21.4152 18.8968 22.8217 20.3033C24.2282 21.7098 26.1359 22.5 28.125 22.5C30.1141 22.5 32.0218 21.7098 33.4283 20.3033C34.8348 18.8968 35.625 16.9891 35.625 15C35.625 13.0109 34.8348 11.1032 33.4283 9.6967C32.0218 8.29018 30.1141 7.5 28.125 7.5ZM28.125 11.0625C28.6421 11.0625 29.1541 11.1643 29.6318 11.3622C30.1095 11.5601 30.5436 11.8501 30.9092 12.2158C31.2749 12.5814 31.5649 13.0155 31.7628 13.4932C31.9607 13.9709 32.0625 14.4829 32.0625 15C32.0625 15.5171 31.9607 16.0291 31.7628 16.5068C31.5649 16.9845 31.2749 17.4186 30.9092 17.7842C30.5436 18.1499 30.1095 18.4399 29.6318 18.6378C29.1541 18.8357 28.6421 18.9375 28.125 18.9375C27.6079 18.9375 27.0959 18.8357 26.6182 18.6378C26.1405 18.4399 25.7064 18.1499 25.3408 17.7842C24.9751 17.4186 24.6851 16.9845 24.4872 16.5068C24.2893 16.0291 24.1875 15.5171 24.1875 15C24.1875 13.9557 24.6023 12.9542 25.3408 12.2158C26.0792 11.4773 27.0807 11.0625 28.125 11.0625ZM7.5 13.125V18.75H1.875V22.5H7.5V28.125H11.25V22.5H16.875V18.75H11.25V13.125H7.5ZM28.125 24.375C23.1188 24.375 13.125 26.8687 13.125 31.875V37.5H43.125V31.875C43.125 26.8687 33.1313 24.375 28.125 24.375ZM28.125 27.9375C33.6938 27.9375 39.5625 30.675 39.5625 31.875V33.9375H16.6875V31.875C16.6875 30.675 22.5 27.9375 28.125 27.9375Z"
        fill="#3D3D3D"
        fillOpacity="0.7"
      />
    </svg>
  );
};

export default SignUpIcon;
