import React from "react";

const ExpandAccordionIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.58539 2.3526C7.58539 2.11987 7.49294 1.89667 7.32838 1.73211C7.16381 1.56755 6.94062 1.4751 6.70789 1.4751C6.47516 1.4751 6.25197 1.56755 6.08741 1.73211C5.92284 1.89667 5.83039 2.11987 5.83039 2.3526V6.7401H2.02789C1.79516 6.7401 1.57197 6.83255 1.4074 6.99711C1.24284 7.16168 1.15039 7.38487 1.15039 7.6176C1.15039 7.85033 1.24284 8.07352 1.4074 8.23808C1.57197 8.40265 1.79516 8.4951 2.02789 8.4951H5.83039V12.2976C5.83039 12.5303 5.92284 12.7535 6.08741 12.9181C6.25197 13.0826 6.47516 13.1751 6.70789 13.1751C6.94062 13.1751 7.16381 13.0826 7.32838 12.9181C7.49294 12.7535 7.58539 12.5303 7.58539 12.2976V8.4951H11.9729C12.2056 8.4951 12.4288 8.40265 12.5934 8.23808C12.7579 8.07352 12.8504 7.85033 12.8504 7.6176C12.8504 7.38487 12.7579 7.16168 12.5934 6.99711C12.4288 6.83255 12.2056 6.7401 11.9729 6.7401H7.58539V2.3526Z"
        fill="#525861"
      />
    </svg>
  );
};

export default ExpandAccordionIcon;
