import React from "react";

const AboutUsIcon = ({ isMenu, activeDesktop }) => {
  return (
    <>
      {isMenu && activeDesktop ? (
        <svg
          width="27"
          height="27"
          viewBox="0 0 29 29"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.5833 9.91667H12.75V7.08333H15.5833M15.5833 21.25H12.75V12.75H15.5833M14.1667 0C12.3063 0 10.4641 0.366432 8.74532 1.07837C7.02654 1.79031 5.46482 2.83382 4.14932 4.14932C1.49256 6.80609 0 10.4094 0 14.1667C0 17.9239 1.49256 21.5272 4.14932 24.184C5.46482 25.4995 7.02654 26.543 8.74532 27.255C10.4641 27.9669 12.3063 28.3333 14.1667 28.3333C17.9239 28.3333 21.5272 26.8408 24.184 24.184C26.8408 21.5272 28.3333 17.9239 28.3333 14.1667C28.3333 12.3063 27.9669 10.4641 27.255 8.74532C26.543 7.02654 25.4995 5.46482 24.184 4.14932C22.8685 2.83382 21.3068 1.79031 19.588 1.07837C17.8692 0.366432 16.0271 0 14.1667 0Z"
            fill="#3D3D3D"
          />
        </svg>
      ) : isMenu ? (
        <svg
          width="27"
          height="27"
          viewBox="0 0 28 27"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.6 9.45H15.4V6.75H12.6M14 24.3C7.826 24.3 2.8 19.4535 2.8 13.5C2.8 7.5465 7.826 2.7 14 2.7C20.174 2.7 25.2 7.5465 25.2 13.5C25.2 19.4535 20.174 24.3 14 24.3ZM14 0C12.1615 0 10.341 0.349188 8.64243 1.02763C6.94387 1.70606 5.40053 2.70047 4.10051 3.95406C1.475 6.4858 0 9.91958 0 13.5C0 17.0804 1.475 20.5142 4.10051 23.0459C5.40053 24.2995 6.94387 25.2939 8.64243 25.9724C10.341 26.6508 12.1615 27 14 27C17.713 27 21.274 25.5777 23.8995 23.0459C26.525 20.5142 28 17.0804 28 13.5C28 11.7272 27.6379 9.97167 26.9343 8.33377C26.2308 6.69588 25.1995 5.20765 23.8995 3.95406C22.5995 2.70047 21.0561 1.70606 19.3576 1.02763C17.659 0.349188 15.8385 0 14 0ZM12.6 20.25H15.4V12.15H12.6V20.25Z"
            fill="#3D3D3D"
          />
        </svg>
      ) : (
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.75 6.75H8.25V5.25H9.75M9.75 12.75H8.25V8.25H9.75M9 1.5C8.01509 1.5 7.03982 1.69399 6.12987 2.0709C5.21993 2.44781 4.39314 3.00026 3.6967 3.6967C2.29018 5.10322 1.5 7.01088 1.5 9C1.5 10.9891 2.29018 12.8968 3.6967 14.3033C4.39314 14.9997 5.21993 15.5522 6.12987 15.9291C7.03982 16.306 8.01509 16.5 9 16.5C10.9891 16.5 12.8968 15.7098 14.3033 14.3033C15.7098 12.8968 16.5 10.9891 16.5 9C16.5 8.01509 16.306 7.03982 15.9291 6.12987C15.5522 5.21993 14.9997 4.39314 14.3033 3.6967C13.6069 3.00026 12.7801 2.44781 11.8701 2.0709C10.9602 1.69399 9.98491 1.5 9 1.5Z"
            fill="#3D3D3D"
          />
        </svg>
      )}
    </>
  );
};

export default AboutUsIcon;
