import React from "react";
import {
    Routes,
    Route,
    Navigate,
} from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import MainLayout from "./layouts/MainLayout";
import {useSelector} from "react-redux";
import {Helmet} from "react-helmet-async";
import AllExperiences from "./pages/experiences/AllExperiences";
import AllFeed from "./pages/posts/AllFeed";
import {GlobalStyles} from "@mui/material";
import {Elements} from "@stripe/react-stripe-js";
import ExperienceDetailsPage from "./pages/experiences/ExperienceDetails";
import {loadStripe} from "@stripe/stripe-js";
import GuestCheckoutPage from "./pages/checkout/Guest";
import GuestOrderSuccessPage from "./pages/experiences/GuestOrderSuccessPage";
import FeedbackPage from "./pages/authentication/FeedbackPage";
import Login from "./pages/authentication/Login";
import Register from "./pages/authentication/Register";
import AuthLayout from "./layouts/AuthLayout";
import PostDetails from "./pages/posts/PostDetails";

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);

function App() {
    const {winery} = useSelector(
        (state) => state.auth
    );
    return (
        <>
            <Helmet>
                <title>
                    {`${winery ? `${winery?.company_name} - ` : ""} Wine Grower`}
                </title>
            </Helmet>
            <GlobalStyles styles={{p: {letterSpacing: 0}}}/>
            <Routes>
                <Route
                    path="/login"
                    element={
                        <AuthLayout>
                            <Login/>
                        </AuthLayout>
                    }
                />
                <Route
                    path="/register"
                    element={
                        <AuthLayout>
                            <Register/>
                        </AuthLayout>
                    }
                />
                <Route path="/" >
                    <Route
                        path="/"
                        element={
                            <MainLayout>
                                <Home/>
                            </MainLayout>
                        }
                    />
                    <Route
                        path="/discover"
                        element={
                            <MainLayout>
                                <AllExperiences/>
                            </MainLayout>
                        }
                    />
                    <Route
                        path="/experience-details/:id/:tenancy"
                        element={
                            <>
                                {window.innerWidth < 768 ? (
                                    <Elements stripe={stripePromise}>
                                        <ExperienceDetailsPage/>
                                    </Elements>
                                ) : (
                                    <MainLayout>
                                        <Elements stripe={stripePromise}>
                                            <ExperienceDetailsPage/>
                                        </Elements>
                                    </MainLayout>
                                )}
                            </>
                        }
                    />
                    <Route
                        path="/guest-checkout/:id/:feature_id/:tenancy"
                        element={
                            <>
                                <MainLayout>
                                    <Elements stripe={stripePromise}>
                                        <GuestCheckoutPage/>
                                    </Elements>
                                </MainLayout>
                            </>
                        }
                    />
                    <Route
                        path="/guest-order-success"
                        element={
                            <>
                                {window.innerWidth < 768 ? (
                                    <GuestOrderSuccessPage/>
                                ) : (
                                    <MainLayout>
                                        <GuestOrderSuccessPage/>
                                    </MainLayout>
                                )}
                            </>
                        }
                    />

                    <Route
                        path="/feeds"
                        element={
                            <MainLayout>
                                <AllFeed/>
                            </MainLayout>
                        }
                    />
                    <Route
                        path="/post-details/:id/:tenancy"
                        element={
                            <>
                                {window.innerWidth < 768 ? (
                                    <PostDetails/>
                                ) : (
                                    <MainLayout>
                                        <PostDetails/>
                                    </MainLayout>
                                )}
                            </>
                        }
                    />
                    <Route
                        path="/feedback"
                        element={
                            <AuthLayout>
                                <FeedbackPage/>
                            </AuthLayout>
                        }
                    />
                </Route>

                <Route path="*" element={<Navigate to="/"/>}/>
            </Routes>
        </>

    );
}

export default App;
