import React from "react";

const GiftIcon = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M2041 5100 c-81 -22 -186 -75 -241 -123 -145 -125 -232 -327 -216
-504 l7 -73 -108 39 c-59 22 -166 60 -236 86 -216 78 -299 88 -420 49 -109
-36 -210 -121 -260 -220 -41 -83 -237 -632 -237 -667 0 -41 28 -95 60 -118 14
-10 549 -208 1189 -441 l1164 -423 -1141 -5 c-1138 -5 -1141 -5 -1169 -26 -15
-11 -38 -36 -50 -54 l-23 -33 0 -1096 c0 -1197 -2 -1160 57 -1260 59 -100 163
-183 268 -212 76 -21 3166 -21 3240 0 108 31 209 112 268 212 58 98 57 84 57
1041 0 556 4 878 10 878 5 0 34 -9 64 -21 91 -33 161 -24 208 28 18 20 218
555 243 652 39 153 -3 309 -115 422 -72 72 -107 90 -407 199 -136 50 -249 93
-252 95 -2 3 8 14 23 26 62 49 142 186 172 294 18 66 18 226 -1 297 -97 372
-505 563 -852 397 -69 -33 -114 -69 -333 -262 -122 -109 -146 -126 -152 -110
-4 10 -19 99 -33 198 -33 228 -42 276 -66 352 -54 165 -214 319 -389 374 -97
31 -236 34 -329 9z m285 -316 c65 -33 126 -98 148 -157 11 -30 96 -545 96
-582 0 -10 -522 190 -555 213 -89 62 -143 175 -132 278 23 219 249 346 443
248z m-701 -715 c352 -128 643 -235 648 -239 7 -6 -182 -543 -196 -556 -5 -6
-1387 493 -1398 505 -9 8 145 428 169 460 22 31 77 61 112 61 14 0 313 -104
665 -231z m2119 197 c185 -91 227 -336 83 -484 -62 -64 -108 -85 -202 -90
l-80 -4 -255 92 c-140 51 -258 96 -263 99 -4 4 87 90 202 192 243 214 274 232
388 226 52 -3 87 -11 127 -31z m-1032 -593 c117 -42 129 -49 124 -67 -3 -12
-48 -138 -100 -281 -68 -187 -98 -259 -109 -257 -15 2 -260 91 -264 96 -2 1
42 126 97 276 55 151 100 275 100 277 0 9 34 -1 152 -44z m1055 -385 c351
-127 651 -240 667 -251 32 -23 56 -72 56 -117 0 -28 -144 -445 -160 -463 -5
-6 -1011 352 -1386 494 -21 7 -19 15 80 288 55 154 102 281 103 281 2 0 290
-104 640 -232z m-1909 -1940 l2 -1048 -542 0 c-605 0 -588 -2 -635 69 l-23 34
0 999 0 998 598 -2 597 -3 3 -1047z m592 2 l0 -1050 -145 0 -145 0 0 1050 0
1050 145 0 145 0 0 -1050z m1313 983 l187 -67 0 -932 0 -931 -23 -34 c-47 -71
-30 -69 -634 -69 l-543 0 0 1043 c0 574 3 1047 7 1050 3 4 189 7 412 7 l406 1
188 -68z"
        />
      </g>
    </svg>
  );
};

export default GiftIcon;
