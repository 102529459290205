import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import authSlice from "./slices/authSlice";
import homeSlice from "./slices/homeSlice";
import timelineSlice from "./slices/timelineSlice";
import experiencesSlice from "./slices/experiencesSlice";

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
};

const rootReducer = combineReducers({
  auth: authSlice,
  home: homeSlice,
  timeline: timelineSlice,
  experiences: experiencesSlice,
});

export { rootPersistConfig, rootReducer };
