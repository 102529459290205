import axios from "axios";
import { API_URL, MAIN_API_URL } from "../../config-global";

export const PrivateApiCall = axios.create({
  baseURL: API_URL,
});

export const MainApiCall = axios.create({
    baseURL: MAIN_API_URL,
});

MainApiCall.interceptors.request.use(
    async (req) => {
        const email = localStorage.getItem("email");
        if (email) {
            // req.headers.Authorization = `Bearer ${token}`;
            req.headers["auth-email"] = `${email}`;
        } else {
            req.headers["auth-email"] = "";
        }

        return req;
    },
    (err) => {
        throw err;
    }
);

PrivateApiCall.interceptors.request.use(
  async (req) => {
    const token = localStorage.getItem("access_token");
    if (token) {
      // req.headers.Authorization = `Bearer ${token}`;
      req.headers["auth-token"] = `${token}`;
    } else {
      req.headers["auth-token"] = "";
    }

    return req;
  },
  (err) => {
    throw err;
  }
);

// Add a response interceptor
PrivateApiCall.interceptors.response.use(
  (res) => res,
  async (err) => {
    if (err?.response) {
      if (err?.response?.status === 403) {
        await localStorage.clear();
        // console.log("err", err);
      }
    }
    throw err;
  }
);

export const PublicApiCall = axios.create({
  baseURL: API_URL,
});

PublicApiCall.interceptors.request.use(
  (req) => req,
  (err) => {
    throw err;
  }
);

PublicApiCall.interceptors.response.use(
  (res) => res,
  (err) => {
    throw err;
  }
);
