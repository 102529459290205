import { Box } from "@mui/material";
import React, { useState } from "react";

const HeaderLogo = ({ winery }) => {
  const [errorImage, setErrorImage] = useState(false);
  return (
    <>
      {!errorImage ? (
        <Box
          sx={{
            width: { xs: "100px", sm: "150px" },
            height: "auto",
            marginBottom: "50px",
            marginTop: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={winery?.logo}
            alt={winery?.company_name}
            height={"auto"}
            className="header-img"
            onError={() => setErrorImage(true)}
            style={{ color: "white", fontSize: "25px" }}
          />
        </Box>
      ) : (
        <div className="header-page-title">{winery?.company_name}</div>
      )}
    </>
  );
};

export default HeaderLogo;
