import React, { useState } from "react";
import { IconButton, InputAdornment, TextField } from "@mui/material";
//Styles
import "./inputs.css";
//Icons
import { Visibility, VisibilityOff } from "@mui/icons-material";

const AuthInputField = ({
  name,
  placeholder,
  type,
  variant,
  fullWidth,
  value,
  onChange,
  onBlur,
  error,
  helperText,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <TextField
      name={name}
      placeholder={placeholder}
      type={showPassword || type !== "password" ? "text" : "password"}
      variant={variant}
      fullWidth={fullWidth}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      error={error}
      helperText={helperText}
      InputProps={{
        endAdornment: type === "password" && (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleTogglePassword}
              edge="end"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      sx={{
        width: { xs: "250px", sm: "310px", md: "400px" },
        marginBottom: error ? "0px" : "10px",
        maxWidth: { xs: "250px", sm: "310px", md: "400px" },
        "& .MuiOutlinedInput-root": {
          height: { xs: "40px", sm: "50px" },
          fontFamily: "inherit",
          borderRadius: "10px",
          backgroundColor: "rgba(255, 255, 255, 0.5)",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          padding: 0,
          borderColor: "white",
          borderWidth: "1px !important",
        },
        ".MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
          padding: 0,
          borderColor: "white",
        },
        ".MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
          borderColor: "#d32f2f",
        },
        ".MuiFormHelperText-root.Mui-error": {
          fontFamily: "inherit",
        },
        ".MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: "white",
        },

        "& .MuiOutlinedInput-input": {
          color: "#3d3d3d",
          padding: "10px",
          width: "100%",
          fontFamily: "inherit",
          fontSize: { xs: "12px", sm: "16px", md: "18px" },
        },
      }}
    />
  );
};

export default AuthInputField;
