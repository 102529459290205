import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const ExperiencesIcons = ({
  active,
  width,
  height,
  isDrawer,
  isMenu,
  color,
  isDesktopActive,
}) => {
  const location = useLocation();
  const [isIconActive, setIsIconActive] = useState(false);

  useEffect(() => {
    if (
      location?.pathname === "/experiences" ||
      location?.pathname?.includes("/experience-details")
    ) {
      setIsIconActive(true);
    } else {
      setIsIconActive(false);
    }
  }, [location]);
  return active ? (
    <>
      <svg
        width={width ? width : "13"}
        height={height ? height : "13"}
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M26.1196 0.622528C26.9497 -0.207509 28.2976 -0.207509 29.1277 0.622528L33.3775 4.87232C34.2075 5.70236 34.2075 7.05034 33.3775 7.88038C32.5939 8.66393 31.3389 8.71041 30.5089 8.00654L29.878 8.63737L26.6376 11.8778C26.0267 12.4887 25.8739 13.3985 26.0665 14.2418C26.6907 16.9577 25.9403 19.9259 23.8221 22.0441L13.098 32.7549C11.4379 34.415 8.7486 34.415 7.08852 32.7549L1.24506 26.9115C-0.415019 25.2514 -0.415019 22.5621 1.24506 20.902L11.9625 10.1779C14.0808 8.05967 17.049 7.31595 19.7649 7.9335C20.6015 8.12607 21.5179 7.9667 22.1222 7.36243L25.3626 4.12197L25.9935 3.49114C25.2896 2.65446 25.3294 1.40608 26.1196 0.622528ZM6.5905 23.0601L10.9333 27.4029C11.345 27.8146 12.0223 27.8146 12.434 27.4029L18.9016 20.9352C19.3133 20.5235 19.3133 19.8462 18.9016 19.4345L14.5589 15.0917C14.1472 14.68 13.4698 14.68 13.0581 15.0917L6.5905 21.5594C6.1788 21.9711 6.1788 22.6484 6.5905 23.0601Z"
          fill="#A09574"
        />
      </svg>
    </>
  ) : isDrawer ? (
    <>
      <svg
        width="16"
        height="16"
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M26.7533 1.26161L26.756 1.25892C27.2346 0.780358 28.0127 0.780358 28.4913 1.25892L32.7411 5.50872C33.2196 5.98728 33.2196 6.76542 32.7411 7.24398C32.2878 7.69723 31.5627 7.72016 31.0909 7.32012L30.4587 6.78394L29.8725 7.37015L29.2416 8.00098L26.0012 11.2414C25.1139 12.1287 24.9479 13.3858 25.1891 14.4421L25.1894 14.4434C25.7468 16.8686 25.0765 19.5169 23.1857 21.4077L12.462 32.1182L12.4616 32.1185C11.153 33.4272 9.03352 33.4272 7.72492 32.1185L1.88145 26.2751C0.572849 24.9665 0.572849 22.847 1.88145 21.5384L1.88165 21.5382L12.5989 10.8143C14.4883 8.9249 17.1364 8.25916 19.5642 8.81086C20.6183 9.05308 21.8803 8.8771 22.7586 7.99883L25.999 4.75836L26.6299 4.12753L27.2137 3.54364L26.6822 2.91175C26.2749 2.42761 26.3014 1.70974 26.7533 1.26161ZM5.9541 23.6965L10.2969 28.0393C11.06 28.8024 12.3072 28.8024 13.0704 28.0393L19.538 21.5716C20.3012 20.8084 20.3012 19.5613 19.538 18.7981L15.1953 14.4553C14.4321 13.6922 13.1849 13.6922 12.4218 14.4553L5.9541 20.923C5.19093 21.6862 5.19093 22.9333 5.9541 23.6965Z"
          fill="#3D3D3D"
          stroke="#3D3D3D"
          strokeWidth="1.8"
        />
      </svg>
    </>
  ) : (isMenu && isIconActive) || isDesktopActive ? (
    <svg
      width={width ? width : "27"}
      height={height ? height : "27"}
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.7533 1.26161L26.756 1.25892C27.2346 0.780358 28.0127 0.780358 28.4913 1.25892L32.7411 5.50872C33.2196 5.98728 33.2196 6.76542 32.7411 7.24398C32.2878 7.69723 31.5627 7.72016 31.0909 7.32012L30.4587 6.78394L29.8725 7.37015L29.2416 8.00098L26.0012 11.2414C25.1139 12.1287 24.9479 13.3858 25.1891 14.4421L25.1894 14.4434C25.7468 16.8686 25.0765 19.5169 23.1857 21.4077L12.462 32.1182L12.4616 32.1185C11.153 33.4272 9.03352 33.4272 7.72492 32.1185L1.88145 26.2751C0.572849 24.9665 0.572849 22.847 1.88145 21.5384L1.88165 21.5382L12.5989 10.8143C14.4883 8.9249 17.1364 8.25916 19.5642 8.81086C20.6183 9.05308 21.8803 8.8771 22.7586 7.99883L25.999 4.75836L26.6299 4.12753L27.2137 3.54364L26.6822 2.91175C26.2749 2.42761 26.3014 1.70974 26.7533 1.26161ZM5.9541 23.6965L10.2969 28.0393C11.06 28.8024 12.3072 28.8024 13.0704 28.0393L19.538 21.5716C20.3012 20.8084 20.3012 19.5613 19.538 18.7981L15.1953 14.4553C14.4321 13.6922 13.1849 13.6922 12.4218 14.4553L5.9541 20.923C5.19093 21.6862 5.19093 22.9333 5.9541 23.6965Z"
        fill={color ? color : "#3D3D3D"}
        stroke={color ? color : "#3D3D3D"}
        strokeWidth="1.8"
      />
    </svg>
  ) : isMenu ? (
    <svg
      width="27"
      height="27"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.7533 1.26161L26.756 1.25892C27.2346 0.780358 28.0127 0.780358 28.4913 1.25892L32.7411 5.50872C33.2196 5.98728 33.2196 6.76542 32.7411 7.24398C32.2878 7.69723 31.5627 7.72016 31.0909 7.32012L30.4587 6.78394L29.8725 7.37015L29.2416 8.00098L26.0012 11.2414C25.1139 12.1287 24.9479 13.3858 25.1891 14.4421L25.1894 14.4434C25.7468 16.8686 25.0765 19.5169 23.1857 21.4077L12.462 32.1182L12.4616 32.1185C11.153 33.4272 9.03352 33.4272 7.72492 32.1185L1.88145 26.2751C0.572849 24.9665 0.572849 22.847 1.88145 21.5384L1.88165 21.5382L12.5989 10.8143C14.4883 8.9249 17.1364 8.25916 19.5642 8.81086C20.6183 9.05308 21.8803 8.8771 22.7586 7.99883L25.999 4.75836L26.6299 4.12753L27.2137 3.54364L26.6822 2.91175C26.2749 2.42761 26.3014 1.70974 26.7533 1.26161ZM5.9541 23.6965L10.2969 28.0393C11.06 28.8024 12.3072 28.8024 13.0704 28.0393L19.538 21.5716C20.3012 20.8084 20.3012 19.5613 19.538 18.7981L15.1953 14.4553C14.4321 13.6922 13.1849 13.6922 12.4218 14.4553L5.9541 20.923C5.19093 21.6862 5.19093 22.9333 5.9541 23.6965Z"
        stroke="#3D3D3D"
        strokeWidth="1.8"
      />
    </svg>
  ) : (
    <>
      <svg
        width={width ? width : "13"}
        height={height ? height : "13"}
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M26.7533 1.26161L26.756 1.25892C27.2346 0.780358 28.0127 0.780358 28.4913 1.25892L32.7411 5.50872C33.2196 5.98728 33.2196 6.76542 32.7411 7.24398C32.2878 7.69723 31.5627 7.72016 31.0909 7.32012L30.4587 6.78394L29.8725 7.37015L29.2416 8.00098L26.0012 11.2414C25.1139 12.1287 24.9479 13.3858 25.1891 14.4421L25.1894 14.4434C25.7468 16.8686 25.0765 19.5169 23.1857 21.4077L12.462 32.1182L12.4616 32.1185C11.153 33.4272 9.03352 33.4272 7.72492 32.1185L1.88145 26.2751C0.572849 24.9665 0.572849 22.847 1.88145 21.5384L1.88165 21.5382L12.5989 10.8143C14.4883 8.9249 17.1364 8.25916 19.5642 8.81086C20.6183 9.05308 21.8803 8.8771 22.7586 7.99883L25.999 4.75836L26.6299 4.12753L27.2137 3.54364L26.6822 2.91175C26.2749 2.42761 26.3014 1.70974 26.7533 1.26161ZM5.9541 23.6965L10.2969 28.0393C11.06 28.8024 12.3072 28.8024 13.0704 28.0393L19.538 21.5716C20.3012 20.8084 20.3012 19.5613 19.538 18.7981L15.1953 14.4553C14.4321 13.6922 13.1849 13.6922 12.4218 14.4553L5.9541 20.923C5.19093 21.6862 5.19093 22.9333 5.9541 23.6965Z"
          fill="#3D3D3D"
          stroke="#3D3D3D"
          strokeWidth="1.8"
          fillOpacity="0.7"
        />
      </svg>
    </>
  );
};

export default ExperiencesIcons;
