import React from "react";

const CommentIcon = ({ color, width, height, noOpacity }) => {
  return (
    <svg
      width={width ? width : "26"}
      height={height ? height : "24"}
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.997 3.49377C15.336 3.49377 17.5341 4.35161 19.1851 5.90985C22.5978 9.12924 22.5978 14.3669 19.1851 17.5863C17.5121 19.1653 15.2595 20.0008 12.9775 20.0008C11.7336 20.0008 10.4815 19.7528 9.30995 19.2413C8.96476 19.1107 8.64156 18.9879 8.40954 18.9879C8.14251 18.9894 7.78349 19.1061 7.43668 19.219C6.72515 19.4494 5.8394 19.7366 5.18404 19.1207C4.53112 18.504 4.83234 17.6708 5.07495 17.0003C5.19462 16.6701 5.31755 16.3291 5.31755 16.0711C5.31755 15.8591 5.20928 15.5888 5.0432 15.1994C3.51837 12.0929 4.22339 8.34897 6.80982 5.91062C8.46083 4.35238 10.6581 3.49377 12.997 3.49377ZM12.9979 4.64575C10.9846 4.64575 9.09339 5.38455 7.67277 6.72545C5.447 8.82359 4.8413 12.0453 6.16423 14.7432C6.35473 15.1879 6.53872 15.621 6.53872 16.0711C6.53872 16.5203 6.37508 16.9727 6.23098 17.372C6.11212 17.7007 5.93221 18.1968 6.04781 18.3059C6.16097 18.4165 6.69014 18.2421 7.03939 18.1293C7.45866 17.9941 7.9341 17.839 8.40547 17.8359C8.87765 17.8359 9.32297 18.0048 9.79434 18.1838C12.682 19.4433 16.0972 18.8704 18.3221 16.7722C21.2578 14.0013 21.2578 9.49404 18.3221 6.72468C16.9007 5.38378 15.0103 4.64575 12.9979 4.64575ZM16.2111 11.2986C16.6605 11.2986 17.0252 11.6419 17.0252 12.0666C17.0252 12.4912 16.6605 12.8345 16.2111 12.8345C15.7617 12.8345 15.3937 12.4912 15.3937 12.0666C15.3937 11.6419 15.7544 11.2986 16.2037 11.2986H16.2111ZM12.9475 11.2986C13.3969 11.2986 13.7616 11.6419 13.7616 12.0666C13.7616 12.4912 13.3969 12.8345 12.9475 12.8345C12.4981 12.8345 12.1301 12.4912 12.1301 12.0666C12.1301 11.6419 12.4899 11.2986 12.9401 11.2986H12.9475ZM9.68354 11.2986C10.1329 11.2986 10.4977 11.6419 10.4977 12.0666C10.4977 12.4912 10.1329 12.8345 9.68354 12.8345C9.23415 12.8345 8.86618 12.4912 8.86618 12.0666C8.86618 11.6419 9.22683 11.2986 9.67621 11.2986H9.68354Z"
        fill={color ? color : "#3D3D3D"}
        fillOpacity={noOpacity ? "1" : "0.7"}
      />
    </svg>
  );
};

export default CommentIcon;
