import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";

//CSS
import "./auth.css";

//Icons
import ClockIcon from "../../assets/icons/ClockIcon";
import EmailIcon from "../../assets/icons/EmailIcon";
import ErrorIcon from "../../assets/icons/ErrorIcon";
import ForgotPasswordIcon from "../../assets/icons/ForgotPasswordIcon";

//MUI components
import {Box, Button, Container} from "@mui/material";
//Redux/ Api
import {useDispatch, useSelector} from "react-redux";
import {verifyUserEmail, verifyUserEmailWithOrder} from "../../config/api";
import {feedbackContentPage} from "../../redux/slices/authSlice";

//Header Logo
import HeaderLogo from "../../components/headerLogo/HeaderLogo";
//Helmet Component
import HelmetPage from "../../components/helmetPage/HelmetPage";

const FeedbackPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {winery, feedbackContent} = useSelector((state) => state.auth);
    const [token, setToken] = useState(null);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    useEffect(() => {
        let queryToken = queryParams.get("token");
        setToken(queryToken);
    }, []);

    useEffect(() => {
        const verifyUser = async () => {
            try {
                let res = null;
                if (token) {
                    res = await verifyUserEmail(token);
                    if (res !== null && res.status === 200) {
                        if (res.data.message === "verified") {
                            dispatch(
                                feedbackContentPage({
                                    type: "verified_successfully",
                                    title: "Verified Successfully",
                                    message: res?.data?.success?.message,
                                })
                            );
                        } else if (res.data.message === "not_approved") {
                            dispatch(
                                feedbackContentPage({
                                    type: "not_approved",
                                    title: "You’re on the Waitlist",
                                    message: res?.data?.success?.message,
                                })
                            );
                        } else if (res.data.message === "user_approved") {
                            dispatch(
                                feedbackContentPage({
                                    type: "user_approved",
                                    title: "User Approved",
                                    message: res?.data?.success?.message,
                                })
                            );
                        } else {
                            dispatch(feedbackContentPage({}));
                        }
                    } else {
                        if (res.data.message === "token_invalid") {
                            dispatch(
                                feedbackContentPage({
                                    type: "token_invalid",
                                    title: "Invalid Token",
                                    message: res?.data?.error?.message,
                                })
                            );
                        } else if (res.data.message === "already_verified") {
                            dispatch(
                                feedbackContentPage({
                                    type: "already_verified",
                                    title: "Already Verified",
                                    message: res?.data?.error?.message,
                                })
                            );
                        } else {
                            dispatch(feedbackContentPage({}));
                        }
                    }
                }
            } catch (error) {
                console.error("Error verifying user email:", error);
            }
        };

        verifyUser();
    }, [token, dispatch]);

    return (
        <Container className="main-container">
            <HelmetPage pageTitle={"Feedback Page"}/>
            <HeaderLogo winery={winery}/>

            <Box className="form-container">
                <Box className="header-icon">
                    {feedbackContent?.type === "not_approved" ? (
                        <ClockIcon/>
                    ) : feedbackContent?.type === "not_verified" ? (
                        <EmailIcon/>
                    ) : feedbackContent?.type === "forgot_password" ||
                    feedbackContent?.type === "reset_password" ? (
                        <ForgotPasswordIcon/>
                    ) : (
                        <ErrorIcon/>
                    )}
                </Box>
                <Box className="feedback-page-title">{feedbackContent?.title}</Box>
                <Box className="feedback-page-message">{feedbackContent?.message}</Box>

                <Box
                    style={{color: "#999999"}}
                    className="feedback-page-back-to-login"
                >
                    <Button
                        onClick={() => navigate("/login")}
                        sx={{
                            backgroundColor: "#3D3D3D",
                            borderRadius: "10px",
                            marginTop: "10px",
                            textTransform: "none",
                            height: {xs: "40px", sm: "45px"},
                            width: {xs: "250px", sm: "310px", md: "350px"},
                            fontSize: {xs: "16px", sm: "20px"},
                            fontFamily: "inherit",
                            "&:hover": {
                                backgroundColor: "#c88080ff",
                            },
                        }}
                    >
                        <Box className="login-form-button-text"> Back To Sign In</Box>
                    </Button>
                </Box>
                {feedbackContent?.type === "not_verified" ? (
                    <Box className="feedback-hint-message">
                        Hint: Email not in your inbox? Check your spam folder.
                    </Box>
                ) : (
                    ""
                )}
            </Box>
        </Container>
    );
};

export default FeedbackPage;
