import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import SignInIcon from "../../assets/icons/SignInIcon";
import { useLocation, useNavigate } from "react-router-dom";
import CloseIcon from "../../assets/icons/headerIcons/CloseIcon";
import { useDispatch, useSelector } from "react-redux";
import { setExpandedBottomPrompt } from "../../redux/slices/homeSlice";
import { setUserRouteLocation } from "../../redux/slices/authSlice";

const BottomSignInPrompt = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { expandedBottomPrompt } = useSelector((state) => state.home);

  const bottomSignInRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        bottomSignInRef.current &&
        !bottomSignInRef.current.contains(event.target)
      ) {
        // Clicked outside the component, close it
        dispatch(setExpandedBottomPrompt(false));
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dispatch, bottomSignInRef]);

  return (
    <>
      {window?.innerWidth < 768 ? (
        <Box
          ref={bottomSignInRef}
          style={{
            position: "fixed",
            bottom: 0,
            left: 0,
            minHeight: expandedBottomPrompt ? "280px" : "140px",
            width: "100%",
            boxShadow: "0px -6px 8px 2px rgba(0, 0, 0, 0.30)",
            backgroundColor: "white",
            borderRadius: "20px 20px 0px 0px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            zIndex: 10,
          }}
        >
          <Box
            sx={{
              borderRadius: "50%",
              backgroundColor: "#F3F2EC",
              position: "absolute",
              top: -25,
            }}
          >
            {expandedBottomPrompt ? (
              <Box
                onClick={() => dispatch(setExpandedBottomPrompt(false))}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "50px",
                  height: "50px",
                  cursor: "pointer",
                }}
              >
                <CloseIcon width="20" height="20" />
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "50px",
                  height: "50px",
                }}
              >
                <SignInIcon width="20" height="20" />
              </Box>
            )}
            {/* <SignInIcon width="20" height="20" /> */}
          </Box>
          {expandedBottomPrompt && (
            <Typography
              sx={{
                color: "#003FA8",
                fontSize: "16px",
                fontFamily: "inherit",
                textAlign: "center",
                padding: "0px 10px",
                marginBottom: "35px",
                fontWeight: "500",
              }}
            >
              Sign in or sign up to access this feature.
            </Typography>
          )}

          <Typography
            sx={{
              color: "rgba(0, 0, 0, 0.80)",
              fontSize: "18px",
              fontFamily: "inherit",
              textAlign: "center",
              fontWeight: "500",
              marginBottom: "10px",
            }}
          >
            {expandedBottomPrompt
              ? "Explore More with a Sign In"
              : "Sign In to explore more"}
          </Typography>
          {expandedBottomPrompt && (
            <Typography
              sx={{
                color: "rgba(61, 61, 61, 0.70)",
                fontSize: "13px",
                fontFamily: "inherit",
                textAlign: "center",
                fontWeight: "500",
                padding: "0px 10px",
                marginBottom: "5px",
              }}
            >
              Experience Personalization, Exclusive Content, and More Benefits
            </Typography>
          )}
          <Button
            variant="contained"
            onClick={() => {
              dispatch(setUserRouteLocation(location?.pathname));
              navigate("/login");
            }}
            sx={{
              backgroundColor: "#0060FF",
              borderRadius: "5px",
              textTransform: "none",
              height: "40px",
              width: { xs: "300px", sm: "310px", md: "350px" },
              fontSize: { xs: "19px", sm: "20px" },
              fontWeight: "600",
              fontFamily: "inherit",
              "&:hover": {
                backgroundColor: "#0060FF",
              },
            }}
          >
            Sign In
          </Button>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            <Box
              sx={{
                marginTop: "10px",
                fontSize: { xs: "11px", sm: "18px" },
                color: "rgba(0, 0, 0, 0.60)",
              }}
            >
              Not a member?
            </Box>
            <Box
              onClick={() => {
                dispatch(setUserRouteLocation(location?.pathname));
                navigate(`/register`);
              }}
              sx={{
                color: "#3D3D3D",
                fontSize: { xs: "12px", sm: "19px" },
                fontWeight: "600",
                cursor: "pointer",
                marginLeft: "5px",
                borderBottom: "1px solid #3D3D3D",
              }}
            >
              Sign Up
            </Box>
          </Box>
          {/* <Typography>Not a member? Sign up</Typography> */}
        </Box>
      ) : (
        <Box
          ref={bottomSignInRef}
          style={{
            position: "fixed",
            bottom: 0,
            left: 0,
            height: expandedBottomPrompt ? "140px" : "80px",
            width: "100%",
            backgroundColor: "#5595FF",
            zIndex: 10,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <Box>
              {expandedBottomPrompt && (
                <Typography
                  sx={{
                    fontSize: "23px",
                    fontWeight: "600",
                    color: "white",
                    fontFamily: "inherit",
                  }}
                >
                  Don’t miss what’s happening
                </Typography>
              )}
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: "600",
                  color: "white",
                  fontFamily: "inherit",
                  margin: expandedBottomPrompt ? "5px 0px" : "0px",
                }}
              >
                Explore More with a Sign In
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  color: "white",
                  fontFamily: "inherit",
                }}
              >
                Experience Personalization, Exclusive Content, and More Benefits
              </Typography>
            </Box>
            <Box>
              <Button
                sx={{
                  backgroundColor: "#FFFFFF",
                  width: "115px",
                  height: "45px",
                  borderRadius: "34px",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#FFFFFF",
                    padding: 0,
                  },
                }}
                onClick={() => {
                  dispatch(setUserRouteLocation(location?.pathname));
                  navigate("/login");
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "inherit",
                    color: "rgba(61, 61, 61, 0.90)",
                    fontSize: "15px",
                  }}
                >
                  Sign In
                </Typography>
              </Button>
              <Button
                sx={{
                  border: "3px solid #FFFFFF",
                  width: "115px",
                  height: "45px",
                  borderRadius: "34px",
                  backgroundColor: "#5595FF",
                  textTransform: "none",
                  marginLeft: "10px",
                  "&:hover": {
                    backgroundColor: "#5595FF",
                    padding: 0,
                  },
                }}
                onClick={() => {
                  dispatch(setUserRouteLocation(location?.pathname));
                  navigate(`/register`);
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "inherit",
                    color: "#FFFFFF",
                    fontSize: "15px",
                  }}
                >
                  Sign Up
                </Typography>
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default BottomSignInPrompt;
