import React from "react";

const SuccessPaymentIcon = ({ type }) => {
  return (
    <>
      {type === "stripe" || type === "wallet" ? (
        <svg
          width="280"
          height="280"
          viewBox="0 0 311 310"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            opacity="0.1"
            cx="155.5"
            cy="154.845"
            r="154.845"
            fill="#7DDE7A"
          />
          <circle
            opacity="0.3"
            cx="155.5"
            cy="153.845"
            r="77.4223"
            fill="#7DDE7A"
          />
          <path
            d="M155.5 193.556C145.233 193.556 135.387 189.477 128.127 182.217C120.868 174.958 116.789 165.111 116.789 154.844C116.789 144.578 120.868 134.731 128.127 127.472C135.387 120.212 145.233 116.133 155.5 116.133C165.767 116.133 175.613 120.212 182.873 127.472C190.133 134.731 194.211 144.578 194.211 154.844C194.211 165.111 190.133 174.958 182.873 182.217C175.613 189.477 165.767 193.556 155.5 193.556ZM147.758 174.2L182.598 141.296L176.791 135.489L147.758 162.587L134.209 149.038L128.402 154.844L147.758 174.2Z"
            fill="#32AC2E"
          />
          <defs>
            <clipPath id="clip0_0_1">
              <rect
                width="77.4223"
                height="77.4223"
                fill="white"
                transform="translate(116.789 116.133)"
              />
            </clipPath>
          </defs>
        </svg>
      ) : (
        <svg
          width="311"
          height="310"
          viewBox="0 0 311 310"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            opacity="0.1"
            cx="155.5"
            cy="154.845"
            r="154.845"
            fill="#E4BC43"
          />
          <circle
            opacity="0.3"
            cx="155.5"
            cy="153.845"
            r="77.4223"
            fill="#E4BC43"
          />
          <g clip-path="url(#clip0_0_1)">
            <path
              d="M156 122.5C173.95 122.5 188.5 137.05 188.5 155C188.5 172.95 173.95 187.5 156 187.5C138.05 187.5 123.5 172.95 123.5 155C123.5 137.05 138.05 122.5 156 122.5ZM156 135.5C155.138 135.5 154.311 135.842 153.702 136.452C153.092 137.061 152.75 137.888 152.75 138.75V155C152.75 155.862 153.093 156.688 153.702 157.298L163.452 167.048C164.065 167.64 164.886 167.967 165.738 167.96C166.59 167.953 167.406 167.611 168.008 167.008C168.611 166.406 168.953 165.59 168.96 164.738C168.967 163.886 168.64 163.065 168.048 162.452L159.25 153.655V138.75C159.25 137.888 158.908 137.061 158.298 136.452C157.689 135.842 156.862 135.5 156 135.5Z"
              fill="#FFC000"
            />
          </g>
          <defs>
            <clipPath id="clip0_0_1">
              <rect
                width="78"
                height="78"
                fill="white"
                transform="translate(117 116)"
              />
            </clipPath>
          </defs>
        </svg>
      )}
    </>
  );
};

export default SuccessPaymentIcon;
