import React from "react";

const PlayIcon = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 24C18.8513 24 24 18.8513 24 12.5C24 6.14873 18.8513 1 12.5 1C6.14873 1 1 6.14873 1 12.5C1 18.8513 6.14873 24 12.5 24Z"
        stroke="white"
        strokeWidth="1.5"
      />
      <path
        d="M16.4262 11.2821C17.3243 11.8134 17.3243 13.1865 16.4262 13.7178L10.9982 16.9229C10.1242 17.4381 9.05008 16.7665 9.05008 15.7039V9.29606C9.05008 8.23346 10.1242 7.56301 10.9982 8.07821L16.4262 11.2833V11.2821Z"
        stroke="white"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default PlayIcon;
