import React from "react";

const SendIcon = ({ color }) => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 21 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.0148 8.04286L3.57726 1.22101C3.46411 1.16754 3.337 1.14611 3.21126 1.15931C3.08551 1.17251 2.96649 1.21979 2.86851 1.29543C2.77495 1.36954 2.70511 1.46684 2.66686 1.5764C2.6286 1.68596 2.62344 1.80343 2.65195 1.9156L4.39101 7.97465H11.8132V9.21498H4.39101L2.6257 15.2554C2.59894 15.3491 2.59582 15.4474 2.61658 15.5424C2.63735 15.6375 2.68141 15.7266 2.74524 15.8026C2.80907 15.8786 2.89089 15.9394 2.9841 15.98C3.07732 16.0207 3.17934 16.0402 3.28195 16.0368C3.38468 16.0362 3.48583 16.0129 3.57726 15.9686L18.0148 9.14676C18.1223 9.09472 18.2125 9.01565 18.2755 8.91827C18.3385 8.82088 18.3718 8.70895 18.3718 8.59481C18.3718 8.48067 18.3385 8.36875 18.2755 8.27136C18.2125 8.17398 18.1223 8.09491 18.0148 8.04286Z"
        fill={color ? color : "#2B2430"}
      />
    </svg>
  );
};

export default SendIcon;
