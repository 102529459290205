import React from "react";

const ShareIcon = ({ color, opacity }) => {
  return (
    <svg
      width="21"
      height="19"
      viewBox="0 0 27 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.0158 12.2341L15.3491 3.56743C15.1976 3.41597 15.0046 3.31283 14.7945 3.27105C14.5843 3.22927 14.3666 3.25072 14.1686 3.3327C13.9707 3.41467 13.8015 3.55349 13.6825 3.7316C13.5634 3.90971 13.4999 4.11912 13.4998 4.33335V8.17376C10.5405 8.44762 7.78983 9.81595 5.78617 12.011C3.7825 14.206 2.67005 17.0697 2.6665 20.0417V21.6667C2.66667 21.8916 2.73682 22.1108 2.86722 22.294C2.99761 22.4772 3.18179 22.6153 3.39421 22.6892C3.60662 22.763 3.83675 22.7689 4.05266 22.706C4.26857 22.6431 4.45958 22.5147 4.59917 22.3383C5.66051 21.0762 6.96274 20.0383 8.42978 19.2851C9.89681 18.532 11.4992 18.0787 13.1434 17.9519C13.1976 17.9454 13.333 17.9346 13.4998 17.9238V21.6667C13.4999 21.8809 13.5634 22.0903 13.6825 22.2684C13.8015 22.4465 13.9707 22.5854 14.1686 22.6673C14.3666 22.7493 14.5843 22.7708 14.7945 22.729C15.0046 22.6872 15.1976 22.5841 15.3491 22.4326L24.0158 13.7659C24.2188 13.5628 24.3329 13.2873 24.3329 13C24.3329 12.7128 24.2188 12.4373 24.0158 12.2341ZM15.6665 19.0515V16.7917C15.6665 16.5044 15.5524 16.2288 15.3492 16.0256C15.146 15.8225 14.8705 15.7083 14.5832 15.7083C14.3069 15.7083 13.1792 15.7625 12.891 15.8004C9.97127 16.0695 7.19204 17.1787 4.8895 18.9941C5.15085 16.6047 6.28434 14.3957 8.07294 12.79C9.86154 11.1843 12.1796 10.2948 14.5832 10.2917C14.8705 10.2917 15.146 10.1775 15.3492 9.97438C15.5524 9.77121 15.6665 9.49566 15.6665 9.20835V6.94851L21.718 13L15.6665 19.0515Z"
        fill={color ? color : "white"}
        fillOpacity={opacity ? "0.6" : "1"}
      />
    </svg>
  );
};

export default ShareIcon;
