import React from "react";

const GoogleIcon = () => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.4903 9.6684C18.4903 8.93095 18.4291 8.3928 18.2966 7.83473H9.67932V11.1632H14.7375C14.6355 11.9904 14.0848 13.2361 12.8611 14.0732L12.8439 14.1846L15.5685 16.2473L15.7573 16.2657C17.4909 14.701 18.4903 12.399 18.4903 9.6684Z"
        fill="#4285F4"
      />
      <path
        d="M9.67927 18.4382C12.1573 18.4382 14.2377 17.6409 15.7572 16.2657L12.861 14.0732C12.086 14.6014 11.0458 14.9701 9.67927 14.9701C7.25217 14.9701 5.19221 13.4055 4.45788 11.243L4.35025 11.2519L1.51715 13.3945L1.4801 13.4952C2.98938 16.4251 6.08956 18.4382 9.67927 18.4382Z"
        fill="#34A853"
      />
      <path
        d="M4.45786 11.243C4.2641 10.6849 4.15197 10.0869 4.15197 9.46908C4.15197 8.85117 4.2641 8.25325 4.44766 7.69519L4.44253 7.57633L1.57393 5.39929L1.48008 5.44292C0.85803 6.65874 0.501099 8.02406 0.501099 9.46908C0.501099 10.9141 0.85803 12.2793 1.48008 13.4952L4.45786 11.243Z"
        fill="#FBBC05"
      />
      <path
        d="M9.67927 3.96802C11.4027 3.96802 12.5652 4.69551 13.2281 5.30346L15.8184 2.83196C14.2276 1.38695 12.1573 0.5 9.67927 0.5C6.08956 0.5 2.98938 2.51305 1.4801 5.44292L4.44769 7.69519C5.19221 5.53265 7.25217 3.96802 9.67927 3.96802Z"
        fill="#EB4335"
      />
      <defs>
        <clipPath id="clip0_26_403">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(0.5 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GoogleIcon;
