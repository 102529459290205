import React from "react";

const EditProfileIcon = ({ width, height, fill = '#3D3D3D', background='none' }) => {
  return (
    <svg width="62" height="21" viewBox="0 0 62 21" fill='none' xmlns="http://www.w3.org/2000/svg">
        
        <rect x="0.25" y="0.25" width="61.5" height="20.5" rx="4.75" stroke={fill} fill={background}  stroke-opacity="0.3" stroke-width="0.5"/>
        <path d="M16.6901 15H11.4341V6.492H16.6901V7.836H12.8741V10.092H16.3301V11.4H12.8741V13.656H16.6901V15ZM19.0587 12.06C19.0587 12.62 19.2027 13.072 19.4907 13.416C19.7787 13.752 20.1587 13.92 20.6307 13.92C21.0867 13.92 21.4587 13.748 21.7467 13.404C22.0347 13.06 22.1787 12.608 22.1787 12.048C22.1787 11.496 22.0387 11.056 21.7587 10.728C21.4787 10.4 21.1067 10.236 20.6427 10.236C20.1787 10.236 19.7987 10.404 19.5027 10.74C19.2067 11.068 19.0587 11.508 19.0587 12.06ZM23.5227 6.312V13.932C23.5227 14.324 23.5427 14.68 23.5827 15H22.2507C22.2107 14.776 22.1907 14.524 22.1907 14.244C22.0467 14.508 21.8227 14.724 21.5187 14.892C21.2147 15.06 20.8667 15.144 20.4747 15.144C19.6427 15.144 18.9667 14.852 18.4467 14.268C17.9267 13.684 17.6667 12.948 17.6667 12.06C17.6667 11.204 17.9267 10.48 18.4467 9.888C18.9747 9.296 19.6427 9 20.4507 9C20.9147 9 21.2867 9.084 21.5667 9.252C21.8547 9.42 22.0547 9.616 22.1667 9.84V6.312H23.5227ZM26.7214 15H25.3414V9.156H26.7214V15ZM25.3894 7.74C25.2134 7.564 25.1254 7.352 25.1254 7.104C25.1254 6.856 25.2134 6.644 25.3894 6.468C25.5654 6.284 25.7774 6.192 26.0254 6.192C26.2814 6.192 26.4974 6.28 26.6734 6.456C26.8494 6.632 26.9374 6.848 26.9374 7.104C26.9374 7.352 26.8494 7.564 26.6734 7.74C26.4974 7.916 26.2814 8.004 26.0254 8.004C25.7774 8.004 25.5654 7.916 25.3894 7.74ZM30.2812 7.368V9.156H31.4932V10.392H30.2812V13.104C30.2812 13.36 30.3412 13.548 30.4612 13.668C30.5812 13.78 30.7692 13.836 31.0252 13.836C31.1852 13.836 31.3452 13.82 31.5052 13.788V14.94C31.2972 15.028 31.0132 15.072 30.6532 15.072C30.1092 15.072 29.6812 14.92 29.3692 14.616C29.0572 14.304 28.9012 13.876 28.9012 13.332V10.392H27.8212V9.156H28.1212C28.4172 9.156 28.6412 9.072 28.7932 8.904C28.9452 8.736 29.0212 8.516 29.0212 8.244V7.368H30.2812Z" fill={fill} fill-opacity="0.5"/>
        <path d="M48.46 6.9362C48.7401 6.6626 49.1167 6.51044 49.5082 6.51272C49.8997 6.515 50.2745 6.67154 50.5513 6.94837C50.8282 7.22521 50.9847 7.60003 50.987 7.99153C50.9893 8.38304 50.8371 8.75965 50.5635 9.0397L50.229 9.3742L48.1255 7.2707L48.46 6.9362ZM47.772 7.6247L43.7725 11.6242C43.6089 11.788 43.4953 11.9951 43.445 12.2212L43.006 14.1962C42.9968 14.2373 42.9982 14.28 43.0099 14.3205C43.0217 14.3609 43.0434 14.3977 43.0732 14.4275C43.103 14.4573 43.1398 14.479 43.1802 14.4908C43.2207 14.5025 43.2634 14.5039 43.3045 14.4947L45.2675 14.0582C45.5012 14.0062 45.7153 13.8886 45.8845 13.7192L49.8755 9.7282L47.772 7.6247Z" fill={fill} fill-opacity="0.5"/>
    </svg>
  );
};

export default EditProfileIcon;
