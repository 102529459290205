import React, {useEffect} from "react";
import {Box, Grid, Typography} from "@mui/material";
//Component
import FeedsItem from "../../components/posts/FeedsItem";
import {useDispatch, useSelector} from "react-redux";
import {fetchFeedContent} from "../../redux/slices/homeSlice";
const AllExperiences = () => {
    const dispatch = useDispatch();
    const {AllFeed} = useSelector((state) => state.home);

    useEffect(() => {
        dispatch(fetchFeedContent());
    }, []);

    return (
        <Box
            sx={{
                // height: "60vh",
                padding: {
                    xs: "10px 20px",
                    sm: "10px 50px",
                    lg: "10px 50px 10px 60px",
                },
            }}
        >
            {window.innerWidth > 768 && (
                <Typography
                    sx={{
                        fontSize: "30px",
                        color: "#3D3D3D",
                        fontFamily: "Fraunces",
                        fontWeight: "600",
                        margin: "10px 0px 20px 0px",
                    }}
                >
                    Feed
                </Typography>
            )}
            <Grid
                sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    margin: "0px 0px 15px 0px",
                }}
            >
            </Grid>
            <Grid
                container
                sx={{width: "100%", flexDirection: "row", display: "flex"}}
                gap={2}
            >
                {
                    AllFeed?.map((winery, index) => {
                        return (
                            winery['data']?.map((post, index) => {
                                return (
                                    <Grid item xs={4} sm={5} md={3.7} lg={3.8} xl={2.8} key={index}>
                                        <FeedsItem post={post}
                                                   tenancy={winery['tenant']}/>
                                    </Grid>
                                );
                            })
                        );
                    })
                }
            </Grid>
        </Box>
    );
};

export default AllExperiences;
