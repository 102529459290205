import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    CircularProgress,
    Divider,
    Grid,
    MenuItem,
    Modal,
    Select,
    Typography,
} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import ExperienceBenefitItem from "../../components/experiences/ExperienceBenefitItem";
import ExperienceAccordion from "../../components/experiences/ExperienceAccordion";
import BottomSignInPrompt from "../../components/layout/BottomSignInPrompt";
import ExperienceDiscountItem from "../../components/experiences/ExperienceDiscountItem";

//Redux/ Api
import {useDispatch, useSelector} from "react-redux";
import {
    addToCart,
    fetchOneExperience,
    hasPaymentError,
    purchaseOneExperience,
    setSelectedFeature,
} from "../../redux/slices/experiencesSlice";
import {setExpandedBottomPrompt} from "../../redux/slices/homeSlice";

//Icons
import {ChevronLeft} from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
//Loading
import LoadingSpinner from "../../components/loadingSpinner/LoadingSpinner";

// Fancy Box
import ImageViewer from "react-simple-image-viewer";
import ChevronDown from "../../assets/icons/ChevronDown";

//Stripe
import {
    useStripe,
    useElements,
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
} from "@stripe/react-stripe-js";
import moment from "moment";
import ExperiencesHomeItem from "../../components/experiences/ExperiencesHomeItem";
//Wallet
import {ethers} from "ethers";
import GiftIcon from "../../assets/icons/GiftIcon";

const ExperienceDetailsPage = () => {
    const {id, tenancy} = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {
        experienceDetails,
        isLoading,
        selectedFeature,
        isPaymentLoading,
        paymentError,
    } = useSelector((state) => state.experiences);
    const {access_token} = useSelector((state) => state.auth);
    const [featureDiscount, setFeatureDiscount] = useState("");
    const [showImage, setShowImage] = useState(0);
    const [buyExperienceModal, setBuyExperienceModal] = useState(false);
    const [successNFTPayment, setSuccessNFTPayment] = useState(false);
    const [nftPaymentMessage, setNftPaymentMessage] = useState("");
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const [walletAddress, setWalletAddress] = useState("");

    const [numberOfPersons, setNumberOfPersons] = useState(1);
    const [cardNumberError, setCardNumberError] = useState(false);
    const [expiryElementError, setExpiryElementError] = useState(false);
    const [cvcElementError, setCvcElementError] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);
    const [paymentMethodSelected, setPaymentMethodSelected] = useState("");
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState(null);

    const [showAll, setShowAll] = useState(false);

    const images = [];
    experienceDetails?.media?.map((img, index) => {
        images.push(img);
    });

    const [imageToShow, setImageToShow] = useState({img: images[0], index: 0});

    const visibleElements = showAll
        ? experienceDetails?.benefits
        : experienceDetails?.benefits?.slice(0, 3);

    const handleChange = (event) => {
        setNumberOfPersons(event.target.value);
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoadingButton(true);
        if (paymentMethodSelected?.method === "stripe") {
            if (!stripe || !elements) {
                return;
            }

            const cardNumberElement = elements.getElement(CardNumberElement);

            const {token, error} = await stripe.createToken(cardNumberElement);

            if (error) {
                setError(error.message);
                setLoadingButton(false);
            } else {
                // Send the token to your server for payment processing
                console.log("Token:", token);
                handlePaymentProcess(token);
                setLoadingButton(false);
            }
        } else if (paymentMethodSelected?.method === "wallet") {
            console.log("Wallet connet");
            requestAccount();
        } else {
            handlePaymentProcess();
        }
    };

    async function requestAccount() {
        console.log("Requesting account...");

        if (window.ethereum) {
            console.log("detected");

            try {
                const accounts = await window.ethereum.request({
                    method: "eth_requestAccounts",
                });
                setWalletAddress(accounts[0]);
                console.log("account", accounts[0]);
                if (accounts[0]) {
                    const walletAddress = accounts[0];
                    const tokenId = 944; // Replace with the specific tokenId you want to check
                    checkNFTOwnership(walletAddress, tokenId);
                    handlePaymentProcess(null, walletAddress);
                }
            } catch (error) {
                console.log("Error connecting...");
            }
        } else {
            alert("Meta Mask not detected");
        }
    }

    const nftContractAddress = "0x19391CA492Fc5994f13E0031Ed5B5D9134a35d14";

    const nftContractTokenId = 944;

    async function checkNFTOwnership(walletAddress) {
        try {
            const provider = new ethers.providers.Web3Provider(window.ethereum);

            // Create a contract instance with the ERC-721 ABI
            const nftContract = new ethers.Contract(
                nftContractAddress,
                ["function ownerOf(uint256 tokenId) view returns (address)"],
                provider
            );
            console.log("walletAddress", walletAddress);
            // Call the ownerOf function to get the owner of the specified token ID
            const owner = await nftContract.ownerOf(nftContractTokenId);
            console.log("owner", owner);
            if (owner?.toLowerCase() === walletAddress?.toLowerCase()) {
                console.log(
                    `The user owns the NFT with token ID ${nftContractTokenId}`
                );
                setNftPaymentMessage(
                    `You are the owner of one of "Society of The Wine Grower" NFT Collection. Hidden Gems and Exclusive Experiences will be Soon Enabled for You!`
                );
                setBuyExperienceModal(false);
                setSuccessNFTPayment(true);
            } else {
                console.log(
                    `The user does not own the NFT with token ID ${nftContractTokenId}`
                );
                setNftPaymentMessage(
                    "You don't own one of our NFT collection , please try again."
                );
                setBuyExperienceModal(false);
                setSuccessNFTPayment(true);
            }
        } catch (error) {
            console.error("Error checking NFT ownership:", error);
        }
    }

    const handlePaymentProcess = (token, wallet_address) => {
        let obj = {
            nbOfSeats: numberOfPersons,
            slug: experienceDetails?.slug,
            amount:
                numberOfPersons *
                (selectedFeature?.discountPrice
                    ? selectedFeature?.discountPrice
                    : selectedFeature?.price),
            date: selectedFeature?.date
                ? moment(selectedFeature?.date).format("YYYY-MM-DD")
                : null,
            featureId: selectedFeature?.id,
            token: token?.id,
            payment_method: paymentMethodSelected?.method,
            wallet_address: wallet_address,
        };

        dispatch(
            purchaseOneExperience(
                obj,
                navigate,
                paymentMethodSelected,
                setBuyExperienceModal
            )
        );
        setLoadingButton(false);
    };

    useEffect(() => {
        if (elements) {
            const cardNumberElement = elements.getElement(CardNumberElement);
            const cardExpiryElement = elements.getElement(CardExpiryElement);
            const cardCvcElement = elements.getElement(CardCvcElement);

            if (cardNumberElement?._implementation?._invalid) {
                setCardNumberError(true);
            } else {
                setCardNumberError(false);
            }
            if (cardExpiryElement?._implementation?._invalid) {
                setExpiryElementError(true);
            } else {
                setExpiryElementError(false);
            }
            if (cardCvcElement?._implementation?._invalid) {
                setCvcElementError(true);
            } else {
                setCvcElementError(false);
            }
        }
    }, [error]);

    useEffect(() => {
        if (
            (!!selectedFeature && experienceDetails?.features?.length > 0) ||
            selectedFeature === undefined
        ) {
            dispatch(setSelectedFeature(experienceDetails?.features[0]));
            setFeatureDiscount(`${experienceDetails?.features[0]?.discount}`);
        }
    }, [experienceDetails]);

    const handleFeatureClick = (feature) => {
        dispatch(setSelectedFeature(feature));
        setFeatureDiscount(`${feature?.discount}`);
    };

    const handleBuyExperince = () => {
        navigate(`/guest-checkout/${experienceDetails?.slug}/${selectedFeature.id}/${tenancy}`);
    };

    const handleBuyExperinceDesktop = () => {
        navigate(`/guest-checkout/${experienceDetails?.slug}/${selectedFeature.id}/${tenancy}`);
    };

    const handleSelectPaymentOption = (method) => {
        setPaymentMethodSelected(method);
    };

    useEffect(() => {
        dispatch(fetchOneExperience(id, tenancy));
    }, [id, tenancy]);

    const openImage = (index) => {
        if (!images[index]) {
            return;
        }
        setCurrentImageIndex(index);
        setShowImage(1);
    };

    useEffect(() => {
        setImageToShow({img: images[0], index: 0});
    }, [experienceDetails]);

    if (showImage) {
        return (
            <>
                <ImageViewer
                    src={images}
                    currentIndex={imageToShow?.index}
                    disableScroll={true}
                    closeOnClickOutside={true}
                    onClose={() => {
                        setShowImage(0);
                    }}
                />
            </>
        );
    }

    return (
        <>
            {isLoading ? (
                <LoadingSpinner/>
            ) : window.innerWidth < 768 ? (
                <Grid
                    container
                    sx={{
                        padding: "10px",
                        height: !access_token && window?.innerWidth < 768 ? "73vh" : "100%",
                        paddingBottom:
                            !access_token && window?.innerWidth > 768 ? "110px" : "0px",
                        overflowY: !access_token && window?.innerWidth < 768 && "scroll",
                    }}
                >
                    <Grid
                        item
                        xs={12}
                        onClick={() => navigate("/experiences")}
                        sx={{
                            display: "flex",
                            alignItems: "flex-start",
                            flexDirection: "row",
                            cursor: "pointer",
                            padding: "2px 10px",
                            color: "#3D3D3D",
                        }}
                    >
                        <ChevronLeft
                            sx={{fontSize: "28px", marginLeft: "-5px", color: "#3D3D3D"}}
                        />
                        <Typography
                            sx={{
                                fontSize: "19px",
                                color: "#3D3D3D",
                                fontFamily: "Fraunces",
                                fontWeight: "600",
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                                WebkitLineClamp: 3,
                                textOverflow: "ellipsis",
                                maxHeight: "86px",
                            }}
                        >
                            {experienceDetails?.title}
                        </Typography>
                    </Grid>
                    <Grid container sx={{padding: "0px 10px"}}>
                        <Grid item xs={12} sx={{marginTop: "10px"}}>
                            <Grid container sx={{height: "257px"}}>
                                <Grid
                                    onClick={() => {
                                        openImage(imageToShow?.index);
                                    }}
                                    item
                                    xs={9}
                                    sx={{
                                        width: "260px",
                                        minHeight: "257px",
                                        height: "auto",
                                        borderRadius: "5px",
                                        backgroundImage: `url(${imageToShow?.img})`,
                                        backgroundSize: "cover",
                                        backgroundRepeat: "no-repeat",
                                        backgroundPosition: "center",
                                        position: "relative",
                                    }}
                                >
                                    {featureDiscount !== null &&
                                        featureDiscount !== "null" &&
                                        Object.keys(featureDiscount).length > 0 && (
                                            <ExperienceDiscountItem discount={featureDiscount}/>
                                        )}
                                </Grid>
                                <Grid item xs={3}>
                                    <Grid
                                        container
                                        sx={{
                                            display: "flex",
                                            alignItems: "flex-end",
                                            justifyContent: "flex-end",
                                            flexDirection: "row",
                                            width: "100%",
                                            marginLeft: "2px",
                                        }}
                                    >
                                        {images?.map((img, index) => {
                                            if (index > 0) {
                                                return (
                                                    <Grid
                                                        onClick={() => {
                                                            setImageToShow({img: img, index: index});
                                                        }}
                                                        item
                                                        xs={12}
                                                        key={index}
                                                        sx={{
                                                            minwidth: "88px",
                                                            width: "100%",
                                                            // maxWidth: "88px",
                                                            minHeight: "88px",
                                                            height: "auto",
                                                            margin: index === 2 ? "7px 0px" : 0,
                                                            borderRadius: "5px",
                                                            backgroundImage: `url(${img})`,
                                                            backgroundSize: "cover",
                                                            backgroundRepeat: "no-repeat",
                                                            backgroundPosition: "center",
                                                        }}
                                                    />
                                                );
                                            }
                                        })}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{marginTop: "40px"}}>
                            {experienceDetails?.features?.length > 0 &&
                                experienceDetails?.features?.map((feature, index) => (
                                    <Grid
                                        container
                                        key={index}
                                        sx={{
                                            display: "flex",
                                            alignItems: "flex-start",
                                            marginTop: "5px",
                                            marginBottom: "8px",
                                            cursor: "pointer",
                                            borderRadius: "5px",
                                        }}
                                        onClick={() => handleFeatureClick(feature)}
                                    >
                                        <Grid item xs={1}>
                                            <Box
                                                sx={{
                                                    position: "relative",
                                                    borderRadius: "50%",
                                                    border: "2px solid #0070CC",
                                                    width: "16px",
                                                    height: "16px",
                                                    backgroundColor: "#0070CC",
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        position: "absolute",
                                                        background:
                                                            selectedFeature?.id === feature?.id
                                                                ? "#0070CC"
                                                                : "#FFFFFF",
                                                        border: "2px solid white",
                                                        borderRadius: "50%",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        width: "12px",
                                                        height: "12px",
                                                    }}
                                                ></Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={11}>
                                            <Typography
                                                sx={{
                                                    fontFamily: "inherit",
                                                    fontSize: "15px",
                                                    fontWeight: "600",
                                                }}
                                            >
                                                {feature?.title}
                                            </Typography>
                                            {feature?.start_to_end && (
                                                <Typography
                                                    sx={{
                                                        fontFamily: "inherit",
                                                        fontSize: "15px",
                                                        color: "rgba(61, 61, 61, 0.90)",
                                                    }}
                                                >
                                                    {feature?.start_to_end}
                                                </Typography>
                                            )}
                                            {feature?.discountPrice && (
                                                <Typography
                                                    sx={{
                                                        fontFamily: "inherit",
                                                        fontSize: "15px",
                                                        color: "#FF001F",
                                                        marginRight: "5px",
                                                        textDecoration: "line-through",
                                                        fontWeight: "600",
                                                    }}
                                                >
                                                    ${feature?.discountPrice && feature?.price}
                                                </Typography>
                                            )}
                                            <Typography
                                                sx={{
                                                    fontFamily: "inherit",
                                                    fontSize: "15px",
                                                    color: "#00020E",
                                                    fontWeight: "600",
                                                }}
                                            >
                                                $
                                                {feature?.discountPrice
                                                    ? feature?.discountPrice
                                                    : feature?.price}
                                            </Typography>
                                        </Grid>
                                        <Divider
                                            sx={{
                                                backgroundColor: "#E6E7E8",
                                                width: "100%",
                                                margin: "10px 0px",
                                            }}
                                        />
                                    </Grid>
                                ))}
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                onClick={() => handleBuyExperince()}
                                sx={{
                                    backgroundColor: "#0077D9",
                                    borderRadius: "6px",
                                    textTransform: "none",
                                    marginTop: "10px",
                                    height: "40px",
                                    width: "100%",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    fontFamily: "inherit",
                                    "&:hover": {
                                        backgroundColor: "#0077D9",
                                    },
                                }}
                            >
                                Buy Now
                            </Button>
                        </Grid>
                        <Grid item xs={12} sx={{marginTop: "20px"}}>
                            <Typography
                                sx={{
                                    fontFamily: "inherit",
                                    color: "#3D3D3D",
                                    fontSize: "18px",
                                    fontWeight: "600",
                                    marginBottom: "10px",
                                }}
                            >
                                Things you will get
                            </Typography>
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    paddingBottom:
                                        experienceDetails?.policies?.length > 0 ? "10px" : "30px",
                                }}
                            >
                                {visibleElements?.map((benefit, index) => {
                                    return <ExperienceBenefitItem item={benefit} key={index}/>;
                                })}
                                {experienceDetails?.benefits?.length > 3 && (
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: showAll ? "flex-end" : "stretch",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => setShowAll(!showAll)}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: "13px",
                                                fontWeight: "600",
                                                color: "#3D3D3D",
                                                textDecoration: "underline",
                                                fontFamily: "inherit",
                                            }}
                                        >
                                            Full Description
                                        </Typography>
                                        <Box sx={{rotate: showAll ? "180deg" : "0deg"}}>
                                            <ChevronDown/>
                                        </Box>
                                    </Box>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    {experienceDetails?.policies?.length > 0 && (
                        <Grid item xs={12} sx={{paddingBottom: "30px"}}>
                            {experienceDetails?.policies?.map((policy, index) => {
                                return (
                                    <Grid key={index}>
                                        <ExperienceAccordion
                                            title={policy?.title}
                                            description={policy?.description}
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid>
                    )}

                    {!access_token && <BottomSignInPrompt/>}
                </Grid>
            ) : (
                <Grid
                    container
                    sx={{
                        padding: "0px 60px",
                        alignItems: "flex-start",
                    }}
                >
                    <Grid
                        item
                        sm={12}
                        onClick={() => navigate("/discover")}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "row",
                            cursor: "pointer",
                            color: "#3D3D3D",
                            marginTop: "20px",
                        }}
                    >
                        <ChevronLeft
                            sx={{fontSize: "28px", marginLeft: "-10px", color: "#3D3D3D"}}
                        />
                        <Typography
                            sx={{
                                fontSize: "20px",
                                color: "#3D3D3D",
                                fontFamily: "Fraunces",
                                fontWeight: "600",
                            }}
                        >
                            Experiences
                        </Typography>
                    </Grid>
                    <Grid item sm={7} sx={{marginTop: "20px", padding: "0px"}}>
                        <Typography
                            sx={{
                                fontSize: "20px",
                                color: "#a5a5a5a5",
                                fontFamily: "Fraunces",
                                fontWeight: "600",
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                                WebkitLineClamp: 2,
                                textOverflow: "ellipsis",
                                maxHeight: "69px",
                            }}
                        >
                            {experienceDetails?.category}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: "23px",
                                color: "#3D3D3D",
                                fontFamily: "Fraunces",
                                fontWeight: "600",
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                                WebkitLineClamp: 2,
                                textOverflow: "ellipsis",
                                maxHeight: "69px",
                            }}
                        >
                            {experienceDetails?.title}
                        </Typography>
                    </Grid>
                    <Grid item sm={7} sx={{padding: "0px 60px 0px 0px"}}>
                        <Grid container>
                            <Grid
                                item
                                sm={12}
                                onClick={() => {
                                    openImage(0);
                                }}
                                sx={{
                                    minHeight: "350px",
                                    height: "auto",
                                    borderRadius: "5px",
                                    position: "relative",
                                    cursor: "pointer",
                                    marginTop: "20px",
                                    overflow: "hidden",
                                }}
                            >
                                <Grid
                                    sx={{
                                        width: "100%",
                                        height: "100%",
                                        backgroundImage: `url(${imageToShow?.img})`,
                                        backgroundSize: "cover",
                                        backgroundRepeat: "no-repeat",
                                        backgroundPosition: "center",
                                        transition:
                                            window.innerWidth > 768 && "transform 0.3s ease-in-out",
                                        "&:hover": {
                                            transform: window.innerWidth > 768 && "scale(1.1)",
                                        },
                                    }}
                                ></Grid>
                                {featureDiscount !== null &&
                                    featureDiscount !== "null" &&
                                    Object.keys(featureDiscount).length > 0 && (
                                        <ExperienceDiscountItem discount={featureDiscount}/>
                                    )}
                            </Grid>
                            <Grid item sm={12}>
                                <Grid
                                    container
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        flexDirection: "row",
                                        width: "100%",
                                        marginLeft: "2px",
                                        marginTop: "20px",
                                        cursor: "pointer",
                                    }}
                                >
                                    {images?.map((img, index) => {
                                        if (index > 0) {
                                            return (
                                                <Grid
                                                    onClick={() => {
                                                        setImageToShow({img: img, index: index});
                                                    }}
                                                    item
                                                    xs={2}
                                                    lg={1}
                                                    key={index}
                                                    sx={{
                                                        minwidth: "55px",
                                                        width: "55px",
                                                        // maxWidth: "55px",
                                                        minHeight: "55px",
                                                        height: "auto",
                                                        margin: "5px 10px 5px 0px",
                                                        borderRadius: "5px",
                                                        backgroundImage: `url(${img})`,
                                                        backgroundSize: "cover",
                                                        backgroundRepeat: "no-repeat",
                                                        backgroundPosition: "center",
                                                    }}
                                                />
                                            );
                                        }
                                    })}
                                </Grid>
                            </Grid>
                            <Grid item sm={12} sx={{marginTop: "20px"}}>
                                {experienceDetails?.policies?.map((policy, index) => {
                                    return (
                                        <Grid key={index} sx={{marginBottom: "20px"}}>
                                            <ExperienceAccordion
                                                title={policy?.title}
                                                description={policy?.description}
                                            />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sm={5} sx={{padding: "0px 50px 0px 10px"}}>
                        <Grid container sx={{marginTop: "15px"}}>
                            <Grid item xs={12}>
                                {experienceDetails?.features?.length > 0 &&
                                    experienceDetails?.features?.map((feature, index) => (
                                        <Grid
                                            container
                                            key={index}
                                            sx={{
                                                display: "flex",
                                                alignItems: "flex-start",
                                                marginTop: "5px",
                                                marginBottom: "8px",
                                                cursor: "pointer",
                                                borderRadius: "5px",
                                            }}
                                            onClick={() => handleFeatureClick(feature)}
                                        >
                                            <Grid item xs={1}>
                                                <Box
                                                    sx={{
                                                        position: "relative",
                                                        borderRadius: "50%",
                                                        border: "2px solid #0070CC",
                                                        width: "16px",
                                                        height: "16px",
                                                        backgroundColor: "#0070CC",
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            position: "absolute",
                                                            background:
                                                                selectedFeature?.id === feature?.id
                                                                    ? "#0070CC"
                                                                    : "#FFFFFF",
                                                            border: "2px solid white",
                                                            borderRadius: "50%",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            width: "12px",
                                                            height: "12px",
                                                        }}
                                                    ></Box>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={11}>
                                                <Typography
                                                    sx={{
                                                        fontFamily: "inherit",
                                                        fontSize: "17px",
                                                        fontWeight: "600",
                                                        color: "#3D3D3D",
                                                    }}
                                                >
                                                    {feature?.title}
                                                </Typography>
                                                {feature?.start_to_end && (
                                                    <Typography
                                                        sx={{
                                                            fontFamily: "inherit",
                                                            fontSize: "14px",
                                                            color: "rgba(61, 61, 61, 0.90)",
                                                        }}
                                                    >
                                                        {feature?.start_to_end}
                                                    </Typography>
                                                )}
                                                {feature?.discountPrice && (
                                                    <Typography
                                                        sx={{
                                                            fontFamily: "inherit",
                                                            fontSize: "17px",
                                                            color: "#FF001F",
                                                            marginRight: "5px",
                                                            textDecoration: "line-through",
                                                            fontWeight: "600",
                                                        }}
                                                    >
                                                        ${feature?.discountPrice && feature?.price}
                                                    </Typography>
                                                )}
                                                <Typography
                                                    sx={{
                                                        fontFamily: "inherit",
                                                        fontSize: "16px",
                                                        color: "#00020E",
                                                        fontWeight: "600",
                                                    }}
                                                >
                                                    $
                                                    {feature?.discountPrice
                                                        ? feature?.discountPrice
                                                        : feature?.price}
                                                </Typography>
                                            </Grid>
                                            <Divider
                                                sx={{
                                                    backgroundColor: "#E6E7E8",
                                                    width: "100%",
                                                    margin: "10px 0px",
                                                }}
                                            />
                                        </Grid>
                                    ))}
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    onClick={() => handleBuyExperinceDesktop()}
                                    sx={{
                                        backgroundColor: "#0077D9",
                                        borderRadius: "6px",
                                        textTransform: "none",
                                        marginTop: "10px",
                                        height: "42px",
                                        width: "100%",
                                        fontSize: "16px",
                                        fontWeight: "500",
                                        fontFamily: "inherit",
                                        "&:hover": {
                                            backgroundColor: "#0077D9",
                                        },
                                    }}
                                >
                                    Buy Now
                                </Button>
                            </Grid>
                            <Grid item xs={12} sx={{marginTop: "20px"}}>
                                <Typography
                                    sx={{
                                        fontFamily: "inherit",
                                        color: "#3D3D3D",
                                        fontSize: "20px",
                                        fontWeight: "600",
                                        marginBottom: "10px",
                                    }}
                                >
                                    Things you will get
                                </Typography>
                                <Grid item xs={12}>
                                    {visibleElements?.map((benefit, index) => {
                                        return <ExperienceBenefitItem item={benefit} key={index}/>;
                                    })}
                                    {experienceDetails?.benefits?.length > 3 && (
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: showAll ? "flex-end" : "stretch",
                                                cursor: "pointer",
                                            }}
                                            onClick={() => setShowAll(!showAll)}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: "13px",
                                                    fontWeight: "600",
                                                    color: "#3D3D3D",
                                                    textDecoration: "underline",
                                                    fontFamily: "inherit",
                                                }}
                                            >
                                                Full Description
                                            </Typography>
                                            <Box sx={{rotate: showAll ? "180deg" : "0deg"}}>
                                                <ChevronDown/>
                                            </Box>
                                        </Box>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {experienceDetails?.experiences?.length > 0 && (
                        <Grid item xs={12} sx={{padding: "0px", marginTop: "20px"}}>
                            <Grid
                                container
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Grid item>
                                    <Typography
                                        sx={{
                                            color: "#3D3D3D",
                                            fontFamily: "inherit",
                                            fontSize: "23px",
                                            fontWeight: "600",
                                        }}
                                    >
                                        You may also like
                                    </Typography>
                                </Grid>
                                <Grid item onClick={() => navigate("/discover")}>
                                    <Typography
                                        sx={{
                                            color: "rgba(61, 61, 61, 0.80)",
                                            fontSize: "16px",
                                            fontWeight: "500",
                                            fontFamily: "inherit",
                                            opacity: 0.8,
                                            cursor: "pointer",
                                        }}
                                    >
                                        See all
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container columnSpacing={3} sx={{marginTop: "15px"}}>
                                {experienceDetails?.experiences?.map((experience, index) => {
                                    return (
                                        <Grid item xs={4} key={index}>
                                            <ExperiencesHomeItem experience={experience} tenancy={tenancy}/>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Grid>
                    )}

                    <Modal
                        open={buyExperienceModal}
                        onClose={() => setBuyExperienceModal(false)}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                        disableAutoFocus={true}
                    >
                        <Box
                            sx={{
                                backgroundColor: "#FFFFFF",
                                minHeight:
                                    paymentMethodSelected?.method === "stripe" ? "430px" : "0px",
                                padding: "0px 30px 20px 30px",
                                borderRadius: "20px",
                                maxWidth: "600px",
                            }}
                        >
                            <Grid container>
                                <Grid item xs={12} sx={{padding: "0px 5px"}}>
                                    <Grid container sx={{marginTop: "20px"}}>
                                        <Grid item xs={10}>
                                            <Typography
                                                sx={{
                                                    fontFamily: "inherit",
                                                    fontSize: "19px",
                                                    fontWeight: "600",
                                                    color: "#3D3D3D",
                                                }}
                                            >
                                                {selectedFeature?.title}
                                            </Typography>
                                            <Box sx={{margin: "10px 0px"}}>
                                                <Typography
                                                    sx={{
                                                        fontFamily: "inherit",
                                                        color: "rgba(61, 61, 61, 0.70)",
                                                        fontSize: "17px",
                                                    }}
                                                >
                                                    {selectedFeature?.date}
                                                </Typography>
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    flexDirection: "row",
                                                    marginTop: "10px",
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontFamily: "inherit",
                                                        fontSize: "18px",
                                                        color: "rgba(61, 61, 61, 0.80)",
                                                    }}
                                                >
                                                    Number of persons:
                                                </Typography>
                                                <Box sx={{marginLeft: "10px"}}>
                                                    <Select
                                                        value={numberOfPersons}
                                                        onChange={handleChange}
                                                        IconComponent={ExpandMoreIcon}
                                                        sx={{
                                                            width: "70px",
                                                            height: "40px",
                                                            fontFamily: "inherit",
                                                            "& .MuiOutlinedInput-input": {
                                                                padding: "3px 0px 3px 15px",
                                                            },
                                                        }}
                                                        MenuProps={{
                                                            style: {
                                                                maxHeight: "300px",
                                                            },
                                                        }}
                                                    >
                                                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((x) => {
                                                            return (
                                                                <MenuItem
                                                                    value={x}
                                                                    key={x}
                                                                    sx={{
                                                                        color: "#3D3D3D",
                                                                        fontFamily: "inherit",
                                                                    }}
                                                                >
                                                                    {x}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    </Select>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography
                                                sx={{
                                                    fontFamily: "inherit",
                                                    fontSize: "18px",
                                                    fontWeight: "700",
                                                    color: "#FF001F",
                                                    textAlign: "right",
                                                }}
                                            >
                                                $
                                                {numberOfPersons *
                                                    (selectedFeature?.discountPrice
                                                        ? selectedFeature?.discountPrice
                                                        : selectedFeature?.price)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider
                                        sx={{
                                            backgroundColor: "#E6E7E8",
                                            width: "100%",
                                            margin: "10px 0px",
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sx={{marginTop: "10px", padding: "0px 5px"}}
                                >
                                    <Typography
                                        sx={{
                                            fontFamily: "inherit",
                                            color: "#3D3D3D",
                                            fontSize: "15px",
                                            fontWeight: "600",
                                        }}
                                    >
                                        Payment method
                                    </Typography>

                                    <Grid container sx={{margin: "10px 0px"}}>
                                        {[
                                            {method: "stripe", name: "Credit Card"},
                                            {method: "crypto", name: "Crypto"},
                                            {method: "wallet", name: "Connect Wallet"},
                                        ].map((method, index) => {
                                            return (
                                                <Grid key={index} item xs={4}>
                                                    <Grid
                                                        container
                                                        sx={{
                                                            display: "flex",
                                                            alignItems: "flex-start",
                                                            flexDirection: "row",
                                                            marginTop: "5px",
                                                            cursor: "pointer",
                                                            borderRadius: "5px",
                                                        }}
                                                        onClick={() => handleSelectPaymentOption(method)}
                                                    >
                                                        <Grid item xs={1}>
                                                            <Box
                                                                sx={{
                                                                    position: "relative",
                                                                    borderRadius: "50%",
                                                                    border: "2px solid #0070CC",
                                                                    width: "16px",
                                                                    height: "16px",
                                                                    backgroundColor: "#0070CC",
                                                                }}
                                                            >
                                                                <Box
                                                                    sx={{
                                                                        position: "absolute",
                                                                        background:
                                                                            paymentMethodSelected?.name ===
                                                                            method?.name
                                                                                ? "#0070CC"
                                                                                : "#FFFFFF",
                                                                        border: "2px solid white",
                                                                        borderRadius: "50%",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        justifyContent: "center",
                                                                        width: "12px",
                                                                        height: "12px",
                                                                    }}
                                                                ></Box>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={11}>
                                                            <Typography
                                                                sx={{
                                                                    fontFamily: "inherit",
                                                                    fontSize: "14px",
                                                                    fontWeight: "600",
                                                                    marginLeft: "10px",
                                                                }}
                                                            >
                                                                {method?.name}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </Grid>
                                {paymentMethodSelected?.method === "stripe" && (
                                    <>
                                        <Grid
                                            item
                                            xs={12}
                                            sx={{marginTop: "10px", padding: "0px 5px"}}
                                        >
                                            <Typography
                                                sx={{
                                                    fontFamily: "inherit",
                                                    color: "#3D3D3D",
                                                    fontSize: "19px",
                                                    fontWeight: "600",
                                                }}
                                            >
                                                Enter your card details
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sx={{marginTop: "20px", padding: "0px 5px"}}
                                        >
                                            <form onSubmit={handleSubmit}>
                                                <Grid container spacing={2} alignItems="center">
                                                    <Grid item xs={12}>
                                                        <Box
                                                            sx={{
                                                                border: cardNumberError
                                                                    ? "1px solid #9e2146"
                                                                    : "1px solid #ced4da",
                                                                borderRadius: "4px",
                                                                padding: "10px",
                                                            }}
                                                        >
                                                            <CardNumberElement
                                                                options={{
                                                                    showIcon: true,
                                                                    placeholder: "Card Number",
                                                                    style: {
                                                                        base: {
                                                                            fontSize: "14px",
                                                                            color: "rgba(61, 61, 61, 0.70)",
                                                                            "::placeholder": {
                                                                                color: "rgba(61, 61, 61, 0.20)",
                                                                            },
                                                                        },
                                                                        invalid: {
                                                                            color: "#9e2146",
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Box
                                                            sx={{
                                                                border: expiryElementError
                                                                    ? "1px solid #9e2146"
                                                                    : "1px solid #ced4da",
                                                                borderRadius: "4px",
                                                                padding: "10px",
                                                            }}
                                                        >
                                                            <CardExpiryElement
                                                                options={{
                                                                    placeholder: "Expiry Date",
                                                                    style: {
                                                                        base: {
                                                                            fontSize: "14px",
                                                                            color: "rgba(61, 61, 61, 0.70)",
                                                                            "::placeholder": {
                                                                                color: "rgba(61, 61, 61, 0.20)",
                                                                            },
                                                                        },
                                                                        invalid: {
                                                                            color: "#9e2146",
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Box
                                                            sx={{
                                                                border: cvcElementError
                                                                    ? "1px solid #9e2146"
                                                                    : "1px solid #ced4da",
                                                                borderRadius: "4px",
                                                                padding: "10px",
                                                            }}
                                                        >
                                                            <CardCvcElement
                                                                options={{
                                                                    placeholder: "Enter CVC",

                                                                    style: {
                                                                        base: {
                                                                            fontSize: "14px",
                                                                            color: "rgba(61, 61, 61, 0.70)",

                                                                            "::placeholder": {
                                                                                color: "rgba(61, 61, 61, 0.20)",
                                                                            },
                                                                        },
                                                                        invalid: {
                                                                            color: "#9e2146",
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    {error && (
                                                        <Grid item xs={12}>
                                                            <Typography
                                                                sx={{
                                                                    fontFamily: "inherit",
                                                                    fontSize: "12px",
                                                                    color: "#FF001F",
                                                                }}
                                                            >
                                                                {error}
                                                            </Typography>
                                                        </Grid>
                                                    )}
                                                    {paymentError && (
                                                        <Grid item xs={12}>
                                                            <Typography
                                                                sx={{
                                                                    fontFamily: "inherit",
                                                                    fontSize: "12px",
                                                                    color: "#FF001F",
                                                                }}
                                                            >
                                                                {paymentError}
                                                            </Typography>
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            </form>
                                        </Grid>
                                    </>
                                )}

                                <Grid item xs={12}>
                                    <Button
                                        onClick={(e) => {
                                            handleSubmit(e);
                                        }}
                                        variant="contained"
                                        type="submit"
                                        // disabled={
                                        //   !stripe ||
                                        //   isPaymentLoading ||
                                        //   loadingButton ||
                                        //   !!paymentMethodSelected?.method === false
                                        // }
                                        sx={{
                                            backgroundColor: "#0060FF !important",
                                            borderRadius: "5px",
                                            textTransform: "none",
                                            marginTop:
                                                error || paymentError
                                                    ? "10px"
                                                    : paymentMethodSelected?.method === "stripe"
                                                        ? "30px"
                                                        : "10px",
                                            height: "40px",
                                            width: "100%",
                                            fontSize: "16px",
                                            fontWeight: "600",
                                            fontFamily: "inherit",
                                            color: "#fff !important",
                                            "&:hover": {
                                                backgroundColor: "#0060FF !important",
                                            },
                                            "&:disabled": {
                                                opacity: 0.5,
                                            },
                                        }}
                                    >
                                        {isPaymentLoading ? (
                                            <>
                                                <CircularProgress
                                                    size={20}
                                                    style={{
                                                        color: "#fff",
                                                    }}
                                                />
                                            </>
                                        ) : (
                                            "Pay"
                                        )}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Modal>
                    <Modal
                        open={successNFTPayment}
                        onClose={() => setSuccessNFTPayment(false)}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                        disableAutoFocus={true}
                    >
                        <Grid
                            container
                            sx={{
                                backgroundColor: "#FFFFFF",
                                // minHeight: "400px",
                                padding: "20px 30px 20px 30px",
                                borderRadius: "20px",
                                maxWidth: "400px",
                            }}
                        >
                            {nftPaymentMessage?.includes("You are the owner of one") && (
                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        marginBottom: "10px",
                                    }}
                                >
                                    <GiftIcon/>
                                </Grid>
                            )}
                            {nftPaymentMessage?.includes("You are the owner of one") && (
                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        marginBottom: "10px",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontFamily: "inherit",
                                            color: "#3D3D3D",
                                            fontSize: "17px",
                                            fontWeight: "600",
                                            marginBottom: "10px",
                                        }}
                                    >
                                        Congratulations
                                    </Typography>
                                </Grid>
                            )}
                            {!nftPaymentMessage?.includes("You are the owner of one") && (
                                <Grid item xs={12} sx={{marginTop: "10px"}}>
                                    <Typography
                                        sx={{
                                            fontFamily: "inherit",
                                            color: "#3D3D3D",
                                            fontSize: "17px",
                                            fontWeight: "600",
                                            marginBottom: "10px",
                                        }}
                                    >
                                        NFT payment
                                    </Typography>
                                </Grid>
                            )}

                            <Grid item xs={12}>
                                <Typography
                                    sx={{
                                        fontFamily: "inherit",
                                        color: "#3D3D3D",
                                        fontSize: "15px",
                                        fontWeight: "600",
                                        marginBottom: "10px",
                                    }}
                                >
                                    {nftPaymentMessage}
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <Button
                                    onClick={() => {
                                        setSuccessNFTPayment(false);
                                    }}
                                    variant="contained"
                                    type="submit"
                                    sx={{
                                        backgroundColor: "#0060FF !important",
                                        borderRadius: "5px",
                                        textTransform: "none",
                                        marginTop:
                                            error || paymentError
                                                ? "10px"
                                                : paymentMethodSelected?.method === "stripe"
                                                    ? "30px"
                                                    : "10px",
                                        height: "40px",
                                        width: "100%",
                                        fontSize: "16px",
                                        fontWeight: "600",
                                        fontFamily: "inherit",
                                        color: "#fff !important",
                                        "&:hover": {
                                            backgroundColor: "#0060FF !important",
                                        },
                                        "&:disabled": {
                                            opacity: 0.5,
                                        },
                                    }}
                                >
                                    Close
                                </Button>
                            </Grid>
                        </Grid>
                    </Modal>
                </Grid>
            )}
        </>
    );
};

export default ExperienceDetailsPage;
