import React, {useEffect} from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import {
    Avatar,
    Divider,
    IconButton,
    InputAdornment,
    TextField,
    Typography,
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {
    addPostComments,
    fetchPostComments,
} from "../../redux/slices/homeSlice";
import CommentComponent from "../comments/CommentComponent";
import LoadingSpinner from "../loadingSpinner/LoadingSpinner";
//Validation and Formik
import * as yup from "yup";
import {Form, Formik} from "formik";
import SendIcon from "../../assets/icons/SendIcon";

const BottomCommentDrawer = ({
                                 isOpen,
                                 tenancy_id,
                                 handleCloseDrawer,
                                 postId,
                                 isHomepage,
                                 helperText
                             }) => {
    const dispatch = useDispatch();
    const {access_token} = useSelector((state) => state.auth);
    const {postComments, isCommentsLoading, headerFilterObjectState} =
        useSelector((state) => state.home);
    const validationSchema = yup.object({
        postComment: yup
            .string()
            .required("Comment is required")
            .min(3, "Comment must be at least 3 characters long"),
    });
    const handleSubmit = (values, {resetForm}) => {
        let obj = {
            slug: postId,
            text: values.postComment,
        };

        dispatch(addPostComments(obj, postId, isHomepage, headerFilterObjectState));
        resetForm();
    };

    useEffect(() => {
        alert(tenancy_id)
        if (isOpen) {
            dispatch(fetchPostComments(postId, tenancy_id));
        }
    }, [postId, isOpen, tenancy_id]);

    return (
        <div>
            <Drawer
                anchor={"bottom"}
                open={isOpen}
                onClose={(e) => handleCloseDrawer(e)}
                sx={{
                    overflowY: "auto",
                    position: "relative",
                    zIndex: 100,
                    "& .MuiDrawer-paper": {
                        borderTopRightRadius: "20px",
                        borderTopLeftRadius: "20px",
                        backgroundColor: "#3D3D3D",
                        height: "350px",
                        width: "100%",
                        overflowX: "hidden",
                    },
                    "& .MuiDrawer-paper::-webkit-scrollbar": {
                        display: "none",
                    },
                }}
                onClick={(e) => e.stopPropagation()}
            >
                <Box
                    sx={{
                        flexDirection: "column",
                    }}
                >
                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            backgroundColor: "#3D3D3D",
                            position: "fixed",
                            borderTopRightRadius: "20px",
                            borderTopLeftRadius: "20px",
                            zIndex: 100,
                        }}
                    >
                        <Box
                            sx={{
                                height: "3px",
                                borderRadius: "2px",
                                width: "30px",
                                background: "white",
                                marginTop: "15px",
                            }}
                        />
                        <Typography
                            sx={{
                                fontFamily: "inherit",
                                color: "#FBFBFB",
                                fontSize: "13px",
                                fontWeight: "600",
                                marginTop: "10px",
                            }}
                        >
                            Comments
                        </Typography>
                        <Divider
                            sx={{
                                backgroundColor: "rgba(255, 255, 255, 0.10)",
                                width: "100%",
                                margin: "5px 0px",
                            }}
                        />
                    </Box>
                </Box>

                <Box sx={{paddingBottom: "60px", paddingTop: "55px"}}>
                    {isCommentsLoading ? (
                        <Box>
                            <LoadingSpinner pageHeight="100px" size={30}/>
                        </Box>
                    ) : postComments?.length > 0 ? (
                        postComments?.map((comment, index) => {
                            return (
                                <Box key={index} sx={{padding: "10px 20px"}}>
                                    <CommentComponent item={comment} isCommentDrawer/>
                                </Box>
                            );
                        })
                    ) : (
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                color: "#FBFBFB",
                            }}
                        >
                            No comments available
                        </Box>
                    )}
                </Box>
                {access_token && (
                    <Box
                        sx={{
                            position: "fixed",
                            bottom: 0,
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            padding: "10px 20px",
                            backgroundColor: "#3D3D3D",
                        }}
                    >
                        <Avatar
                            alt={"User"}
                            src="/"
                            sx={{
                                width: 30,
                                height: 30,
                                backgroundColor: "#1976d2",
                                margin: "10px 5px 10px 0px",
                            }}
                        />
                        <Box
                            sx={{
                                width: {
                                    xs: "80%",
                                    sm: "100%",
                                },
                            }}
                        >
                            <Formik
                                initialValues={{
                                    postComment: "",
                                }}
                                validationSchema={validationSchema}
                                onSubmit={(values, formikBag) =>
                                    handleSubmit(values, formikBag)
                                }
                            >
                                {({
                                      values,
                                      handleChange,
                                      handleBlur,
                                      touched,
                                      errors,
                                      submitForm,
                                  }) => (
                                    <Form>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                flexDirection: "row",
                                            }}
                                        >
                                            <TextField
                                                name="postComment"
                                                placeholder="Add a comment..."
                                                variant="standard"
                                                value={values.postComment}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={
                                                    touched.postComment && Boolean(errors.postComment)
                                                }
                                                helperText={
                                                    touched.postComment ? errors.postComment : ""
                                                }
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                edge="end"
                                                                onClick={() => submitForm()}
                                                            >
                                                                <SendIcon color={"#FFFFFF"}/>
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                sx={{
                                                    width: "100%",
                                                    fontSize: "12px",
                                                    fontFamily: "inherit",
                                                    color: "#FBFBFB",
                                                    "& .MuiInput-root": {
                                                        color: "#FBFBFB",
                                                    },
                                                    "& .MuiInput-input": {
                                                        fontSize: "12px",
                                                        fontFamily: "inherit",
                                                        color: "#FBFBFB",
                                                    },
                                                    ".MuiFormHelperText-root.Mui-error": {
                                                        fontSize: "10px",
                                                    },
                                                    "& .MuiInput-root:before": {
                                                        borderColor: "#FBFBFB !important",
                                                    },
                                                    "& .Mui-error:before": {
                                                        borderColor: "#d32f2f !important",
                                                    },
                                                    "& .MuiInput-root:after": {
                                                        borderColor: "#FBFBFB !important",
                                                    },
                                                    "& input::placeholder": {
                                                        color: "#FBFBFB",
                                                        fontFamily: "Poppins",
                                                        fontWeight: 500,
                                                    },
                                                }}
                                            />
                                        </Box>
                                    </Form>
                                )}
                            </Formik>
                        </Box>
                    </Box>
                )}
            </Drawer>
        </div>
    );
};

export default BottomCommentDrawer;
