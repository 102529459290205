import React from "react";

const ExperienceSuccessIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9995 25.2002C15.4704 25.2002 16.9269 24.9105 18.2858 24.3476C19.6448 23.7847 20.8795 22.9597 21.9196 21.9196C22.9597 20.8795 23.7847 19.6448 24.3476 18.2859C24.9105 16.9269 25.2002 15.4704 25.2002 13.9995C25.2002 12.5286 24.9105 11.0721 24.3476 9.71321C23.7847 8.35427 22.9597 7.11952 21.9196 6.07944C20.8795 5.03936 19.6448 4.21432 18.2858 3.65143C16.9269 3.08854 15.4704 2.79883 13.9995 2.79883C11.0289 2.79883 8.17998 3.9789 6.07944 6.07944C3.9789 8.17998 2.79883 11.0289 2.79883 13.9995C2.79883 16.9701 3.9789 19.8191 6.07944 21.9196C8.17998 24.0202 11.0289 25.2002 13.9995 25.2002ZM19.1893 12.19C19.4443 11.926 19.5855 11.5723 19.5823 11.2053C19.5791 10.8382 19.4318 10.4871 19.1723 10.2275C18.9127 9.96791 18.5616 9.82067 18.1945 9.81748C17.8274 9.81429 17.4738 9.95541 17.2097 10.2104L12.5995 14.8206L10.7893 13.0104C10.5253 12.7554 10.1716 12.6143 9.80457 12.6175C9.43749 12.6207 9.08635 12.7679 8.82678 13.0275C8.56721 13.2871 8.41997 13.6382 8.41678 14.0053C8.41359 14.3723 8.55471 14.726 8.80973 14.99L11.6097 17.79C11.8723 18.0525 12.2283 18.1999 12.5995 18.1999C12.9708 18.1999 13.3268 18.0525 13.5893 17.79L19.1893 12.19Z"
        fill="#D9F3CE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9995 25.2002C15.4704 25.2002 16.9269 24.9105 18.2858 24.3476C19.6448 23.7847 20.8795 22.9597 21.9196 21.9196C22.9597 20.8795 23.7847 19.6448 24.3476 18.2859C24.9105 16.9269 25.2002 15.4704 25.2002 13.9995C25.2002 12.5286 24.9105 11.0721 24.3476 9.71321C23.7847 8.35427 22.9597 7.11952 21.9196 6.07944C20.8795 5.03936 19.6448 4.21432 18.2858 3.65143C16.9269 3.08854 15.4704 2.79883 13.9995 2.79883C11.0289 2.79883 8.17998 3.9789 6.07944 6.07944C3.9789 8.17998 2.79883 11.0289 2.79883 13.9995C2.79883 16.9701 3.9789 19.8191 6.07944 21.9196C8.17998 24.0202 11.0289 25.2002 13.9995 25.2002ZM19.1893 12.19C19.4443 11.926 19.5855 11.5723 19.5823 11.2053C19.5791 10.8382 19.4318 10.4871 19.1723 10.2275C18.9127 9.96791 18.5616 9.82067 18.1945 9.81748C17.8274 9.81429 17.4738 9.95541 17.2097 10.2104L12.5995 14.8206L10.7893 13.0104C10.5253 12.7554 10.1716 12.6143 9.80457 12.6175C9.43749 12.6207 9.08635 12.7679 8.82678 13.0275C8.56721 13.2871 8.41997 13.6382 8.41678 14.0053C8.41359 14.3723 8.55471 14.726 8.80973 14.99L11.6097 17.79C11.8723 18.0525 12.2283 18.1999 12.5995 18.1999C12.9708 18.1999 13.3268 18.0525 13.5893 17.79L19.1893 12.19Z"
        fill="#D9F3CE"
      />
      <path
        d="M19.1896 12.19C19.4446 11.9259 19.5857 11.5723 19.5825 11.2052C19.5793 10.8381 19.4321 10.487 19.1725 10.2274C18.913 9.96786 18.5618 9.82063 18.1947 9.81744C17.8277 9.81425 17.474 9.95536 17.21 10.2104L12.5998 14.8206L10.7896 13.0104C10.5255 12.7554 10.1719 12.6142 9.80483 12.6174C9.43775 12.6206 9.08661 12.7679 8.82704 13.0274C8.56747 13.287 8.42023 13.6381 8.41705 14.0052C8.41386 14.3723 8.55497 14.7259 8.80999 14.99L11.61 17.79C11.8725 18.0524 12.2286 18.1999 12.5998 18.1999C12.971 18.1999 13.3271 18.0524 13.5896 17.79L19.1896 12.19Z"
        fill="#D9F3CE"
      />
      <path
        d="M19.1896 12.19C19.4446 11.9259 19.5857 11.5723 19.5825 11.2052C19.5793 10.8381 19.4321 10.487 19.1725 10.2274C18.913 9.96786 18.5618 9.82063 18.1947 9.81744C17.8277 9.81425 17.474 9.95536 17.21 10.2104L12.5998 14.8206L10.7896 13.0104C10.5255 12.7554 10.1719 12.6142 9.80483 12.6174C9.43775 12.6206 9.08661 12.7679 8.82704 13.0274C8.56747 13.287 8.42023 13.6381 8.41705 14.0052C8.41386 14.3723 8.55497 14.7259 8.80999 14.99L11.61 17.79C11.8725 18.0524 12.2286 18.1999 12.5998 18.1999C12.971 18.1999 13.3271 18.0524 13.5896 17.79L19.1896 12.19Z"
        fill="#163608"
      />
    </svg>
  );
};

export default ExperienceSuccessIcon;
