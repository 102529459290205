import React from "react";
import { Grid, Typography } from "@mui/material";
import ExperienceSuccessIcon from "../../assets/icons/ExperienceSuccessIcon";

const ExperienceBenefitItem = ({ item }) => {
    return (
        <Grid
            container
            sx={{ display: "flex", alignItems: "center", margin: "15px 0px" }}
        >
            <Grid
                item
                xs={1.5}
                sm={"auto"}
                sx={{ maxWidth: { sm: "50px" }, minWidth: { sm: "40px" } }}
            >
                <ExperienceSuccessIcon />
            </Grid>
            <Grid item xs={10}>
                <Typography
                    sx={{
                        fontWeight: "bold",
                        display: "inline",
                        fontFamily: "inherit",
                        fontSize: "15px",
                        color: "#3D3D3D",
                    }}
                >
                    {item?.title}
                </Typography>
                <Typography
                    sx={{
                        display: "inline",
                        marginLeft: "4px",
                        fontFamily: "inherit",
                        fontSize: "15px",
                        color: "#3D3D3D",
                        opacity: 0.7,
                    }}
                >
                    {item?.description}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default ExperienceBenefitItem;