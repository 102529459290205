import React from "react";

const LikeIcon = ({ filled, color, width, height, noOpacity }) => {
  return (
    <svg
      width={width ? width : "26"}
      height={height ? height : "24"}
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.1832 18.9976C11.9882 18.8764 4.4043 14.132 4.4043 8.79896C4.4043 6.43159 6.32343 4.51245 8.6908 4.51245C10.126 4.51245 11.3965 5.21779 12.1745 6.30074C12.9524 5.21779 14.2229 4.51245 15.6581 4.51245C18.0255 4.51245 19.9446 6.43159 19.9446 8.79896C19.9446 14.1285 12.3932 18.8672 12.1875 18.9955C12.1872 18.9985 12.187 19 12.187 19C12.187 19 12.1854 18.9991 12.1832 18.9976Z"
        stroke={filled ? "#B81A0B" : color ? color : "#3D3D3D"}
        strokeOpacity={noOpacity ? "1" : "0.7"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={filled ? "#B81A0B" : ""}
      />
      <path
        d="M7.05869 9.50103C7.05869 8.21363 8.10233 7.16998 9.38973 7.16998"
        stroke={filled ? "#FFFFFF" : color ? color : "#FFFFFF"}
        strokeOpacity={noOpacity ? "1" : "0.7"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LikeIcon;
