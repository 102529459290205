import React, {useEffect, useState} from "react";
import {
    Box,
    Button, CircularProgress,
    Divider,
    Grid,
    MenuItem,
    Select, TextField,
    Typography,
} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";

//Redux/ Api
import {useDispatch, useSelector} from "react-redux";
import {
    fetchAllCities,
    fetchAllCountries,
    fetchOneExperienceWithSingleFeature, purchaseGuestExperience,
    setSelectedFeature,
} from "../../redux/slices/experiencesSlice";

//Icons
import {ChevronLeft} from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// Fancy Box
import ImageViewer from "react-simple-image-viewer";

//Stripe
import {
    useStripe,
    useElements,
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
} from "@stripe/react-stripe-js";
import moment from "moment";
import ExperiencesHomeItem from "../../components/experiences/ExperiencesHomeItem";
//Wallet
import {ethers} from "ethers";
import GuestOrderSuccessPage from "../experiences/GuestOrderSuccessPage";
import MainLayout from "../../layouts/MainLayout";

const GuestCheckoutPage = () => {
    const {id, feature_id, tenancy} = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {
        experienceDetails,
        isLoading,
        countries,
        cities,
        selectedFeature,
        paymentError,
    } = useSelector((state) => state.experiences);
    const {access_token} = useSelector((state) => state.auth);
    const [featureDiscount, setFeatureDiscount] = useState("");
    const [showImage, setShowImage] = useState(0);
    const [buyExperienceModal, setBuyExperienceModal] = useState(false);
    const [successNFTPayment, setSuccessNFTPayment] = useState(false);
    const [nftPaymentMessage, setNftPaymentMessage] = useState("");
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [walletAddress, setWalletAddress] = useState("");
    const [cardNumberError, setCardNumberError] = useState(false);
    const [expiryElementError, setExpiryElementError] = useState(false);
    const [cvcElementError, setCvcElementError] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);
    const [paymentMethodSelected, setPaymentMethodSelected] = useState("");
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState(null);
    const [numberOfPersons, setNumberOfPersons] = useState(1);
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [apartment, setApartment] = useState('');
    const [city, setCity] = useState(0);
    const [state, setState] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [country, setCountry] = useState(0);

    const images = [];
    experienceDetails?.media?.map((img, index) => {
        images.push(img);
    });

    const [imageToShow, setImageToShow] = useState({img: images[0], index: 0});


    useEffect(() => {
        dispatch(fetchAllCountries());
    }, []);

    useEffect(() => {
        dispatch(fetchAllCities(country));
    }, [country]);

    useEffect(() => {
        if (paymentError != "") {
            setLoadingButton(false);
        }
    }, [paymentError]);


    const handleSubmit = async (event) => {
        if (loadingButton) {
            return;
        }
        setLoadingButton(true);
        const cardNumberElement = elements.getElement(CardNumberElement);
        const {token, error} = await stripe.createToken(cardNumberElement);
        if (error) {
            setError(error.message);
            setLoadingButton(false);
        } else {
            handlePaymentProcess(token);
        }
    };

    const nftContractAddress = "0x19391CA492Fc5994f13E0031Ed5B5D9134a35d14";

    const nftContractTokenId = 944;

    async function checkNFTOwnership(walletAddress) {
        try {
            const provider = new ethers.providers.Web3Provider(window.ethereum);

            // Create a contract instance with the ERC-721 ABI
            const nftContract = new ethers.Contract(
                nftContractAddress,
                ["function ownerOf(uint256 tokenId) view returns (address)"],
                provider
            );
            console.log("walletAddress", walletAddress);
            // Call the ownerOf function to get the owner of the specified token ID
            const owner = await nftContract.ownerOf(nftContractTokenId);
            console.log("owner", owner);
            if (owner?.toLowerCase() === walletAddress?.toLowerCase()) {
                console.log(
                    `The user owns the NFT with token ID ${nftContractTokenId}`
                );
                setNftPaymentMessage(
                    `You are the owner of one of "Society of The Wine Grower" NFT Collection. Hidden Gems and Exclusive Experiences will be Soon Enabled for You!`
                );
                setBuyExperienceModal(false);
                setSuccessNFTPayment(true);
            } else {
                console.log(
                    `The user does not own the NFT with token ID ${nftContractTokenId}`
                );
                setNftPaymentMessage(
                    "You don't own one of our NFT collection , please try again."
                );
                setBuyExperienceModal(false);
                setSuccessNFTPayment(true);
            }
        } catch (error) {
            console.error("Error checking NFT ownership:", error);
        }
    }

    const handlePaymentProcess = (token, wallet_address) => {
        let obj = {
            zip_code: zipCode,
            state: state,
            country: country,
            city: city,
            apartment: apartment,
            address: address,
            email: email,
            nbOfSeats: numberOfPersons,
            slug: experienceDetails?.slug,
            amount:
                numberOfPersons *
                (selectedFeature?.discountPrice
                    ? selectedFeature?.discountPrice
                    : selectedFeature?.price),
            date: selectedFeature?.date
                ? moment(selectedFeature?.date).format("YYYY-MM-DD")
                : null,
            featureId: feature_id,
            tenancy: tenancy,
            token: token?.id,
            // payment_method: paymentMethodSelected?.method,
            payment_method: 'stripe',
            wallet_address: wallet_address,
        };

        dispatch(
            purchaseGuestExperience(
                obj,
                navigate,
                paymentMethodSelected,
                setLoadingButton
            )
        );
        // setLoadingButton(false);
    };

    useEffect(() => {
        if (elements) {
            const cardNumberElement = elements.getElement(CardNumberElement);
            const cardExpiryElement = elements.getElement(CardExpiryElement);
            const cardCvcElement = elements.getElement(CardCvcElement);

            if (cardNumberElement?._implementation?._invalid) {
                setCardNumberError(true);
            } else {
                setCardNumberError(false);
            }
            if (cardExpiryElement?._implementation?._invalid) {
                setExpiryElementError(true);
            } else {
                setExpiryElementError(false);
            }
            if (cardCvcElement?._implementation?._invalid) {
                setCvcElementError(true);
            } else {
                setCvcElementError(false);
            }
        }
    }, [error]);

    useEffect(() => {
        if (
            (!!selectedFeature && experienceDetails?.features?.length > 0) ||
            selectedFeature === undefined
        ) {
            dispatch(setSelectedFeature(experienceDetails?.features[0]));
            setFeatureDiscount(`${experienceDetails?.features[0]?.discount}`);
        }
    }, [experienceDetails]);


    useEffect(() => {
        dispatch(fetchOneExperienceWithSingleFeature(id, tenancy, feature_id));
    }, [id, feature_id, tenancy]);

    const openImage = (index) => {
        if (!images[index]) {
            return;
        }
        setCurrentImageIndex(index);
        setShowImage(1);
    };

    useEffect(() => {
        setImageToShow({img: images[0], index: 0});
    }, [experienceDetails]);

    if (showImage) {
        return (
            <>
                <ImageViewer
                    src={images}
                    currentIndex={imageToShow?.index}
                    disableScroll={true}
                    closeOnClickOutside={true}
                    onClose={() => {
                        setShowImage(0);
                    }}
                />
            </>
        );
    }

    const handleChangeNumberOfSeats = (event) => {
        setNumberOfPersons(event.target.value)
    };

    return (
        <>
            {window.innerWidth < 768 ? (
                <Grid
                    container
                    sx={{
                        padding: "0px 20px",
                        alignItems: "flex-start",
                    }}
                >
                    <Grid
                        item
                        sm={12}
                        onClick={() => navigate("/experiences")}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "row",
                            cursor: "pointer",
                            color: "#3D3D3D",
                            marginTop: "20px",
                        }}
                    >
                        <ChevronLeft
                            sx={{fontSize: "28px", marginLeft: "-10px", color: "#3D3D3D"}}
                        />
                        <Typography
                            sx={{
                                fontSize: "20px",
                                color: "#3D3D3D",
                                fontFamily: "Fraunces",
                                fontWeight: "600",
                            }}
                        >
                            Experiences
                        </Typography>
                    </Grid>
                    <Grid item sm={7} sx={{marginTop: "20px", padding: "0px"}}>
                        <Typography
                            sx={{
                                fontSize: "23px",
                                color: "#3D3D3D",
                                fontFamily: "Fraunces",
                                fontWeight: "600",
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                                WebkitLineClamp: 2,
                                textOverflow: "ellipsis",
                                maxHeight: "69px",
                            }}
                        >
                            {experienceDetails?.title}
                        </Typography>
                        {experienceDetails?.features?.length > 0 &&
                            experienceDetails?.features?.map((feature, index) => (
                                <Grid
                                    container
                                    key={index}
                                    sx={{
                                        display: "flex",
                                        alignItems: "flex-start",
                                        marginTop: "5px",
                                        marginBottom: "8px",
                                        cursor: "pointer",
                                        borderRadius: "5px",
                                    }}
                                >
                                    <Grid item xs={11}>
                                        {feature?.start_to_end && (
                                            <Typography
                                                sx={{
                                                    fontFamily: "inherit",
                                                    fontSize: "14px",
                                                    color: "rgba(61, 61, 61, 0.90)",
                                                }}
                                            >
                                                {feature?.start_to_end}
                                            </Typography>
                                        )}
                                    </Grid>
                                </Grid>
                            ))}
                        <Grid container
                              sx={{
                                  display: "flex",
                                  alignItems: "flex-start",
                                  marginTop: "5px",
                                  marginBottom: "8px",
                                  cursor: "pointer",
                                  borderRadius: "5px",
                              }}>
                            <Divider
                                sx={{
                                    backgroundColor: "#E6E7E8",
                                    width: "100%",
                                    margin: "10px 0px",
                                }}
                            />
                            <Typography
                                sx={{
                                    fontFamily: "inherit",
                                    fontSize: "18px",
                                    color: "rgba(61, 61, 61, 0.80)",
                                    marginBottom: "15px"
                                }}
                            >
                                Guests:
                            </Typography>
                            <Grid item xs={12}>
                                <Select
                                    value={numberOfPersons}
                                    onChange={(e) => handleChangeNumberOfSeats(e)}
                                    IconComponent={ExpandMoreIcon}
                                    sx={{
                                        width: "100%",
                                        height: "40px",
                                        fontFamily: "inherit",
                                        "& .MuiOutlinedInput-input": {
                                            padding: "3px 0px 3px 15px",
                                        },
                                    }}
                                    MenuProps={{
                                        style: {
                                            maxHeight: "300px",
                                        },
                                    }}
                                >
                                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((x) => {
                                        return (
                                            <MenuItem
                                                value={x}
                                                key={x}
                                                sx={{
                                                    color: "#3D3D3D",
                                                    fontFamily: "inherit",
                                                }}
                                            >
                                                {x}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </Grid>
                            <Divider
                                sx={{
                                    backgroundColor: "#E6E7E8",
                                    width: "100%",
                                    margin: "10px 0px",
                                }}
                            />
                            <Grid item xs={12}>
                                <Typography
                                    sx={{
                                        fontFamily: "inherit",
                                        fontSize: "18px",
                                        color: "rgba(61, 61, 61, 0.80)",
                                        marginBottom: "15px",
                                        fontWeight: "bold"
                                    }}
                                >
                                    Required for your experience
                                </Typography>
                                <Typography
                                    sx={{
                                        fontFamily: "inherit",
                                        fontSize: "18px",
                                        color: "rgba(61, 61, 61, 0.80)",
                                        marginBottom: "15px"
                                    }}
                                >
                                    Email
                                </Typography>
                                <TextField id="outlined-basic"
                                           value={email}
                                           onChange={(e) => setEmail(e.target.value)}
                                           label="Email Address" variant="outlined"
                                           sx={{
                                               width: "100%",
                                               fontFamily: "inherit",
                                           }}
                                />
                                <Divider
                                    sx={{
                                        backgroundColor: "#E6E7E8",
                                        width: "100%",
                                        margin: "10px 0px",
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12}>
                                        <Typography
                                            sx={{
                                                fontFamily: "inherit",
                                                fontSize: "18px",
                                                color: "rgba(61, 61, 61, 0.80)",
                                                marginBottom: "15px"
                                            }}
                                        >
                                            Card Details
                                        </Typography>
                                        <Box
                                            sx={{
                                                border: cardNumberError
                                                    ? "1px solid #9e2146"
                                                    : "1px solid #ced4da",
                                                borderRadius: "4px",
                                                padding: "10px",
                                            }}
                                        >

                                            <CardNumberElement
                                                options={{
                                                    showIcon: true,
                                                    placeholder: "Card Number",
                                                    style: {
                                                        base: {
                                                            fontSize: "14px",
                                                            color: "rgba(61, 61, 61, 0.70)",
                                                            "::placeholder": {
                                                                color: "rgba(61, 61, 61, 0.20)",
                                                            },
                                                        },
                                                        invalid: {
                                                            color: "#9e2146",
                                                        },
                                                    },
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box
                                            sx={{
                                                border: expiryElementError
                                                    ? "1px solid #9e2146"
                                                    : "1px solid #ced4da",
                                                borderRadius: "4px",
                                                padding: "10px",
                                            }}
                                        >
                                            <CardExpiryElement
                                                options={{
                                                    placeholder: "Expiry Date",
                                                    style: {
                                                        base: {
                                                            fontSize: "14px",
                                                            color: "rgba(61, 61, 61, 0.70)",
                                                            "::placeholder": {
                                                                color: "rgba(61, 61, 61, 0.20)",
                                                            },
                                                        },
                                                        invalid: {
                                                            color: "#9e2146",
                                                        },
                                                    },
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box
                                            sx={{
                                                border: cvcElementError
                                                    ? "1px solid #9e2146"
                                                    : "1px solid #ced4da",
                                                borderRadius: "4px",
                                                padding: "10px",
                                            }}
                                        >
                                            <CardCvcElement
                                                options={{
                                                    placeholder: "Enter CVC",

                                                    style: {
                                                        base: {
                                                            fontSize: "14px",
                                                            color: "rgba(61, 61, 61, 0.70)",

                                                            "::placeholder": {
                                                                color: "rgba(61, 61, 61, 0.20)",
                                                            },
                                                        },
                                                        invalid: {
                                                            color: "#9e2146",
                                                        },
                                                    },
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                    {error && (
                                        <Grid item xs={12}>
                                            <Typography
                                                sx={{
                                                    fontFamily: "inherit",
                                                    fontSize: "12px",
                                                    color: "#FF001F",
                                                }}
                                            >
                                                {error}
                                            </Typography>
                                        </Grid>
                                    )}
                                    {paymentError && (
                                        <Grid item xs={12}>
                                            <Typography
                                                sx={{
                                                    fontFamily: "inherit",
                                                    fontSize: "12px",
                                                    color: "#FF001F",
                                                }}
                                            >
                                                {paymentError}
                                            </Typography>
                                        </Grid>
                                    )}
                                </Grid>
                                <Divider
                                    sx={{
                                        backgroundColor: "#E6E7E8",
                                        width: "100%",
                                        margin: "10px 0px",
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Typography
                                    sx={{
                                        fontFamily: "inherit",
                                        fontSize: "28px",
                                        color: "rgba(61, 61, 61, 0.80)",
                                        marginBottom: "15px",
                                        fontWeight: "bold"
                                    }}
                                >
                                    Billing Address
                                </Typography>
                                <Grid item xs={12} sx={{
                                    marginBottom: '15px'
                                }}>
                                    <TextField id="outlined-basic" label="Address" variant="outlined"
                                               value={address}
                                               onChange={(e) => setAddress(e.target.value)}
                                               sx={{
                                                   width: "100%",
                                                   fontFamily: "inherit",
                                               }}
                                    />
                                </Grid>
                                <Grid item xs={12} sx={{
                                    marginBottom: '15px'
                                }}>
                                    <TextField id="outlined-basic" label="Apartment Number" variant="outlined"
                                               value={apartment}
                                               onChange={(e) => setApartment(e.target.value)}
                                               sx={{
                                                   width: "100%",
                                                   fontFamily: "inherit",
                                               }}
                                    />
                                </Grid>
                                <Grid item xs={12} sx={{
                                    marginBottom: '15px'
                                }}>
                                    <Typography
                                        sx={{
                                            fontFamily: "inherit",
                                            fontSize: "18px",
                                            color: "rgba(61, 61, 61, 0.80)",
                                            marginBottom: "15px"
                                        }}
                                    >
                                        Country
                                    </Typography>
                                    <Box>
                                        <Select
                                            value={country}
                                            onChange={(e) => setCountry(e.target.value)}
                                            IconComponent={ExpandMoreIcon}
                                            sx={{
                                                width: "100%",
                                                height: "40px",
                                                fontFamily: "inherit",
                                                "& .MuiOutlinedInput-input": {
                                                    padding: "3px 0px 3px 15px",
                                                },
                                            }}
                                            MenuProps={{
                                                style: {
                                                    maxHeight: "300px",
                                                },
                                            }}
                                        >
                                            {countries?.map((country) => {
                                                return (
                                                    <MenuItem
                                                        value={country.id}
                                                        key={country.id}
                                                        sx={{
                                                            color: "#3D3D3D",
                                                            fontFamily: "inherit",
                                                        }}
                                                    >
                                                        {country.name}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sx={{
                                    marginBottom: '15px'
                                }}>
                                    <Typography
                                        sx={{
                                            fontFamily: "inherit",
                                            fontSize: "18px",
                                            color: "rgba(61, 61, 61, 0.80)",
                                            marginBottom: "15px"
                                        }}
                                    >
                                        State
                                    </Typography>
                                    <Box>
                                        <Select
                                            value={city}
                                            onChange={(e) => setCity(e.target.value)}
                                            IconComponent={ExpandMoreIcon}
                                            sx={{
                                                width: "100%",
                                                height: "40px",
                                                fontFamily: "inherit",
                                                "& .MuiOutlinedInput-input": {
                                                    padding: "3px 0px 3px 15px",
                                                },
                                            }}
                                            MenuProps={{
                                                style: {
                                                    maxHeight: "300px",
                                                },
                                            }}
                                        >
                                            {cities?.map((city) => {
                                                return (
                                                    <MenuItem
                                                        value={city.id}
                                                        key={city.id}
                                                        sx={{
                                                            color: "#3D3D3D",
                                                            fontFamily: "inherit",
                                                        }}
                                                    >
                                                        {city.name}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sx={{
                                    marginBottom: '15px'
                                }}>
                                    {country == 2 ? (
                                        <Grid>
                                            <TextField id="outlined-basic" label="City" variant="outlined"
                                                       value={state}
                                                       onChange={(e) => setState(e.target.value)}
                                                       sx={{
                                                           width: "50%",
                                                           fontFamily: "inherit",
                                                       }}
                                            />
                                            <TextField id="outlined-basic" label="Zip code" variant="outlined"
                                                       value={zipCode}
                                                       onChange={(e) => setZipCode(e.target.value)}
                                                       sx={{
                                                           width: "50%",
                                                           fontFamily: "inherit",
                                                       }}
                                            />
                                        </Grid>
                                    ) : (
                                        <TextField id="outlined-basic" label="Zip code" variant="outlined"
                                                   value={zipCode}
                                                   onChange={(e) => setZipCode(e.target.value)}
                                                   sx={{
                                                       width: "100%",
                                                       fontFamily: "inherit",
                                                   }}
                                        />
                                    )
                                    }


                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        onClick={() => handleSubmit()}
                                        sx={{
                                            backgroundColor: "#0077D9",
                                            borderRadius: "6px",
                                            textTransform: "none",
                                            marginTop: "10px",
                                            height: "42px",
                                            width: "100%",
                                            fontSize: "16px",
                                            fontWeight: "500",
                                            fontFamily: "inherit",
                                            "&:hover": {
                                                backgroundColor: "#0077D9",
                                            },
                                        }}
                                    >
                                        {loadingButton ? (
                                            <>
                                                <CircularProgress
                                                    size={20}
                                                    style={{
                                                        color: "#fff",
                                                    }}
                                                />
                                            </>
                                        ) : (
                                            'Buy Now'
                                        )}

                                    </Button>


                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>

                    <Grid item sm={5} sx={{padding: "0px 50px 0px 10px"}}>
                        <Grid container sx={{marginTop: "15px"}}>
                            <Grid
                                item
                                sm={4}
                                onClick={() => {
                                    openImage(0);
                                }}
                                sx={{
                                    minHeight: "150px",
                                    height: "auto",
                                    minWidth: "150px",
                                    width: "auto",
                                    borderRadius: "5px",
                                    position: "relative",
                                    cursor: "pointer",
                                    marginTop: "20px",
                                    overflow: "hidden",
                                }}
                            >
                                <Grid
                                    sx={{
                                        width: "100%",
                                        height: "100%",
                                        backgroundImage: `url(${imageToShow?.img})`,
                                        backgroundSize: "cover",
                                        backgroundRepeat: "no-repeat",
                                        backgroundPosition: "center",
                                        transition:
                                            window.innerWidth > 768 && "transform 0.3s ease-in-out",
                                        "&:hover": {
                                            transform: window.innerWidth > 768 && "scale(1.1)",
                                        },
                                    }}
                                >
                                </Grid>
                            </Grid>
                            <Grid item xs={8} sx={{
                                paddingTop: "20px",
                                paddingLeft: "20px"
                            }}>
                                {experienceDetails?.features?.length > 0 &&
                                    experienceDetails?.features?.map((feature, index) => (
                                        <Grid
                                            container
                                            key={index}
                                            sx={{
                                                display: "flex",
                                                alignItems: "flex-start",
                                                marginTop: "5px",
                                                marginBottom: "8px",
                                                cursor: "pointer",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            <Grid item xs={11}>
                                                <Typography
                                                    sx={{
                                                        fontFamily: "inherit",
                                                        fontSize: "17px",
                                                        fontWeight: "600",
                                                        color: "#3D3D3D",
                                                    }}
                                                >
                                                    {feature?.title}
                                                </Typography>
                                                {feature?.start_to_end && (
                                                    <Typography
                                                        sx={{
                                                            fontFamily: "inherit",
                                                            fontSize: "14px",
                                                            color: "rgba(61, 61, 61, 0.90)",
                                                        }}
                                                    >
                                                        {feature?.start_to_end}
                                                    </Typography>
                                                )}
                                                {feature?.discountPrice && (
                                                    <Typography
                                                        sx={{
                                                            fontFamily: "inherit",
                                                            fontSize: "17px",
                                                            color: "#FF001F",
                                                            marginRight: "5px",
                                                            textDecoration: "line-through",
                                                            fontWeight: "600",
                                                        }}
                                                    >
                                                        ${feature?.discountPrice && feature?.price * numberOfPersons}
                                                    </Typography>
                                                )}
                                                <Typography
                                                    sx={{
                                                        fontFamily: "inherit",
                                                        fontSize: "16px",
                                                        color: "#00020E",
                                                        fontWeight: "600",
                                                    }}
                                                >
                                                    $
                                                    {feature?.discountPrice
                                                        ? feature?.discountPrice
                                                        : feature?.price * numberOfPersons}
                                                </Typography>
                                            </Grid>
                                            <Divider
                                                sx={{
                                                    backgroundColor: "#E6E7E8",
                                                    width: "100%",
                                                    margin: "10px 0px",
                                                }}
                                            />
                                        </Grid>
                                    ))}
                            </Grid>


                        </Grid>
                    </Grid>
                    {experienceDetails?.experiences?.length > 0 && (
                        <Grid item xs={12} sx={{padding: "0px", marginTop: "20px"}}>
                            <Grid
                                container
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Grid item>
                                    <Typography
                                        sx={{
                                            color: "#3D3D3D",
                                            fontFamily: "inherit",
                                            fontSize: "23px",
                                            fontWeight: "600",
                                        }}
                                    >
                                        You may also like
                                    </Typography>
                                </Grid>
                                <Grid item onClick={() => navigate("/experiences")}>
                                    <Typography
                                        sx={{
                                            color: "rgba(61, 61, 61, 0.80)",
                                            fontSize: "16px",
                                            fontWeight: "500",
                                            fontFamily: "inherit",
                                            opacity: 0.8,
                                            cursor: "pointer",
                                        }}
                                    >
                                        See all
                                    </Typography>
                                </Grid>
                            </Grid>
                            {window.innerWidth ? (
                                <Grid container columnSpacing={3} sx={{marginTop: "15px"}}>
                                    {experienceDetails?.experiences?.map((experience, index) => {
                                        return (
                                            <Grid item xs={12} key={index}>
                                                <ExperiencesHomeItem experience={experience}/>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            ) : (
                                <Grid container columnSpacing={3} sx={{marginTop: "15px"}}>
                                    {experienceDetails?.experiences?.map((experience, index) => {
                                        return (
                                            <Grid item xs={4} key={index}>
                                                <ExperiencesHomeItem experience={experience}/>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            )}

                        </Grid>
                    )}
                </Grid>
            ) : (
                <Grid
                    container
                    sx={{
                        padding: "0px 60px",
                        alignItems: "flex-start",
                    }}
                >
                    <Grid
                        item
                        sm={12}
                        onClick={() => navigate("/experiences")}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "row",
                            cursor: "pointer",
                            color: "#3D3D3D",
                            marginTop: "20px",
                        }}
                    >
                        <ChevronLeft
                            sx={{fontSize: "28px", marginLeft: "-10px", color: "#3D3D3D"}}
                        />
                        <Typography
                            sx={{
                                fontSize: "20px",
                                color: "#3D3D3D",
                                fontFamily: "Fraunces",
                                fontWeight: "600",
                            }}
                        >
                            Experiences
                        </Typography>
                    </Grid>
                    <Grid item sm={7} sx={{marginTop: "20px", padding: "0px"}}>
                        <Typography
                            sx={{
                                fontSize: "23px",
                                color: "#3D3D3D",
                                fontFamily: "Fraunces",
                                fontWeight: "600",
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                                WebkitLineClamp: 2,
                                textOverflow: "ellipsis",
                                maxHeight: "69px",
                            }}
                        >
                            {experienceDetails?.title}
                        </Typography>
                        {experienceDetails?.features?.length > 0 &&
                            experienceDetails?.features?.map((feature, index) => (
                                <Grid
                                    container
                                    key={index}
                                    sx={{
                                        display: "flex",
                                        alignItems: "flex-start",
                                        marginTop: "5px",
                                        marginBottom: "8px",
                                        cursor: "pointer",
                                        borderRadius: "5px",
                                    }}
                                >
                                    <Grid item xs={11}>
                                        {feature?.start_to_end && (
                                            <Typography
                                                sx={{
                                                    fontFamily: "inherit",
                                                    fontSize: "14px",
                                                    color: "rgba(61, 61, 61, 0.90)",
                                                }}
                                            >
                                                {feature?.start_to_end}
                                            </Typography>
                                        )}
                                    </Grid>
                                </Grid>
                            ))}
                        <Grid container
                              sx={{
                                  display: "flex",
                                  alignItems: "flex-start",
                                  marginTop: "5px",
                                  marginBottom: "8px",
                                  cursor: "pointer",
                                  borderRadius: "5px",
                              }}>
                            <Divider
                                sx={{
                                    backgroundColor: "#E6E7E8",
                                    width: "100%",
                                    margin: "10px 0px",
                                }}
                            />
                            <Typography
                                sx={{
                                    fontFamily: "inherit",
                                    fontSize: "18px",
                                    color: "rgba(61, 61, 61, 0.80)",
                                    marginBottom: "15px"
                                }}
                            >
                                Guests:
                            </Typography>
                            <Grid item xs={12}>
                                <Select
                                    value={numberOfPersons}
                                    onChange={(e) => handleChangeNumberOfSeats(e)}
                                    IconComponent={ExpandMoreIcon}
                                    sx={{
                                        width: "70px",
                                        height: "40px",
                                        fontFamily: "inherit",
                                        "& .MuiOutlinedInput-input": {
                                            padding: "3px 0px 3px 15px",
                                        },
                                    }}
                                    MenuProps={{
                                        style: {
                                            maxHeight: "300px",
                                        },
                                    }}
                                >
                                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((x) => {
                                        return (
                                            <MenuItem
                                                value={x}
                                                key={x}
                                                sx={{
                                                    color: "#3D3D3D",
                                                    fontFamily: "inherit",
                                                }}
                                            >
                                                {x}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </Grid>
                            <Divider
                                sx={{
                                    backgroundColor: "#E6E7E8",
                                    width: "100%",
                                    margin: "10px 0px",
                                }}
                            />
                            <Grid item xs={12}>
                                <Typography
                                    sx={{
                                        fontFamily: "inherit",
                                        fontSize: "28px",
                                        color: "rgba(61, 61, 61, 0.80)",
                                        marginBottom: "15px",
                                        fontWeight: "bold"
                                    }}
                                >
                                    Required for your experience
                                </Typography>
                                <Typography
                                    sx={{
                                        fontFamily: "inherit",
                                        fontSize: "18px",
                                        color: "rgba(61, 61, 61, 0.80)",
                                        marginBottom: "15px"
                                    }}
                                >
                                    Email
                                </Typography>
                                <TextField id="outlined-basic"
                                           value={email}
                                           onChange={(e) => setEmail(e.target.value)}
                                           label="Email Address" variant="outlined"
                                           sx={{
                                               width: "50%",
                                               fontFamily: "inherit",
                                           }}
                                />
                                <Divider
                                    sx={{
                                        backgroundColor: "#E6E7E8",
                                        width: "100%",
                                        margin: "10px 0px",
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12}>
                                        <Typography
                                            sx={{
                                                fontFamily: "inherit",
                                                fontSize: "18px",
                                                color: "rgba(61, 61, 61, 0.80)",
                                                marginBottom: "15px"
                                            }}
                                        >
                                            Card Details
                                        </Typography>
                                        <Box
                                            sx={{
                                                border: cardNumberError
                                                    ? "1px solid #9e2146"
                                                    : "1px solid #ced4da",
                                                borderRadius: "4px",
                                                padding: "10px",
                                            }}
                                        >

                                            <CardNumberElement
                                                options={{
                                                    showIcon: true,
                                                    placeholder: "Card Number",
                                                    style: {
                                                        base: {
                                                            fontSize: "14px",
                                                            color: "rgba(61, 61, 61, 0.70)",
                                                            "::placeholder": {
                                                                color: "rgba(61, 61, 61, 0.20)",
                                                            },
                                                        },
                                                        invalid: {
                                                            color: "#9e2146",
                                                        },
                                                    },
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box
                                            sx={{
                                                border: expiryElementError
                                                    ? "1px solid #9e2146"
                                                    : "1px solid #ced4da",
                                                borderRadius: "4px",
                                                padding: "10px",
                                            }}
                                        >
                                            <CardExpiryElement
                                                options={{
                                                    placeholder: "Expiry Date",
                                                    style: {
                                                        base: {
                                                            fontSize: "14px",
                                                            color: "rgba(61, 61, 61, 0.70)",
                                                            "::placeholder": {
                                                                color: "rgba(61, 61, 61, 0.20)",
                                                            },
                                                        },
                                                        invalid: {
                                                            color: "#9e2146",
                                                        },
                                                    },
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box
                                            sx={{
                                                border: cvcElementError
                                                    ? "1px solid #9e2146"
                                                    : "1px solid #ced4da",
                                                borderRadius: "4px",
                                                padding: "10px",
                                            }}
                                        >
                                            <CardCvcElement
                                                options={{
                                                    placeholder: "Enter CVC",

                                                    style: {
                                                        base: {
                                                            fontSize: "14px",
                                                            color: "rgba(61, 61, 61, 0.70)",

                                                            "::placeholder": {
                                                                color: "rgba(61, 61, 61, 0.20)",
                                                            },
                                                        },
                                                        invalid: {
                                                            color: "#9e2146",
                                                        },
                                                    },
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                    {error && (
                                        <Grid item xs={12}>
                                            <Typography
                                                sx={{
                                                    fontFamily: "inherit",
                                                    fontSize: "12px",
                                                    color: "#FF001F",
                                                }}
                                            >
                                                {error}
                                            </Typography>
                                        </Grid>
                                    )}
                                    {paymentError && (
                                        <Grid item xs={12}>
                                            <Typography
                                                sx={{
                                                    fontFamily: "inherit",
                                                    fontSize: "12px",
                                                    color: "#FF001F",
                                                }}
                                            >
                                                {paymentError}
                                            </Typography>
                                        </Grid>
                                    )}
                                </Grid>
                                <Divider
                                    sx={{
                                        backgroundColor: "#E6E7E8",
                                        width: "100%",
                                        margin: "10px 0px",
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Typography
                                    sx={{
                                        fontFamily: "inherit",
                                        fontSize: "28px",
                                        color: "rgba(61, 61, 61, 0.80)",
                                        marginBottom: "15px",
                                        fontWeight: "bold"
                                    }}
                                >
                                    Billing Address
                                </Typography>
                                <Grid item xs={12} sx={{
                                    marginBottom: '15px'
                                }}>
                                    <TextField id="outlined-basic" label="Address" variant="outlined"
                                               value={address}
                                               onChange={(e) => setAddress(e.target.value)}
                                               sx={{
                                                   width: "100%",
                                                   fontFamily: "inherit",
                                               }}
                                    />
                                </Grid>
                                <Grid item xs={12} sx={{
                                    marginBottom: '15px'
                                }}>
                                    <TextField id="outlined-basic" label="Apartment Number" variant="outlined"
                                               value={apartment}
                                               onChange={(e) => setApartment(e.target.value)}
                                               sx={{
                                                   width: "100%",
                                                   fontFamily: "inherit",
                                               }}
                                    />
                                </Grid>
                                <Grid item xs={12} sx={{
                                    marginBottom: '15px'
                                }}>
                                    <Typography
                                        sx={{
                                            fontFamily: "inherit",
                                            fontSize: "18px",
                                            color: "rgba(61, 61, 61, 0.80)",
                                            marginBottom: "15px"
                                        }}
                                    >
                                        Country
                                    </Typography>
                                    <Box>
                                        <Select
                                            value={country}
                                            onChange={(e) => setCountry(e.target.value)}
                                            IconComponent={ExpandMoreIcon}
                                            sx={{
                                                width: "100%",
                                                height: "40px",
                                                fontFamily: "inherit",
                                                "& .MuiOutlinedInput-input": {
                                                    padding: "3px 0px 3px 15px",
                                                },
                                            }}
                                            MenuProps={{
                                                style: {
                                                    maxHeight: "300px",
                                                },
                                            }}
                                        >
                                            {countries?.map((country) => {
                                                return (
                                                    <MenuItem
                                                        value={country.id}
                                                        key={country.id}
                                                        sx={{
                                                            color: "#3D3D3D",
                                                            fontFamily: "inherit",
                                                        }}
                                                    >
                                                        {country.name}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sx={{
                                    marginBottom: '15px'
                                }}>
                                    <Typography
                                        sx={{
                                            fontFamily: "inherit",
                                            fontSize: "18px",
                                            color: "rgba(61, 61, 61, 0.80)",
                                            marginBottom: "15px"
                                        }}
                                    >
                                        State
                                    </Typography>
                                    <Box>
                                        <Select
                                            value={city}
                                            onChange={(e) => setCity(e.target.value)}
                                            IconComponent={ExpandMoreIcon}
                                            sx={{
                                                width: "100%",
                                                height: "40px",
                                                fontFamily: "inherit",
                                                "& .MuiOutlinedInput-input": {
                                                    padding: "3px 0px 3px 15px",
                                                },
                                            }}
                                            MenuProps={{
                                                style: {
                                                    maxHeight: "300px",
                                                },
                                            }}
                                        >
                                            {cities?.map((city) => {
                                                return (
                                                    <MenuItem
                                                        value={city.id}
                                                        key={city.id}
                                                        sx={{
                                                            color: "#3D3D3D",
                                                            fontFamily: "inherit",
                                                        }}
                                                    >
                                                        {city.name}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sx={{
                                    marginBottom: '15px'
                                }}>
                                    {country == 2 ? (
                                        <Grid>
                                            <TextField id="outlined-basic" label="City" variant="outlined"
                                                       value={state}
                                                       onChange={(e) => setState(e.target.value)}
                                                       sx={{
                                                           width: "50%",
                                                           fontFamily: "inherit",
                                                       }}
                                            />
                                            <TextField id="outlined-basic" label="Zip code" variant="outlined"
                                                       value={zipCode}
                                                       onChange={(e) => setZipCode(e.target.value)}
                                                       sx={{
                                                           width: "50%",
                                                           fontFamily: "inherit",
                                                       }}
                                            />
                                        </Grid>
                                    ) : (
                                        <TextField id="outlined-basic" label="Zip code" variant="outlined"
                                                   value={zipCode}
                                                   onChange={(e) => setZipCode(e.target.value)}
                                                   sx={{
                                                       width: "100%",
                                                       fontFamily: "inherit",
                                                   }}
                                        />
                                    )
                                    }


                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        onClick={() => handleSubmit()}
                                        sx={{
                                            backgroundColor: "#0077D9",
                                            borderRadius: "6px",
                                            textTransform: "none",
                                            marginTop: "10px",
                                            height: "42px",
                                            width: "100%",
                                            fontSize: "16px",
                                            fontWeight: "500",
                                            fontFamily: "inherit",
                                            "&:hover": {
                                                backgroundColor: "#0077D9",
                                            },
                                        }}
                                    >
                                        {loadingButton ? (
                                            <>
                                                <CircularProgress
                                                    size={20}
                                                    style={{
                                                        color: "#fff",
                                                    }}
                                                />
                                            </>
                                        ) : (
                                            'Buy Now'
                                        )}

                                    </Button>


                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>

                    <Grid item sm={5} sx={{padding: "0px 50px 0px 10px"}}>
                        <Grid container sx={{marginTop: "15px"}}>
                            <Grid
                                item
                                sm={4}
                                onClick={() => {
                                    openImage(0);
                                }}
                                sx={{
                                    minHeight: "150px",
                                    height: "auto",
                                    minWidth: "150px",
                                    width: "auto",
                                    borderRadius: "5px",
                                    position: "relative",
                                    cursor: "pointer",
                                    marginTop: "20px",
                                    overflow: "hidden",
                                }}
                            >
                                <Grid
                                    sx={{
                                        width: "100%",
                                        height: "100%",
                                        backgroundImage: `url(${imageToShow?.img})`,
                                        backgroundSize: "cover",
                                        backgroundRepeat: "no-repeat",
                                        backgroundPosition: "center",
                                        transition:
                                            window.innerWidth > 768 && "transform 0.3s ease-in-out",
                                        "&:hover": {
                                            transform: window.innerWidth > 768 && "scale(1.1)",
                                        },
                                    }}
                                >
                                </Grid>
                            </Grid>
                            <Grid item xs={8} sx={{
                                paddingTop: "20px",
                                paddingLeft: "20px"
                            }}>
                                {experienceDetails?.features?.length > 0 &&
                                    experienceDetails?.features?.map((feature, index) => (
                                        <Grid
                                            container
                                            key={index}
                                            sx={{
                                                display: "flex",
                                                alignItems: "flex-start",
                                                marginTop: "5px",
                                                marginBottom: "8px",
                                                cursor: "pointer",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            <Grid item xs={11}>
                                                <Typography
                                                    sx={{
                                                        fontFamily: "inherit",
                                                        fontSize: "17px",
                                                        fontWeight: "600",
                                                        color: "#3D3D3D",
                                                    }}
                                                >
                                                    {feature?.title}
                                                </Typography>
                                                {feature?.start_to_end && (
                                                    <Typography
                                                        sx={{
                                                            fontFamily: "inherit",
                                                            fontSize: "14px",
                                                            color: "rgba(61, 61, 61, 0.90)",
                                                        }}
                                                    >
                                                        {feature?.start_to_end}
                                                    </Typography>
                                                )}
                                                {feature?.discountPrice && (
                                                    <Typography
                                                        sx={{
                                                            fontFamily: "inherit",
                                                            fontSize: "17px",
                                                            color: "#FF001F",
                                                            marginRight: "5px",
                                                            textDecoration: "line-through",
                                                            fontWeight: "600",
                                                        }}
                                                    >
                                                        ${feature?.discountPrice && feature?.price * numberOfPersons}
                                                    </Typography>
                                                )}
                                                <Typography
                                                    sx={{
                                                        fontFamily: "inherit",
                                                        fontSize: "16px",
                                                        color: "#00020E",
                                                        fontWeight: "600",
                                                    }}
                                                >
                                                    $
                                                    {feature?.discountPrice
                                                        ? feature?.discountPrice
                                                        : feature?.price * numberOfPersons}
                                                </Typography>
                                            </Grid>
                                            <Divider
                                                sx={{
                                                    backgroundColor: "#E6E7E8",
                                                    width: "100%",
                                                    margin: "10px 0px",
                                                }}
                                            />
                                        </Grid>
                                    ))}
                            </Grid>


                        </Grid>
                    </Grid>
                    {experienceDetails?.experiences?.length > 0 && (
                        <Grid item xs={12} sx={{padding: "0px", marginTop: "20px"}}>
                            <Grid
                                container
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Grid item>
                                    <Typography
                                        sx={{
                                            color: "#3D3D3D",
                                            fontFamily: "inherit",
                                            fontSize: "23px",
                                            fontWeight: "600",
                                        }}
                                    >
                                        You may also like
                                    </Typography>
                                </Grid>
                                <Grid item onClick={() => navigate("/experiences")}>
                                    <Typography
                                        sx={{
                                            color: "rgba(61, 61, 61, 0.80)",
                                            fontSize: "16px",
                                            fontWeight: "500",
                                            fontFamily: "inherit",
                                            opacity: 0.8,
                                            cursor: "pointer",
                                        }}
                                    >
                                        See all
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container columnSpacing={3} sx={{marginTop: "15px"}}>
                                {experienceDetails?.experiences?.map((experience, index) => {
                                    return (
                                        <Grid item xs={4} key={index}>
                                            <ExperiencesHomeItem experience={experience}/>
                                        </Grid>
                                    );
                                })}
                            </Grid>

                        </Grid>
                    )}
                </Grid>
            )}

        </>
    );
};

export default GuestCheckoutPage;
