import React from "react";

const MenuIcon = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.6666 18.9583C22.0839 18.9585 22.4851 19.1193 22.7872 19.4073C23.0892 19.6953 23.2688 20.0884 23.2889 20.5052C23.309 20.9221 23.1679 21.3307 22.8949 21.6463C22.6219 21.962 22.238 22.1605 21.8226 22.2008L21.6666 22.2083H4.33325C3.91593 22.2081 3.51469 22.0474 3.21267 21.7594C2.91064 21.4714 2.73099 21.0783 2.71093 20.6614C2.69088 20.2446 2.83196 19.836 3.10494 19.5204C3.37792 19.2047 3.76188 19.0062 4.17725 18.9659L4.33325 18.9583H21.6666ZM21.6666 11.375C22.0976 11.375 22.5109 11.5462 22.8156 11.851C23.1204 12.1557 23.2916 12.569 23.2916 13C23.2916 13.431 23.1204 13.8443 22.8156 14.1491C22.5109 14.4538 22.0976 14.625 21.6666 14.625H4.33325C3.90228 14.625 3.48895 14.4538 3.1842 14.1491C2.87946 13.8443 2.70825 13.431 2.70825 13C2.70825 12.569 2.87946 12.1557 3.1842 11.851C3.48895 11.5462 3.90228 11.375 4.33325 11.375H21.6666ZM21.6666 3.79167C22.0976 3.79167 22.5109 3.96288 22.8156 4.26762C23.1204 4.57237 23.2916 4.9857 23.2916 5.41667C23.2916 5.84765 23.1204 6.26097 22.8156 6.56572C22.5109 6.87047 22.0976 7.04167 21.6666 7.04167H4.33325C3.90228 7.04167 3.48895 6.87047 3.1842 6.56572C2.87946 6.26097 2.70825 5.84765 2.70825 5.41667C2.70825 4.9857 2.87946 4.57237 3.1842 4.26762C3.48895 3.96288 3.90228 3.79167 4.33325 3.79167H21.6666Z"
        fill="black"
      />
      <defs>
        <rect width="26" height="26" fill="white" />
      </defs>
    </svg>
  );
};

export default MenuIcon;
