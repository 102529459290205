import React, {useEffect, useRef, useState} from "react";
import {Box, Grid, List, ListSubheader, Typography} from "@mui/material";
import {Navigate, useNavigate} from "react-router-dom";

//Redux / Api
import {useDispatch, useSelector} from "react-redux";

// Header filter component
import HomepageItem from "../components/homepage/HomepageItem";
import {
    fetchHomeContent,
} from "../redux/slices/homeSlice";
import {fetchFeedContent, fetchUpcomingPhases} from "../redux/slices/homeSlice";
import {fetchAllExperiences} from "../redux/slices/experiencesSlice";
import TimelineIcon from "../assets/icons/headerIcons/TimelineIcon";
import ExperiencesIcons from "../assets/icons/headerIcons/ExperiencesIcons";
import ExperiencesHomeItem from "../components/experiences/ExperiencesHomeItem";
import UpcomingPhases from "../components/homepage/UpcomingPhases";


function Home() {
    const dispatch = useDispatch();
    const listRef = useRef(null);
    const {AllFeed, upcomingPhases} = useSelector((state) => state.home);
    const {allExperiences} = useSelector((state) => state.experiences);

    useEffect(() => {
        dispatch(fetchFeedContent());
        dispatch(fetchUpcomingPhases());
        dispatch(fetchAllExperiences(1));
    }, []);

    return (
        <Grid container>
            <Grid
                item
                xs={12}
                md={8}
                sx={{
                    position: "relative",
                    padding: {xs: 0, sm: "0px 30px", lg: "0px 60px"},
                }}
            >
                <Grid
                    item
                    md={9}
                    sx={{
                        display: {xs: "none", sm: "flex"},
                        marginTop: "20px",
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: "30px",
                            color: "#3D3D3D",
                            fontWeight: "600",
                            fontFamily: "Fraunces",
                        }}
                    >
                        Home
                    </Typography>
                </Grid>
                <List
                    ref={listRef}
                    sx={{
                        mb: 0,
                        pb: 0,
                        pt: 0,
                        overflowX: {md: "scroll"},
                        // height: 'calc(100vh - 100px - 80px)',
                    }}
                >
                    {
                        AllFeed?.map((winery, index) => {
                            return (
                                winery['data']?.map((post, index) => {
                                    return (
                                        <Grid item key={index}>
                                            <HomepageItem
                                                item={post}
                                                tenancy={winery['tenant']}
                                                isDesktop={window.innerWidth < 768 ? false : true}
                                            />
                                        </Grid>
                                    );
                                })
                            );
                        })
                    }
                </List>
            </Grid>
            <Grid
                item
                md={4}
                sx={{
                    display: {xs: "none", md: "block"},
                    marginTop: "55px",
                    overflowX: {md: "scroll"},
                    // height: {
                    //     md: "calc(100vh - 55px - 80px)",
                    // },
                }}
            >
                <Grid
                    container
                    sx={{flexDirection: "row", display: "flex", alignItems: "center"}}
                >
                    <TimelineIcon
                        isMenu
                        isDesktopActive
                        color={"rgba(61, 61, 61, 0.80)"}
                        width={"23"}
                        height={"23"}
                    />
                    <Typography
                        sx={{
                            fontSize: "21px",
                            color: "rgba(61, 61, 61, 0.80)",
                            fontFamily: "Fraunces",
                            fontWeight: "400",
                            letterSpacing: 0,
                            marginLeft: "10px",
                        }}
                    >
                        Happening this month
                    </Typography>
                </Grid>
                <Grid sx={{padding: "10px 5px 0px 0px"}}>
                    {upcomingPhases?.map((phase, index) => {
                        return (
                            <Box key={index}>
                                <UpcomingPhases
                                    name={phase?.title}
                                    textColor={phase?.text_color}
                                    backgroundColor={phase?.color}
                                    slug={phase?.slug}
                                />
                            </Box>
                        );
                    })}
                </Grid>
                <Grid
                    container
                    sx={{
                        flexDirection: "row",
                        margin: "25px 0px 15px 0px",
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <ExperiencesIcons
                        isMenu
                        isDesktopActive
                        color={"rgba(61, 61, 61, 0.80)"}
                        width={"23"}
                        height={"23"}
                    />
                    <Typography
                        sx={{
                            fontSize: "21px",
                            color: "rgba(61, 61, 61, 0.80)",
                            fontFamily: "Fraunces",
                            fontWeight: "400",
                            letterSpacing: 0,
                            marginLeft: "10px",
                        }}
                    >
                        Experiences
                    </Typography>
                </Grid>
                <Grid sx={{padding: "0px 10px 0px 0px"}}>
                    {allExperiences?.map((experience, index) => {
                        return (
                            <Box key={index}>
                                <ExperiencesHomeItem
                                    experience={experience}
                                    isHomePage
                                    isDesktop
                                />
                            </Box>
                        );
                    })}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Home;
