import React from "react";
import DOMPurify from "dompurify";

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
} from "@mui/material";
//Icons
import ExpandAccordionIcon from "../../assets/icons/ExpandAccordionIcon";

const ExperienceAccordion = ({ title, description }) => {
    const sanitizedHtml = DOMPurify.sanitize(description);

    return (
        <Accordion
            sx={{
                "&.MuiPaper-root": {
                    boxShadow: "none",
                },
            }}
        >
            <AccordionSummary
                expandIcon={<ExpandAccordionIcon />}
                sx={{
                    borderBottom: "1px solid #3D3D3D66",
                    minHeight: "35px",
                    "&.Mui-expanded": {
                        minHeight: "35px",
                    },
                    "& .MuiAccordionSummary-content.Mui-expanded": {
                        margin: "5px 0px !important",
                    },
                }}
            >
                <Typography
                    sx={{
                        fontFamily: "inherit",
                        fontSize: { xs: "15px", lg: "17px" },
                        color: "#3D3D3D",
                        fontWeight: "600",
                    }}
                >
                    {title}
                </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ paddingTop: "10px", paddingBottom: 0 }}>
                <Typography
                    dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
                    sx={{
                        color: "rgba(61, 61, 61, 0.80)",
                        fontFamily: "inherit",
                        fontWeight: "500",
                        fontSize: "14px",
                        "& p": {
                            margin: "2px !important",
                        },
                    }}
                />
            </AccordionDetails>
        </Accordion>
    );
};

export default ExperienceAccordion;