import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const HomeIcon = ({ active, width, height, isDrawer, isMenu }) => {
  const location = useLocation();
  const [isIconActive, setIsIconActive] = useState(false);
  useEffect(() => {
    if (
      location?.pathname === "/" ||
      location?.pathname.includes("/post-details")
    ) {
      setIsIconActive(true);
    } else {
      setIsIconActive(false);
    }
  }, [location]);

  return isDrawer ? (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.72109 0.0866824C7.66294 0.03105 7.58556 0 7.50508 0C7.4246 0 7.34722 0.03105 7.28906 0.0866824L0.0976562 6.9566C0.0671152 6.98582 0.0428178 7.02093 0.0262338 7.0598C0.00964982 7.09868 0.00112391 7.14051 0.00117183 7.18278L0 13.6304C0 13.962 0.131696 14.2799 0.366116 14.5143C0.600537 14.7487 0.918479 14.8804 1.25 14.8804H5.00391C5.16967 14.8804 5.32864 14.8146 5.44585 14.6974C5.56306 14.5802 5.62891 14.4212 5.62891 14.2554V8.94293C5.62891 8.86005 5.66183 8.78057 5.72044 8.72196C5.77904 8.66336 5.85853 8.63043 5.94141 8.63043H9.06641C9.14929 8.63043 9.22877 8.66336 9.28738 8.72196C9.34598 8.78057 9.37891 8.86005 9.37891 8.94293V14.2554C9.37891 14.4212 9.44475 14.5802 9.56196 14.6974C9.67917 14.8146 9.83815 14.8804 10.0039 14.8804H13.7563C14.0878 14.8804 14.4057 14.7487 14.6401 14.5143C14.8746 14.2799 15.0063 13.962 15.0063 13.6304V7.18278C15.0063 7.14051 14.9978 7.09868 14.9812 7.0598C14.9646 7.02093 14.9403 6.98582 14.9098 6.9566L7.72109 0.0866824Z"
        fill="#3D3D3D"
      />
    </svg>
  ) : isMenu && isIconActive ? (
    <svg
      width="27"
      height="27"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.5797 15.7814L16.7078 2.91892C16.6151 2.82606 16.505 2.75238 16.3839 2.70212C16.2627 2.65185 16.1328 2.62598 16.0016 2.62598C15.8704 2.62598 15.7405 2.65185 15.6193 2.70212C15.4981 2.75238 15.388 2.82606 15.2953 2.91892L2.42344 15.7814C2.04844 16.1564 1.83594 16.6658 1.83594 17.197C1.83594 18.3002 2.73281 19.197 3.83594 19.197H5.19219V28.3752C5.19219 28.9283 5.63906 29.3752 6.19219 29.3752H14.0016V22.3752H17.5016V29.3752H25.8109C26.3641 29.3752 26.8109 28.9283 26.8109 28.3752V19.197H28.1672C28.6984 19.197 29.2078 18.9877 29.5828 18.6095C30.3609 17.8283 30.3609 16.5627 29.5797 15.7814Z"
        fill="#3D3D3D"
      />
    </svg>
  ) : isMenu ? (
    <svg
      width="27"
      height="27"
      viewBox="0 0 30 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.8109 26.3752H17.5016V20.3752V19.3752H16.5016H13.0016H12.0016V20.3752V26.3752H5.19219V17.197V16.197H4.19219H2.83594C2.2851 16.197 1.83594 15.7479 1.83594 15.197C1.83594 14.9328 1.94179 14.6774 2.13029 14.4888C2.13037 14.4887 2.13046 14.4886 2.13054 14.4885L15.0016 1.62688L27.8726 14.4885C27.8726 14.4886 27.8727 14.4887 27.8728 14.4887C28.2635 14.8797 28.2635 15.5131 27.8743 15.9039L27.8728 15.9054C27.6879 16.0918 27.4351 16.197 27.1672 16.197H25.8109H24.8109V17.197V26.3752Z"
        stroke="#3D3D3D"
        strokeOpacity="0.9"
        strokeWidth="2"
      />
    </svg>
  ) : (
    <svg
      width={width ? width : "12"}
      height={height ? height : "12"}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5003 16.5834V7.37508C17.5003 7.2069 17.4612 7.04103 17.386 6.8906C17.3107 6.74017 17.2015 6.60932 17.067 6.50841L9.48366 0.820915C9.29614 0.680274 9.06806 0.604248 8.83366 0.604248C8.59926 0.604248 8.37118 0.680274 8.18366 0.820915L0.600326 6.50841C0.46578 6.60932 0.356576 6.74017 0.281363 6.8906C0.206149 7.04103 0.166992 7.2069 0.166992 7.37508V16.5834C0.166992 16.8707 0.281129 17.1463 0.484293 17.3494C0.687458 17.5526 0.963008 17.6667 1.25033 17.6667H5.58366C5.87098 17.6667 6.14653 17.5526 6.34969 17.3494C6.55286 17.1463 6.66699 16.8707 6.66699 16.5834V13.3334C6.66699 13.0461 6.78113 12.7705 6.98429 12.5674C7.18746 12.3642 7.46301 12.2501 7.75033 12.2501H9.91699C10.2043 12.2501 10.4799 12.3642 10.683 12.5674C10.8862 12.7705 11.0003 13.0461 11.0003 13.3334V16.5834C11.0003 16.8707 11.1145 17.1463 11.3176 17.3494C11.5208 17.5526 11.7963 17.6667 12.0837 17.6667H16.417C16.7043 17.6667 16.9799 17.5526 17.183 17.3494C17.3862 17.1463 17.5003 16.8707 17.5003 16.5834Z"
        fill={active ? "#A09574" : "#3D3D3D"}
        fillOpacity="0.7"
      />
    </svg>
  );
};

export default HomeIcon;
