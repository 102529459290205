import React, { createRef, useEffect, useState } from "react";
import {
  Typography,
  Box,
  Button,
  Avatar,
  Badge,
  TextField,
  Grid,
  Modal,
} from "@mui/material";

import { Form, Formik } from "formik";
import * as Yup from "yup";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { saveProfile } from "../../config/api";
import { fetchProfile, startLoading } from "../../redux/slices/authSlice";
//Loading Spinner
import LoadingSpinner from "../../components/loadingSpinner/LoadingSpinner";
//Icons
import CameraIcon from "../../assets/icons/CameraIcon";
import EditProfileIcon from "../../assets/icons/EditProfileIcon";

const ProfileSettingsModal = ({
  profileSettingsModal,
  setProfileSettingsModal,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { profile, isLoading, access_token } = useSelector(
    (state) => state.auth
  );

  const [image, _setImage] = useState(null);
  const [imageFile, _setImageFile] = useState(null);
  const [loading, setLoading] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [enableEdit, _setEnableEdit] = useState(false);
  const inputFileRef = createRef(null);

  const cleanup = () => {
    URL.revokeObjectURL(image);
    inputFileRef.current.value = null;
  };

  const setImage = (newImage) => {
    if (image) {
      cleanup();
    }
    _setImage(newImage);
  };

  const handleOnChange = async (event) => {
    const newImage = event.target?.files?.[0];
    console.log("new image", URL.createObjectURL(newImage));

    if (newImage) {
      const img64 = await toBase64(newImage);
      _setImageFile(img64);
      setImage(URL.createObjectURL(newImage));
    }

    submitPicture();
  };

  /**
   *
   * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} event
   */
  const handleClick = (event) => {
    // if (image) {
    //   event.preventDefault();
    //   setImage(null);
    // }
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });

  const submitPicture = async () => {
    let values = {
      fname: profile.first_name,
      lname: profile.last_name,
    };

    await submitProfile(values);
  };

  const submitProfile = async (values) => {
    let obj = {
      first_name: values.fname,
      last_name: values.lname,
      password: values.password,
    };

    let formData = new FormData();
    formData.append("first_name", values.fname);
    formData.append("last_name", values.lname);
    if (values.password) {
      formData.append("password", values.password);
    }
    console.log("image::: ", image);
    if (image) {
      formData.append("profile_picture", imageFile);
    }

    setErrorMessage(null);
    setSuccessMessage(null);
    setLoading(true);
    await saveProfile(formData).then((res) => {
      if (res.status === 200) {
        console.warn("test: ", res?.data?.message);
        dispatch(fetchProfile());
        setSuccessMessage(res?.data?.message);

        setLoading(false);
      } else {
        setErrorMessage(res?.data?.message);
        setLoading(false);
        console.log("error: ", res?.data?.message);
      }
      setLoading(false);
      console.log("error: ", res?.data?.message);
    });
    setLoading(false);
  };

  useEffect(() => {
    if (access_token) {
      dispatch(fetchProfile());
    }
  }, []);

  // useEffect(() => {
  //   if (imageFile) {
  //     submitPicture();
  //   }
  // }, [imageFile]);

  const inputStyle = {
    "& .MuiOutlinedInput-root": {
      height: "40px",
      width: "100%",
      fontFamily: "inherit",
      borderRadius: "5px",
      border: "0.5px solid rgba(61, 61, 61, 0.30);",
    },
    ".MuiTextField-root": {
      width: "100%",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      padding: 0,
      borderColor: "white",
      borderWidth: "1px !important",
    },
    ".MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      padding: 0,
      border: "1px solid #3d3d3d !important",
    },
    ".MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "#d32f2f",
    },
    ".MuiFormHelperText-root.Mui-error": {
      fontFamily: "inherit",
    },
    ".MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
    },

    "& .MuiOutlinedInput-input": {
      color: "#3d3d3d",
      padding: "10px",
      width: "100%",
      fontFamily: "inherit",
      fontSize: { xs: "12px", sm: "14px" },
    },
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    fname: Yup.string().required("First Name is required"),
    lname: Yup.string(),
    password: Yup.string()
      .min(8, "At least 8 characters")
      .matches(/[a-z]/, "At least one lowercase character")
      .matches(/[A-Z]/, "At least one uppercase character")
      .matches(/[0-9]/, "At least one numeric character")
      .matches(/[!@#$%^&*-]/, "At least one special character (@,!,#, etc)."),

    confirm_password: Yup.string().when("password", {
      is: (password) => password && password.length > 0,
      then: () =>
        Yup.string()
          .oneOf([Yup.ref("password"), null], "Passwords must match")
          .required("Confirm Password is required"),
      otherwise: () => Yup.string(),
    }),
  });
  const handleCloseModal = () => {
    setProfileSettingsModal(false);
    setSuccessMessage(null);
    setErrorMessage(null);
  };
  return (
    <>
      <Modal
        open={profileSettingsModal}
        onClose={() => handleCloseModal()}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        disableAutoFocus={true}
      >
        <Box
          sx={{
            backgroundColor: "#FFFFFF",
            minHeight: "400px",
            padding: "20px 40px 20px 40px",
            borderRadius: "20px",
            width: "500px",
            maxHeight: { sm: "400px", lg: "590px" },
            overflowY: "scroll",
          }}
        >
          {isLoading ? (
            <LoadingSpinner pageHeight={"400px"} size={50} />
          ) : (
            <Grid container>
              <Grid item sm={12}>
                <Typography
                  sx={{
                    color: "#3D3D3D",
                    fontSize: "24px",
                    fontWeight: "600",
                    fontFamily: "inherit",
                  }}
                >
                  Set up your profile
                </Typography>
              </Grid>
              <Grid item sm={12}>
                <Typography
                  sx={{
                    color: "rgba(61, 61, 61, 0.70)",
                    fontSize: "20px",
                    fontWeight: "500",
                    fontFamily: "inherit",
                  }}
                >
                  Upload your picture!
                </Typography>
              </Grid>
              <Grid item sm={12}>
                <Formik
                  initialValues={{
                    fname: profile?.first_name,
                    lname: profile?.last_name,
                    email: profile?.email,
                    password: "",
                    confirm_password: "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={(values) => submitProfile(values)}
                >
                  {({
                    values,
                    handleChange,
                    handleBlur,
                    touched,
                    errors,
                    isSubmitting,
                  }) => (
                    <Form>
                      <Grid>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginTop: "30px",
                          }}
                        >
                          <Badge
                            overlap="circular"
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            badgeContent={<CameraIcon />}
                          >
                            <Avatar
                              sx={{
                                width: "130px",
                                height: "130px",
                              }}
                              src={image || profile?.profile_picture}
                            ></Avatar>
                            <Box
                              sx={{
                                position: "absolute",
                                width: "100%",
                                height: "100%",
                                left: 0,
                                top: 0,
                                opacity: 1,
                                zIndex: 9,
                              }}
                            >
                              <label
                                htmlFor="avatar-image-upload"
                                style={{
                                  position: "absolute",
                                  width: "100%",
                                  height: "100%",
                                }}
                                onClick={(e) => {
                                  handleClick(e);
                                }}
                              >
                                {/* <Button ></Button> */}
                                <input
                                  ref={inputFileRef}
                                  accept="image/*"
                                  hidden
                                  id="avatar-image-upload"
                                  type="file"
                                  onChange={handleOnChange}
                                />
                              </label>
                            </Box>
                          </Badge>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: 600,
                              fontSize: "18px",
                              lineHeight: "22px",
                              marginTop: "10px",
                              fontFamily: "inherit",
                              textAlign: "center",
                            }}
                          >
                            {profile?.first_name} {profile?.last_name}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            flexDirection: "row",
                            marginTop: "20px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: "600",
                              fontSize: "17px",
                              lineHeight: "22px",
                              fontFamily: "inherit",
                            }}
                          >
                            User information
                          </Typography>
                          <Button
                            onClick={() => {
                              _setEnableEdit(!enableEdit);
                            }}
                          >
                            <EditProfileIcon
                              fill={enableEdit ? "#ffffff" : "#3D3D3D"}
                              background={enableEdit ? "#a09574" : "none"}
                            />
                          </Button>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            gap: "5px",
                            alignItems: "center",
                            flexDirection: "row",
                            marginTop: "10px",
                          }}
                        >
                          <TextField
                            name="fname"
                            placeholder="First Name"
                            sx={inputStyle}
                            fullWidth
                            value={values.fname}
                            disabled={!enableEdit}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.fname && Boolean(errors.fname)}
                          />
                          <TextField
                            name="lname"
                            placeholder="Last Name"
                            sx={inputStyle}
                            fullWidth
                            value={values.lname}
                            disabled={!enableEdit}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.lname && Boolean(errors.lname)}
                          />
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            gap: "5px",
                            alignItems: "center",
                            flexDirection: "row",
                            marginTop: "10px",
                          }}
                        >
                          <TextField
                            name="email"
                            placeholder="Email"
                            sx={inputStyle}
                            fullWidth
                            value={values.email}
                            disabled={true}
                          />
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            flexDirection: "row",
                            marginTop: "30px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: "600",
                              fontSize: "17px",
                              lineHeight: "22px",
                              fontFamily: "inherit",
                            }}
                          >
                            Password
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            gap: "5px",
                            alignItems: "center",
                            flexDirection: "row",
                            marginTop: "10px",
                          }}
                        >
                          {enableEdit ? (
                            <TextField
                              name="password"
                              type="password"
                              placeholder="Password"
                              sx={inputStyle}
                              fullWidth
                              value={values.password}
                              disabled={!enableEdit}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={
                                touched.password && Boolean(errors.password)
                              }
                              helperText={
                                touched.password ? errors.password : ""
                              }
                            />
                          ) : (
                            <TextField
                              name="password"
                              type="password"
                              placeholder="Password"
                              sx={inputStyle}
                              fullWidth
                              value={"************"}
                              disabled={true}
                            />
                          )}
                        </Box>
                        {enableEdit && (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              gap: "5px",
                              alignItems: "center",
                              flexDirection: "row",
                              marginTop: "10px",
                            }}
                          >
                            <TextField
                              name="confirm_password"
                              type="password"
                              placeholder="Confirm Password"
                              sx={inputStyle}
                              fullWidth
                              value={values.confirm_password}
                              disabled={!enableEdit}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={
                                touched.confirm_password &&
                                Boolean(errors.confirm_password)
                              }
                              helperText={
                                touched.confirm_password
                                  ? errors.confirm_password
                                  : ""
                              }
                            />
                          </Box>
                        )}

                        {errorMessage && (
                          <Grid
                            item
                            xs={12}
                            sx={{
                              textAlign: "left",
                              width: "100%",
                              color: "#d32f2f",
                              fontSize: "15px",
                              maxWidth: {
                                xs: "250px",
                                sm: "310px",
                                md: "400px",
                              },
                            }}
                          >
                            <Box>{errorMessage}</Box>
                          </Grid>
                        )}
                        {successMessage && (
                          <Grid
                            item
                            xs={12}
                            sx={{
                              textAlign: "left",
                              width: "100%",
                              color: "#a09574",
                              fontSize: "15px",
                              maxWidth: {
                                xs: "250px",
                                sm: "310px",
                                md: "400px",
                              },
                            }}
                          >
                            <Box>{successMessage}</Box>
                          </Grid>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          variant="contained"
                          type="submit"
                          sx={{
                            boxShadow: "none !important",
                            backgroundColor: "#EBEADF !important",
                            borderRadius: "5px",
                            textTransform: "none",
                            marginTop: "10px",
                            height: "40px",
                            width: "100%",
                            fontSize: "16px",
                            fontWeight: "600",
                            fontFamily: "inherit",
                            color: "#3D3D3D !important",
                            "&:hover": {
                              backgroundColor: "#EBEADF !important",
                            },
                            "&:disabled": {
                              opacity: 0.5,
                            },
                          }}
                          disabled={loading || !enableEdit}
                        >
                          Save
                        </Button>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default ProfileSettingsModal;
