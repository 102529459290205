import React from "react";
import {Box, Grid, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import ExperienceDiscountItem from "./ExperienceDiscountItem";

const ExperiencesHomeItem = ({experience, isHomePage, isDesktop, tenancy}) => {
    const navigate = useNavigate();
    return (
        <Grid
            container
            sx={{marginBottom: "15px", cursor: "pointer"}}
            onClick={() => navigate(`/experience-details/${experience?.slug}/${tenancy}`)}
            // onClick={() => window.open("http://" + tenancy + '.' + experience?.front_domain + `/experience-details/${experience?.slug}`, '_blank')}
        >
            <Grid
                item
                sx={{
                    maxWidth: isDesktop && "300px",
                    width: "100%",
                    height: "220px",
                    borderRadius: "5px",
                    position: "relative",
                    overflow: "hidden",
                }}
            >
                <Grid
                    sx={{
                        width: "100%",
                        height: "100%",
                        background: `linear-gradient(0deg, rgba(0, 0, 0, 0.70) 20.36%, rgba(0, 0, 0, 0.00) 45.81%), url(${
                            experience?.image ? "http://"+process.env.REACT_APP_MAIN_API_DOMAIN+"/uploads/" + tenancy + "/" + experience?.image : ""
                        }),  #ECECEC`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        transition: window.innerWidth > 768 && "transform 0.3s ease-in-out",
                        "&:hover": {
                            transform: window.innerWidth > 768 && "scale(1.1)",
                        },
                    }}
                ></Grid>
                {experience?.discount && (
                    <ExperienceDiscountItem discount={experience?.discount}/>
                )}
            </Grid>
            <Grid item xs={12}>
                <Grid
                    container
                    sx={{
                        display: "flex",
                        alignItems: isHomePage
                            ? "flex-start"
                            : window.innerWidth < 768
                                ? "flex-start"
                                : "center",
                        marginTop: isHomePage
                            ? "10px"
                            : window.innerWidth < 768
                                ? "5px"
                                : "15px",
                        flexDirection: isHomePage ? "column" : "row",
                    }}
                >
                    <Grid item xs={7} sm={12}>
                        <Typography
                            sx={{
                                fontFamily: "inherit",
                                fontSize:
                                    window.innerWidth < 768 ? "10px" : {md: "12px", lg: "15px"},
                                color: "#a5a5a5",
                                opacity: 1,
                                fontWeight: "600",
                                letterSpacing: 0,
                            }}
                        >
                            {experience?.category}
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: "inherit",
                                fontSize:
                                    window.innerWidth < 768 ? "15px" : {md: "18px", lg: "20px"},
                                color: "#3D3D3D",
                                opacity: 1,
                                fontWeight: "600",
                                letterSpacing: 0,
                            }}
                        >
                            {experience?.title}
                        </Typography>

                        <Typography
                            sx={{
                                fontFamily: "inherit",
                                fontSize:
                                    window.innerWidth < 768 ? "15px" : {md: "18px", lg: "20px"},
                                color: "#3D3D3D",
                                opacity: 1,
                                fontWeight: "600",
                                letterSpacing: 0,
                            }}
                        >
                            {experience?.feature_date}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={5}
                        sm={12}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "row",
                            justifyContent:
                                window?.innerWidth < 768 ? "flex-end" : "flex-start",
                        }}
                    >
                        {experience?.discountPrice && window.innerWidth < 768 && (
                            <Typography
                                sx={{
                                    fontFamily: "inherit",
                                    fontSize: "15px",
                                    color: "#FF001F",
                                    marginRight: "5px",
                                    textDecoration: "line-through",
                                    fontWeight: "600",
                                }}
                            >
                                ${experience?.discountPrice && experience?.price}
                            </Typography>
                        )}
                        {window.innerWidth < 768 ? (
                            <>
                                {" "}
                                <Typography
                                    sx={{
                                        fontFamily: "inherit",
                                        fontSize: "13px",
                                        color: "#3D3D3D",
                                        fontWeight: "600",
                                        opacity: 0.7,
                                    }}
                                >
                                    From
                                </Typography>
                                <Typography
                                    sx={{
                                        fontFamily: "inherit",
                                        fontSize: "15px",
                                        color: "#3D3D3D",
                                        fontWeight: "600",
                                        marginLeft: "5px",
                                    }}
                                >
                                    $
                                    {experience?.discountPrice
                                        ? experience?.discountPrice
                                        : experience?.price}
                                </Typography>
                            </>
                        ) : (
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "baseline",
                                    flexDirection: "row",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: "inherit",
                                        fontSize: {md: "13px", lg: "15px"},
                                        color: "#3D3D3D",
                                        fontWeight: "600",
                                        opacity: 0.6,
                                    }}
                                >
                                    From
                                </Typography>
                                <Typography
                                    sx={{
                                        fontFamily: "inherit",
                                        fontSize: {md: "14px", lg: "16px"},
                                        color: "#3D3D3D",
                                        fontWeight: "600",
                                        margin: "0px 5px",
                                        opacity: 0.9,
                                    }}
                                >
                                    $
                                </Typography>
                                <Typography
                                    sx={{
                                        fontFamily: "inherit",
                                        fontSize: {md: "17px", lg: "18px"},
                                        color: "#3D3D3D",
                                        fontWeight: "600",
                                        opacity: 0.9,
                                    }}
                                >
                                    {experience?.discountPrice
                                        ? experience?.discountPrice
                                        : experience?.price}
                                </Typography>
                            </Box>
                        )}
                        {experience?.discountPrice && window.innerWidth > 768 && (
                            <Typography
                                sx={{
                                    fontFamily: "inherit",
                                    fontSize:
                                        window.innerWidth < 768
                                            ? "13px"
                                            : {md: "17px", lg: "18px"},
                                    color: "#FF001F",
                                    marginLeft: "10px",
                                    textDecoration: "line-through",
                                    fontWeight: "600",
                                }}
                            >
                                ${experience?.discountPrice && experience?.price}
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ExperiencesHomeItem;
