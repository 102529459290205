import React from "react";
//CSS
import "./layout.css";

//MUI components
import { Box } from "@mui/material";

const Footer = () => {
  return (
    <Box className="footer-container">
      <Box className="footer-content">
        <Box>
          <img
            src={require("../../assets/images/wine-grower-logo.png")}
            alt=""
            height={"auto"}
            className="footer-img"
          />
        </Box>
        <Box className="footer-content-2">
          <div
            style={{
              color: "white",
            }}
          >
            Privacy Policy
          </div>
          <div className="footer-content-2-text">Terms & Conditions</div>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
