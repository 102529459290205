import React from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setSelectedPhase } from "../../redux/slices/timelineSlice";

const UpcomingPhases = ({ backgroundColor, name, slug }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handlePhaseNavigate = () => {
    dispatch(setSelectedPhase(slug));
    navigate("/timeline");
  };
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        width: "100%",
        margin: "10px 0px",
        maxWidth: "300px",
        cursor: "pointer",
      }}
      onClick={() => handlePhaseNavigate()}
    >
      <Box
        sx={{
          width: "15px",
          height: "50px",
          background: `${backgroundColor}`,
          opacity: 1,
          borderRadius: "10px",
          marginRight: "-16px",
        }}
      />
      <Box
        sx={{
          height: "50px",
          borderRadius: "10px",
          background: `${backgroundColor}4D`,
          flexDirection: "row",
          width: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            color: "#3D3D3D",
            opacity: 1,
            marginLeft: "28px",
            fontWeight: "500",
            fontFamily: "inherit",
          }}
        >
          {name}
        </Typography>
      </Box>
    </Box>
  );
};

export default UpcomingPhases;
