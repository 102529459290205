import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
//Redux/ Api
import { useDispatch, useSelector } from "react-redux";
import { fetchPostDetails } from "../../redux/slices/homeSlice";
//Loading Spinner
import LoadingSpinner from "../../components/loadingSpinner/LoadingSpinner";
//Pages
import PostDetailsPage from "../../components/posts/PostDetailsPage";
import PlayerContainer from "../../components/videoPlayer/CustomVideo";
import { ChevronLeft } from "@mui/icons-material";
import ExperiencesIcons from "../../assets/icons/headerIcons/ExperiencesIcons";
import ExperiencesHomeItem from "../../components/experiences/ExperiencesHomeItem";
import UpcomingPhases from "../../components/homepage/UpcomingPhases";
import TimelineIcon from "../../assets/icons/headerIcons/TimelineIcon";

const PostDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { postDetails, isLoading, upcomingPhases } = useSelector(
    (state) => state.home
  );
  const { allExperiences } = useSelector((state) => state.experiences);
  const { id, tenancy } = useParams();
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(fetchPostDetails(id, tenancy));
      setIsDataLoaded(true);
    };

    fetchData();
  }, [dispatch, id]);

  if (!isDataLoaded || isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Box>
      {postDetails && Object.keys(postDetails).length !== 0 ? (
        <Grid container>
          {window.innerWidth > 768 && (
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",

                padding: "2px 45px",
                color: "#3D3D3D",
              }}
            >
              <Box
                onClick={() => navigate(-1)}
                sx={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ChevronLeft
                  sx={{
                    fontSize: "22px",
                    marginLeft: "-5px",
                    color: "#3D3D3D",
                  }}
                />
              </Box>
              Back
            </Grid>
          )}

          <Grid
            item
            xs={12}
            md={8}
            sx={{ height: "100vh", overflowY: "scroll" }}
          >
            {window.innerWidth < 768 && postDetails.type === "video" ? (
              <PlayerContainer postDetails={postDetails} />
            ) : (
              <PostDetailsPage postDetails={postDetails} tenancy={tenancy} />
            )}
          </Grid>
          <Grid
            item
            xs={0}
            md={4}
            sx={{ height: "100vh", overflowY: "scroll" }}
          >
            <Grid
              item
              md={12}
              sx={{
                display: {
                  xs: "none",
                  md: "block",
                  marginTop: "25px",
                  padding: "0px 10px",
                },
              }}
            >
              <Grid
                container
                sx={{
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <TimelineIcon
                  isMenu
                  isDesktopActive
                  color={"rgba(61, 61, 61, 0.80)"}
                  width={"23"}
                  height={"23"}
                />
                <Typography
                  sx={{
                    fontSize: "21px",
                    color: "rgba(61, 61, 61, 0.80)",
                    fontFamily: "Fraunces",
                    fontWeight: "400",
                    letterSpacing: 0,
                    marginLeft: "10px",
                  }}
                >
                  Happening this month
                </Typography>
              </Grid>
              <Grid sx={{ padding: "0px 5px 0px 0px" }}>
                {upcomingPhases?.map((phase, index) => {
                  return (
                    <Box key={index}>
                      <UpcomingPhases
                        name={phase?.title}
                        textColor={phase?.text_color}
                        backgroundColor={phase?.color}
                        slug={phase?.slug}
                      />
                    </Box>
                  );
                })}
              </Grid>
              <Grid
                container
                sx={{
                  flexDirection: "row",
                  margin: "25px 0px 15px 0px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <ExperiencesIcons
                  isMenu
                  isDesktopActive
                  color={"rgba(61, 61, 61, 0.80)"}
                  width={"23"}
                  height={"23"}
                />
                <Typography
                  sx={{
                    fontSize: "21px",
                    color: "rgba(61, 61, 61, 0.80)",
                    fontFamily: "Fraunces",
                    fontWeight: "400",
                    letterSpacing: 0,
                    marginLeft: "10px",
                  }}
                >
                  Experiences
                </Typography>
              </Grid>
              <Grid sx={{ padding: "0px 10px 0px 0px" }}>
                {allExperiences?.map((experience) => {
                  return (
                    <ExperiencesHomeItem
                      experience={experience}
                      isHomePage
                      isDesktop
                    />
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100vh",
            }}
          >
            <Box>
              <Box
                onClick={() => navigate(-1)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  cursor: "pointer",
                  padding: "2px 10px",
                  color: "#3D3D3D",
                }}
              >
                <ChevronLeft
                  sx={{
                    fontSize: "22px",
                    marginLeft: "-5px",
                    color: "#3D3D3D",
                  }}
                />
                Back
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "200px",
                }}
              >
                Couldn't fetch post details.
              </Box>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default PostDetails;
