import React from "react";
//Helmet
import { Helmet } from "react-helmet-async";
//Redux
import { useSelector } from "react-redux";

const HelmetPage = ({ pageTitle }) => {
  const { winery } = useSelector((state) => state.auth);

  return (
    <Helmet>
      <title>
        {`${
          winery ? `${winery?.company_name} - ` : ""
        } Wine Grower - ${pageTitle}`}
      </title>
    </Helmet>
  );
};

export default HelmetPage;
