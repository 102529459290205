import React from "react";

const CloseIcon = ({ width, height, color }) => {
  return (
    <svg
      width={width ? width : "26"}
      height={height ? height : "26"}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.81012 4.81C4.96084 4.65906 5.13985 4.53931 5.33689 4.45761C5.53393 4.37592 5.74514 4.33386 5.95845 4.33386C6.17176 4.33386 6.38298 4.37592 6.58002 4.45761C6.77706 4.53931 6.95606 4.65906 7.10679 4.81L13.0001 10.7012L18.8935 4.81C19.0443 4.6592 19.2233 4.53957 19.4203 4.45796C19.6173 4.37635 19.8285 4.33434 20.0418 4.33434C20.2551 4.33434 20.4662 4.37635 20.6633 4.45796C20.8603 4.53957 21.0393 4.6592 21.1901 4.81C21.3409 4.9608 21.4605 5.13983 21.5422 5.33686C21.6238 5.53389 21.6658 5.74507 21.6658 5.95833C21.6658 6.1716 21.6238 6.38277 21.5422 6.5798C21.4605 6.77684 21.3409 6.95586 21.1901 7.10666L15.299 13L21.1901 18.8933C21.4947 19.1979 21.6658 19.611 21.6658 20.0417C21.6658 20.4724 21.4947 20.8854 21.1901 21.19C20.8856 21.4946 20.4725 21.6657 20.0418 21.6657C19.6111 21.6657 19.198 21.4946 18.8935 21.19L13.0001 15.2988L7.10679 21.19C6.80223 21.4946 6.38916 21.6657 5.95845 21.6657C5.52774 21.6657 5.11468 21.4946 4.81012 21.19C4.50556 20.8854 4.33446 20.4724 4.33446 20.0417C4.33446 19.611 4.50556 19.1979 4.81012 18.8933L10.7013 13L4.81012 7.10666C4.65918 6.95594 4.53944 6.77694 4.45774 6.5799C4.37604 6.38285 4.33398 6.17164 4.33398 5.95833C4.33398 5.74502 4.37604 5.53381 4.45774 5.33677C4.53944 5.13972 4.65918 4.96072 4.81012 4.81Z"
        fill={color ? color : "#3D3D3D"}
      />
    </svg>
  );
};

export default CloseIcon;
