import React, {useEffect} from "react";
import {Box, Grid, Typography} from "@mui/material";

//Component
import ExperiencesDiscoverItem from "../../components/experiences/ExperiencesDiscoverItem";
import {useDispatch, useSelector} from "react-redux";
import {fetchDiscoverExperiences} from "../../redux/slices/experiencesSlice";


const AllExperiences = () => {
    const dispatch = useDispatch();
    const {allExperiences} = useSelector((state) => state.experiences);
    const sortedData = [...allExperiences];
    useEffect(() => {
        dispatch(fetchDiscoverExperiences());
    }, []);

    return (
        <Box
            sx={{
                // height: "60vh",
                padding: {
                    xs: "10px 20px",
                    sm: "10px 50px",
                    lg: "10px 50px 10px 60px",
                },
            }}
        >
            {window.innerWidth > 768 && (
                <Typography
                    sx={{
                        fontSize: "30px",
                        color: "#3D3D3D",
                        fontFamily: "Fraunces",
                        fontWeight: "600",
                        margin: "10px 0px 20px 0px",
                    }}
                >
                    Experiences
                </Typography>
            )}
            <Grid
                sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    margin: "0px 0px 15px 0px",
                }}
            >
            </Grid>
            {window.innerWidth < 768 ? (
                <>
                    {
                        sortedData?.map((winery, index) => {
                            return (
                                winery['data']?.map((experience, index) => {
                                    return (
                                        <Box key={index}>
                                            <ExperiencesDiscoverItem experience={experience}
                                                                     tenancy={winery['tenant']}/>
                                        </Box>
                                    );
                                })
                            );
                        })
                    }
                </>
            ) : (
                <>
                    <Grid
                        container
                        sx={{width: "100%", flexDirection: "row", display: "flex"}}
                        gap={2}
                    >
                        {
                            sortedData?.map((winery, index) => {
                                return (
                                    winery['data']?.map((experience, index) => {
                                        return (
                                            <Grid item xs={4} sm={5} md={3.7} lg={3.8} xl={2.8} key={index}>
                                                <ExperiencesDiscoverItem experience={experience}
                                                                         tenancy={winery['tenant']}/>
                                            </Grid>
                                        );
                                    })
                                );
                            })
                        }
                    </Grid>
                </>
            )}
        </Box>
    );
};

export default AllExperiences;
