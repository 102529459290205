import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addPostReactions,
  setExpandedBottomPrompt,
} from "../../redux/slices/homeSlice";
import BottomCommentDrawer from "../drawers/BottomCommentDrawer";
import SharePopupComponent from "../sharePopup/SharePopupComponent";

const CustomVideoOptions = ({
  icon,
  amount,
  type,
  postDetails,
  postId,
  isLiked,
  setIsLiked,
  setReactionsCount,
}) => {
  const dispatch = useDispatch();
  const { access_token } = useSelector((state) => state.auth);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [shareOptionsModal, setShareOptionsModal] = useState(false);

  const handleOptionClick = (e) => {
    if (type === "like") {
      if (access_token) {
        if (isLiked) {
          setReactionsCount(amount - 1);
        } else {
          setReactionsCount(amount + 1);
        }
        setIsLiked(!isLiked);
        let obj = {
          slug: postId,
        };
        dispatch(addPostReactions(obj, postId));
      } else {
        dispatch(setExpandedBottomPrompt(true));
      }
    } else if (type === "comment") {
      e.preventDefault();
      setIsDrawerOpen(true);
    } else if (type === "share") {
      e.preventDefault();
      setShareOptionsModal(true);
    }
  };

  const handleDrawerClose = (e) => {
    // Prevent the click event from propagating to the parent
    e.stopPropagation();

    setIsDrawerOpen(false);
  };

  return (
    <Box
      sx={{
        padding: "10px",
        cursor: "pointer",
        backgroundColor: "rgba(61, 61, 61, 0.40)",
        borderRadius: "10px",
        width: "40px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        margin: "5px",
        minHeight: !amount ? "55px" : "",
      }}
      onClick={(e) => handleOptionClick(e)}
    >
      <Box>{icon}</Box>
      <Box>
        <Typography
          sx={{
            color: "#FFFFFF",
            fontSize: "16px",
            fontFamily: "inherit",
            fontWeight: "500",
            textAlign: "center",
          }}
        >
          {amount}
        </Typography>
      </Box>
      {/* {type === "share" && ( */}
      <SharePopupComponent
        shareOptionsModal={shareOptionsModal}
        setShareOptionsModal={setShareOptionsModal}
        postDetails={postDetails}
      />
      {/* )} */}
      {type === "comment" && (
        <BottomCommentDrawer
          isOpen={isDrawerOpen}
          handleCloseDrawer={handleDrawerClose}
          postId={postId}
        />
      )}
    </Box>
  );
};

export default CustomVideoOptions;
