import {MainApiCall, PrivateApiCall, PublicApiCall} from "./interceptors";
import axios from "axios";

const errorCatch = (error) => {
  if (error.response) {
    if (error.reponse?.data) {
      return error.response?.data;
    }
    return error.response;
  }
  return error;
};

/** ******************************************************************************************* */
/** ************************************** AUTH *********************************************** */
/** ******************************************************************************************* */
//LOGIN
export async function login(data) {
  const resp = await MainApiCall.post("/api/global-login", data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
  return resp;
}

//GOOGLE LOGIN
export async function googleLogin(data) {
  const resp = await PublicApiCall.post("/api/user/google-login", data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
  return resp;
}

//REGISTER
export async function register(data) {
  const resp = await MainApiCall.post("/api/global-register", data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
  return resp;
}

//EDIT PROFILE
export async function saveProfile(data) {
  const resp = await PrivateApiCall.post("/api/user/update-profile", data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
  return resp;
}

//FORGOT PASSWORD
export async function forgotPassword(data) {
  const resp = await PublicApiCall.post("/api/user/reset-password", data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
  return resp;
}

// RESET PASSWORD
export async function resetPassword(data) {
  const resp = await PublicApiCall.post("/api/user/change-password", data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
  return resp;
}

//VERIFY EMAIL
export async function verifyUserEmail(token) {
  const resp = await MainApiCall.get(`/api/global-verify/${token}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
  return resp;
}

export async function verifyUserEmailWithOrder(token, order) {
  const resp = await PublicApiCall.get(`/api/user/verify/${token}/${order}`)
      .then((response) => response)
      .catch((error) => errorCatch(error));
  return resp;
}

export async function verifyGuestOrder(order) {
  const resp = await PublicApiCall.get(`/api/order/verify/${order}`)
      .then((response) => response)
      .catch((error) => errorCatch(error));
  return resp;
}
// GET HOME CONTENT
export async function getFeedContent(data) {
  const resp = await MainApiCall.post("/api/feed", data)
      .then((response) => response)
      .catch((error) => errorCatch(error));
  return resp;
}
/** ******************************************************************************************* */
/** ************************************** HOME *********************************************** */
/** ******************************************************************************************* */
// GET HOME CONTENT
export async function getHomeContent(data) {
  const resp = await PrivateApiCall.post("/api/post/filter", data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
  return resp;
}
// GET HOME FILTERS
export async function getHomeFilters() {
  const resp = await PrivateApiCall.get("/api/get-filters")
    .then((response) => response)
    .catch((error) => errorCatch(error));
  return resp;
}
// GET UPCOMING PHASES
export async function getUpcomingPhases() {
  const resp = await axios.get("https://theset.douq.org/api/phase/get-phases")
    .then((response) => response)
    .catch((error) => errorCatch(error));
  return resp;
}

// GET TIMELINE ARTICLES
export async function getTimelineArticles() {
  const resp = await PrivateApiCall.get("/api/article/get-articles")
    .then((response) => response)
    .catch((error) => errorCatch(error));
  return resp;
}
// GET ABOUT US DETAILS
export async function getAboutUsDetails() {
  const resp = await PrivateApiCall.get("/api/page/get-page/about_us")
    .then((response) => response)
    .catch((error) => errorCatch(error));
  return resp;
}
// SEND FEEDBACK FORM
export async function sendMessageForm(data) {
  const resp = await PrivateApiCall.post("/api/contact/send-contact", data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
  return resp;
}
/** ******************************************************************************************* */
/** *********************************** POSTS ************************************************* */
/** ******************************************************************************************* */
// GET POST DETAILS
export async function getPostDetails(id, tenancy) {
  const resp = await MainApiCall.get(`/api/get-single-post/${id}/${tenancy}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
  return resp;
}

// GET POST COMMENTS
export async function getPostComments(id, tenancy_id) {
  const resp = await MainApiCall.get(`/api/get-post-comments/${id}/${tenancy_id}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
  return resp;
}

// GET POST COMMENTS
export async function addPostComment(data) {
  const resp = await MainApiCall.post("/api/add-comment", data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
  return resp;
}

// GET POST REACTIONS
export async function getPostReactions(id, tenancy) {
  const resp = await MainApiCall.get(`/api/get-reactions/${id}/${tenancy}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
  return resp;
}

// GET POST REACTIONS
export async function addPostReaction(data) {
  const resp = await MainApiCall.post("/api/add-reaction", data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
  return resp;
}

/** ******************************************************************************************* */
/** ********************************** TIMELINE *********************************************** */
/** ******************************************************************************************* */

// GET TIMELINE DATA
export async function getTimeline() {
  const resp = await PrivateApiCall.get("/api/timeline/get-timeline")
    .then((response) => response)
    .catch((error) => errorCatch(error));
  return resp;
}

// GET SELECTED PHASE TIMELINE DATA
export async function getSelectedPhaseTimelineContent(data) {
  const resp = await PrivateApiCall.post("/api/post/get-post-by-month", data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
  return resp;
}

/** ******************************************************************************************* */
/** ******************************** EXPERIENCES ********************************************** */
/** ******************************************************************************************* */

// GET ALL EXPERIENCES
export async function getAllExperiences(category) {
  const resp = await axios.get(`https://theset.douq.org/api/experience/get-experiences/${category}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
  return resp;
}

export async function getDiscoverExperiences() {
  const resp = await MainApiCall.get("/api/discover-more")
    .then((response) => response)
    .catch((error) => errorCatch(error));
  return resp;
}

// GET ONE EXPERIENCE
export async function getOneExperience(id, tenancy) {
  const resp = await MainApiCall.get(
    `/api/get-experiences-details/${id}/${tenancy}`
  )
    .then((response) => response)
    .catch((error) => errorCatch(error));
  return resp;
}
export async function getOneExperienceWithFeature(id) {
  const resp = await PrivateApiCall.get(
    `/api/experience/get-single-experiences/${id}`
  )
    .then((response) => response)
    .catch((error) => errorCatch(error));
  return resp;
}

// EXPERIENCE PAYMENT
export async function experiencePayment(data) {
  const resp = await PrivateApiCall.post("/api/booking/book-experience", data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
  return resp;
}

export async function guestExperiencePayment(data) {
  const resp = await MainApiCall.post("/api/book-experience", data)
      .then((response) => response)
      .catch((error) => errorCatch(error));
  return resp;
}

// GET ALL ORDERS
export async function getAllOrders() {
  const resp = await PrivateApiCall.get("/api/user/get-orders")
    .then((response) => response)
    .catch((error) => errorCatch(error));
  return resp;
}

// GET ORDER DETAILS
export async function getOrderDetails(id) {
  const resp = await PrivateApiCall.get(
    `/api/user/order/get-order-details/${id}`
  )
    .then((response) => response)
    .catch((error) => errorCatch(error));
  return resp;
}

// GET PROFILE
export async function getProfile() {
  const resp = await PrivateApiCall.get("/api/user/profile")
    .then((response) => response)
    .catch((error) => errorCatch(error));
  return resp;
}

export async function getAllCountries() {
  const resp = await MainApiCall.get(
      `/api/get-countries`
  )
      .then((response) => response)
      .catch((error) => errorCatch(error));
  return resp;
}

export async function getAllCities(id) {
  const resp = await MainApiCall.get(
      `/api/get-cities/${id}`
  )
      .then((response) => response)
      .catch((error) => errorCatch(error));
  return resp;
}

export async function getOneExperienceWithSingleFeature(id, tenancy, feature_id) {
  const resp = await MainApiCall.get(
      `/api/get-experiences-details/${id}/${tenancy}/${feature_id}`
  )
      .then((response) => response)
      .catch((error) => errorCatch(error));
  return resp;
}


export async function getAllCategories() {
  const resp = await PrivateApiCall.get("/api/experience/get-categories")
      .then((response) => response)
      .catch((error) => errorCatch(error));
  return resp;
}


export async function addItemToCart(data) {
  const resp = await PrivateApiCall.post("/api/cart/add", data)
      .then((response) => response)
      .catch((error) => errorCatch(error));
  return resp;
}

// GET ALL ORDERS
export async function getCartItems() {
  const resp = await PrivateApiCall.get("/api/cart/get")
      .then((response) => response)
      .catch((error) => errorCatch(error));
  return resp;
}

export async function updateCartSeats(data) {
  const resp = await PrivateApiCall.post("/api/cart/update-seats", data)
      .then((response) => response)
      .catch((error) => errorCatch(error));
  return resp;
}

export async function removeCartItem(data) {
  const resp = await PrivateApiCall.post("/api/cart/delete-item", data)
      .then((response) => response)
      .catch((error) => errorCatch(error));
  return resp;
}

export async function finishCartPayment(data) {
  const resp = await PrivateApiCall.post("/api/cart/finish", data)
      .then((response) => response)
      .catch((error) => errorCatch(error));
  return resp;
}

export async function getUserAddresses() {
  const resp = await PrivateApiCall.get(
      `/api/user/get-addresses`
  )
      .then((response) => response)
      .catch((error) => errorCatch(error));
  return resp;
}