import React from "react";

const HeaderChevronRight = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.25 17.875L15.125 11L8.25 4.125"
        stroke="black"
        strokeWidth="2.70833"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HeaderChevronRight;
