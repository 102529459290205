import React from "react";

const SignOutPinIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="9"
      viewBox="0 0 15 9"
      fill="none"
    >
      <path
        d="M7.5 9L0.138785 -1.38663e-06L14.8612 -9.95557e-08L7.5 9Z"
        fill="white"
      />
    </svg>
  );
};

export default SignOutPinIcon;
