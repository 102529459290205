import React from "react";
import { Box, Grid, Modal, Typography } from "@mui/material";
import {
  FacebookIcon,
  FacebookShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
} from "react-share";

const SharePopupComponent = ({
  shareOptionsModal,
  setShareOptionsModal,
  postDetails,
}) => {
  const hostName = window.location.host;
  let modifiedHostName;

  if (hostName === process.env.REACT_APP_CENTRAL_DOMAIN) {
    modifiedHostName = "";
  } else {
    modifiedHostName = hostName
      .replace(`.${process.env.REACT_APP_CENTRAL_DOMAIN}`, "")
      .trim();
  }

  const handleModalClose = (e) => {
    e.stopPropagation();
    setShareOptionsModal(false);
  };

  return (
    <Modal
      open={shareOptionsModal}
      onClose={(e) => handleModalClose(e)}
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      disableAutoFocus={true}
    >
      <Box
        sx={{
          backgroundColor: "#FFFFFF",
          width: { xs: "200px", sm: "240px" },
          borderRadius: "20px",
          padding: { xs: "10px 20px", md: "20px 40px" },
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Typography
          sx={{
            color: "#3D3D3D",
            fontSize: { xs: "16px", md: "24px" },
            fontWeight: "600",
            fontFamily: "inherit",
            marginTop: "10px",
          }}
        >
          Share this post :
        </Typography>
        <Grid
          container
          sx={{ margin: "20px 0px" }}
          columnGap={window.innerWidth < 250 ? 1 : 3}
        >
          <Grid item>
            <WhatsappShareButton
              url={`${process.env.REACT_APP_APP_SCHEME}://${modifiedHostName}.${process.env.REACT_APP_CENTRAL_DOMAIN}/post-details/${postDetails?.slug}`}
              title={postDetails?.title}
              separator=":: "
            >
              <WhatsappIcon size={window.innerWidth < 768 ? 30 : 40} round />
            </WhatsappShareButton>
          </Grid>
          <Grid item>
            <TelegramShareButton
              url={`${process.env.REACT_APP_APP_SCHEME}://${modifiedHostName}.${process.env.REACT_APP_CENTRAL_DOMAIN}/post-details/${postDetails?.slug}`}
              title={postDetails?.title}
            >
              <TelegramIcon size={window.innerWidth < 768 ? 30 : 40} round />
            </TelegramShareButton>
          </Grid>
          <Grid item>
            <TwitterShareButton
              url={`${process.env.REACT_APP_APP_SCHEME}://${modifiedHostName}.${process.env.REACT_APP_CENTRAL_DOMAIN}/post-details/${postDetails?.slug}`}
              title={postDetails?.title}
            >
              <XIcon size={window.innerWidth < 768 ? 30 : 40} round />
            </TwitterShareButton>
          </Grid>
          <Grid item>
            <FacebookShareButton
              title={postDetails?.title}
              url={`${process.env.REACT_APP_APP_SCHEME}://${modifiedHostName}.${process.env.REACT_APP_CENTRAL_DOMAIN}/post-details/${postDetails?.slug}`}
            >
              <FacebookIcon size={window.innerWidth < 768 ? 30 : 40} round />
            </FacebookShareButton>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default SharePopupComponent;
