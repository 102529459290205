import React, { useEffect, useRef, useState } from "react";
import { Box, Container, Grid } from "@mui/material";
//Mobile Header
import MobileHeader from "../components/layout/MobileHeader";
import BottomSignInPrompt from "../components/layout/BottomSignInPrompt";
//Reduxs
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUserRouteLocation } from "../redux/slices/authSlice";
//Desktop left menu
import LeftDesktopMenu from "../components/layout/LeftDesktopMenu";

const MainLayout = ({ children, showNavigation = true }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const scrollingRef = useRef();
  const [scrolling, setScrolling] = useState(false);

  const { access_token, userRouteLocation } = useSelector(
    (state) => state.auth
  );
  useEffect(() => {
    if (userRouteLocation) {
      navigate(`${userRouteLocation}`);
    }
    dispatch(setUserRouteLocation(""));
  }, []);

  // useEffect(() => {
  //   const handleScroll = (e) => {
  //     if (scrollingRef.current.scrollTop > 100) {
  //       setScrolling(true);
  //     } else {
  //       setScrolling(false);
  //     }
  //   };

  //   if (scrollingRef.current) {
  //     scrollingRef.current.addEventListener("scroll", handleScroll);
  //   }

  //   return () => {
  //     if (scrollingRef.current) {
  //       scrollingRef.current.removeEventListener("scroll", handleScroll);
  //     }
  //   };
  // }, []); // Empty dependency array to run the effect once

  return (
    <Container style={{ padding: 0, height: "100vh", maxWidth: "3000px" }}>
      {window.innerWidth < 768 ? (
        <Box
          sx={{
            height: "auto",
            overflowY: "visible",
            paddingBottom: !access_token && "150px",
          }}
          ref={scrollingRef}
          className={`${scrolling ? "scrolling" : ""}`}
        >
          <MobileHeader showNavigation={showNavigation} />
          <Box>{children}</Box>
        </Box>
      ) : (
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "row",
            backgroundColor: "#F3F2EC",
          }}
          columnSpacing={1}
        >
          <Grid item sm={4} md={3.5} lg={2.5}>
            <LeftDesktopMenu />
          </Grid>
          <Grid
            item
            sm={8}
            md={8.5}
            lg={9.5}
            sx={{
              backgroundColor: "#FFFFFF",
              paddingBottom: !access_token ? "80px" : "0px",
              // height: "100vh",
            }}
          >
            {children}
          </Grid>
        </Grid>
      )}

      {!access_token && <BottomSignInPrompt />}
    </Container>
  );
};

export default MainLayout;
