import { createSlice } from "@reduxjs/toolkit";

import {
  getSelectedPhaseTimelineContent,
  getTimeline,
  getTimelineArticles,
} from "../../config/api";

const initialState = {
  isLoading: false,
  phaseMonthTimelineContentLoading: false,
  error: "",
  timelineContent: [],
  selectedPhaseTimelineContent: {},
  selectedPhaseMonthTimelineContent: [],
  timelineArticles: [],
  selectedPhase: "",
};

const timelineSlice = createSlice({
  name: "timeline",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.selectedPhaseTimelineContent = [];
      state.error = "";
    },
    startPhaseMonthTimelineContentLoading(state) {
      state.phaseMonthTimelineContentLoading = true;
      state.error = "";
    },
    // START LOADING
    startReactionsLoading(state) {
      state.isReactionsLoading = true;
      state.error = "";
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action?.payload;
      state.phaseMonthTimelineContentLoading = false;
    },

    fetchTimelineContentSuccess: (state, action) => {
      state.isLoading = false;
      state.timelineContent = action.payload.data;
    },

    fetchSelectedPhaseTimelineContentSuccess: (state, action) => {
      console.log("action", action.payload);
      state.isLoading = false;
      state.phaseMonthTimelineContentLoading = false;
      if (action.payload.data?.length > 0) {
        state.selectedPhaseMonthTimelineContent = action.payload.data;
      } else {
        state.selectedPhaseTimelineContent = action.payload.data;
        state.selectedPhaseMonthTimelineContent = [];
      }
    },
    fetchTimelineArticlesSuccess: (state, action) => {
      state.isLoading = false;
      state.timelineArticles = action.payload.data;
    },
    setSelectedPhase: (state, action) => {
      state.selectedPhase = action.payload;
    },
  },
});
export const { setSelectedPhase } = timelineSlice.actions;

export function fetchTimelineContent() {
  return async (dispatch) => {
    dispatch(timelineSlice.actions.startLoading());
    try {
      const res = await getTimeline();
      if (res.status === 200) {
        dispatch(timelineSlice.actions.fetchTimelineContentSuccess(res.data));
      } else {
        dispatch(timelineSlice.actions.hasError(res?.data?.message));
      }
      return { status: res.status, message: res?.data?.message };
    } catch (error) {
      dispatch(timelineSlice.actions.hasError(error));
      return { status: 500, message: "Something went wrong" };
    }
  };
}

export function fetchSelectedPhaseTimelineContent(data) {
  return async (dispatch) => {
    dispatch(timelineSlice.actions.startLoading());
    dispatch(timelineSlice.actions.startPhaseMonthTimelineContentLoading());
    try {
      const res = await getSelectedPhaseTimelineContent(data);
      if (res.status === 200) {
        dispatch(
          timelineSlice.actions.fetchSelectedPhaseTimelineContentSuccess(
            res.data
          )
        );
      } else {
        dispatch(timelineSlice.actions.hasError(res?.data?.message));
      }
      return { status: res.status, message: res?.data?.message };
    } catch (error) {
      dispatch(timelineSlice.actions.hasError(error));
      return { status: 500, message: "Something went wrong" };
    }
  };
}

export function fetchTimelineArticles() {
  return async (dispatch) => {
    dispatch(timelineSlice.actions.startLoading());
    try {
      const res = await getTimelineArticles();
      if (res.status === 200) {
        dispatch(timelineSlice.actions.fetchTimelineArticlesSuccess(res.data));
      } else {
        dispatch(timelineSlice.actions.hasError(res?.data?.message));
      }
      return { status: res.status, message: res?.data?.message };
    } catch (error) {
      dispatch(timelineSlice.actions.hasError(error));
      return { status: 500, message: "Something went wrong" };
    }
  };
}
export default timelineSlice.reducer;
