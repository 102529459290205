import { Box } from "@mui/material";
import React from "react";

const HeaderOptionItem = ({ icon, title, isActive, onClick }) => {
  return (
    <Box
      sx={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        borderRadius: "30px",
        backgroundColor: isActive ? "#F5F5F5" : "#FFFFFF",
        minWidth: "80px",
        padding: "2px 10px",
      }}
      onClick={() => onClick()}
    >
      <Box sx={{ height: "18px" }}>{icon}</Box>
      <Box
        sx={{
          color: isActive ? "#A09574" : "#3D3D3D",
          fontWeight: "600",
          fontSize: "12px",
          opacity: 0.7,
        }}
      >
        {title}
      </Box>
    </Box>
  );
};

export default HeaderOptionItem;
