import React from "react";
import {Grid, Typography} from "@mui/material";
const FeedItem = ({post, isHomePage, isDesktop, tenancy}) => {
    return (
        <Grid
            container
            sx={{marginBottom: "15px", cursor: "pointer"}}
            onClick={() => window.open("http://" + tenancy + '.' + post?.front_domain + `/post-details/${post?.slug}`, '_blank')}
        >
            <Grid
                item
                sx={{
                    maxWidth: isDesktop && "300px",
                    width: "100%",
                    height: "220px",
                    borderRadius: "5px",
                    position: "relative",
                    overflow: "hidden",
                }}
            >
                <Grid
                    sx={{
                        width: "100%",
                        height: "100%",
                        background: `linear-gradient(0deg, rgba(0, 0, 0, 0.70) 20.36%, rgba(0, 0, 0, 0.00) 45.81%), url(${
                            post?.image ? "http://"+ process.env.REACT_APP_MAIN_API_DOMAIN +"/uploads/" + tenancy + "/" + post?.image : ""
                        }),  #ECECEC`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        transition: window.innerWidth > 768 && "transform 0.3s ease-in-out",
                        "&:hover": {
                            transform: window.innerWidth > 768 && "scale(1.1)",
                        },
                    }}
                ></Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid
                    container
                    sx={{
                        display: "flex",
                        alignItems: isHomePage
                            ? "flex-start"
                            : window.innerWidth < 768
                                ? "flex-start"
                                : "center",
                        marginTop: isHomePage
                            ? "10px"
                            : window.innerWidth < 768
                                ? "5px"
                                : "15px",
                        flexDirection: isHomePage ? "column" : "row",
                    }}
                >
                    <Grid item xs={7} sm={12}>
                        <Typography
                            sx={{
                                fontFamily: "inherit",
                                fontSize:
                                    window.innerWidth < 768 ? "15px" : {md: "18px", lg: "20px"},
                                color: "#3D3D3D",
                                opacity: 1,
                                fontWeight: "600",
                                letterSpacing: 0,
                            }}
                        >
                            {post?.title}
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: "inherit",
                                fontSize:
                                    window.innerWidth < 768 ? "15px" : {md: "18px", lg: "20px"},
                                color: "#3D3D3D",
                                opacity: 1,
                                fontWeight: "600",
                                letterSpacing: 0,
                            }}
                        >
                            {post?.phase?.title}
                        </Typography>
                    </Grid>

                </Grid>
            </Grid>
        </Grid>
    );
};

export default FeedItem;
