import {createSlice} from "@reduxjs/toolkit";
// import { useNavigate } from "react-router-dom";

import {getAllCountries, getProfile, getUserAddresses, googleLogin, login, saveProfile} from "../../config/api";

const initialState = {
    isLoading: false,
    googleLoginLoading: false,
    error: "",
    user: null,
    profile: null,
    winery: null,
    feedbackContent: null,
    access_token: "",
    email: "",
    userRouteLocation: "",
    addresses: [],
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
            state.error = "";
        },
        //START GOOGLE LOGIN LOADING
        startGoogleLoginLoading(state) {
            state.googleLoginLoading = true;
            state.error = "";
        },
        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.googleLoginLoading = false;
            state.error = action?.payload;
        },
        wineryFetchSuccess: (state, action) => {
            state.winery = action.payload?.data;
            state.isLoading = false;
        },
        feedbackContentPage: (state, action) => {
            state.feedbackContent = action?.payload;
            state.isLoading = false;
            state.googleLoginLoading = false;
        },
        loginSuccess: (state, action) => {
            localStorage.setItem("access_token", action?.payload?.access_token);
            localStorage.setItem("email", action?.payload?.data?.email);
            state.isLoading = false;
            state.user = action.payload.data;
            state.access_token = action?.payload?.access_token;
            state.email = action?.payload?.data?.email;
        },
        googleLoginSuccess: (state, action) => {
            localStorage.setItem("access_token", action?.payload?.access_token);
            localStorage.setItem("email", action?.payload?.data?.email);
            state.isLoading = false;
            state.googleLoginLoading = false;
            state.user = action.payload.data;
            state.access_token = action.payload.access_token;
        },
        logout: (state) => {
            state.user = null;
            // localStorage.clear();
            localStorage.removeItem("access_token");
            localStorage.removeItem("email");
            state.access_token = "";
        },
        setUserRouteLocation: (state, action) => {
            state.userRouteLocation = action.payload;
        },
        fetchProfileSuccess: (state, action) => {
            state.isLoading = false;
            state.profile = action.payload.user;
            state.user = action.payload.user;
        },
        fetchAllAddressesSuccess: (state, action) => {
            state.isLoading = false;
            state.addresses = action.payload.data;
        },
    },
});
export const {wineryFetchSuccess, feedbackContentPage, setUserRouteLocation} =
    authSlice.actions;

export function loginUser(data, navigate, setErrorMessage) {
    return async (dispatch) => {
        dispatch(authSlice.actions.startLoading());
        try {
            const res = await login(data);
            if (res.status === 200) {
                if (res.data?.message === "not_verified") {
                    navigate("/feedback");
                    dispatch(
                        authSlice.actions.feedbackContentPage({
                            type: "not_verified",
                            title: "Verify your email!",
                            message: res?.data?.success?.message,
                        })
                    );
                } else if (res.data?.message === "not_approved") {
                    navigate("/feedback");
                    dispatch(
                        authSlice.actions.feedbackContentPage({
                            type: "not_approved",
                            title: "You’re on the Waitlist",
                            message: res?.data?.success?.message,
                        })
                    );
                } else {
                    dispatch(authSlice.actions.loginSuccess(res.data));
                    dispatch(authSlice.actions.feedbackContentPage({}));
                }
            } else {
                dispatch(authSlice.actions.hasError(res?.data?.message));
                setErrorMessage(
                    res?.data?.message
                        ? res?.data?.message
                        : "Something went wrong, please try again later."
                );
            }
            return {status: res.status, message: res?.data?.message};
        } catch (error) {
            dispatch(authSlice.actions.hasError(error));
            setErrorMessage("Something went wrong, please try again later.");
            return {status: 500, message: "Something went wrong"};
        }
    };
}

export function googleLoginUser(data, navigate) {
    return async (dispatch) => {
        dispatch(authSlice.actions.startGoogleLoginLoading());
        try {
            const res = await googleLogin(data);
            if (res.status === 200) {
                if (res.data?.message === "not_verified") {
                    navigate("/feedback");
                    dispatch(
                        authSlice.actions.feedbackContentPage({
                            type: "not_verified",
                            title: "Verify your email!",
                            message: res?.data?.success?.message,
                        })
                    );
                } else if (res.data?.message === "not_approved") {
                    navigate("/feedback");
                    dispatch(
                        authSlice.actions.feedbackContentPage({
                            type: "not_approved",
                            title: "You’re on the Waitlist",
                            message: res?.data?.success?.message,
                        })
                    );
                } else {
                    dispatch(authSlice.actions.googleLoginSuccess(res.data));
                    dispatch(authSlice.actions.feedbackContentPage({}));
                    navigate("/home");
                }
            } else {
                dispatch(authSlice.actions.hasError(res?.data?.message));
            }
            return {status: res.status, message: res?.data?.message};
        } catch (error) {
            dispatch(authSlice.actions.hasError(error));
            return {status: 500, message: "Something went wrong"};
        }
    };
}

export function logoutUser(navigate) {
    return async (dispatch) => {
        dispatch(authSlice.actions.logout());
        navigate("/");
    };
}

//FECTH PROFILE
export function fetchProfile() {
    return async (dispatch) => {
        dispatch(authSlice.actions.startLoading());
        try {
            const res = await getProfile();
            if (res.status === 200) {
                dispatch(authSlice.actions.fetchProfileSuccess(res.data));
            } else {
                dispatch(authSlice.actions.hasError(res?.data?.message));
            }
            return {status: res.status, message: res?.data?.message};
        } catch (error) {
            dispatch(authSlice.actions.hasError(error));
            return {status: 500, message: "Something went wrong"};
        }
    };
}

//FECTH PROFILE
export function startLoading() {
    return async (dispatch) => {
        dispatch(authSlice.actions.startLoading());
    };
}

export function fetchAllAddresses() {
    return async (dispatch) => {
        dispatch(authSlice.actions.startLoading());
        try {
            const res = await getUserAddresses();
            if (res.status === 200) {
                dispatch(authSlice.actions.fetchAllAddressesSuccess(res.data));
            } else {
                dispatch(authSlice.actions.hasError(res?.data?.message));
            }
            return {status: res.status, message: res?.data?.message};
        } catch (error) {
            dispatch(authSlice.actions.hasError(error));
            return {status: 500, message: "Something went wrong"};
        }
    };
}

export default authSlice.reducer;
