import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid, Typography } from "@mui/material";

//Icons
import HeaderChevronRight from "../../assets/icons/headerIcons/HeaderChevronRight";
import HomeIcon from "../../assets/icons/headerIcons/HomeIcon";
import MenuIcon from "../../assets/icons/headerIcons/MenuIcon";
import TimelineIcon from "../../assets/icons/headerIcons/TimelineIcon";
import ExperiencesIcons from "../../assets/icons/headerIcons/ExperiencesIcons";
//Redux
import { useSelector } from "react-redux";
//Components
import HeaderOptionItem from "./HeaderOptionItem";
import HeaderLeftDrawer from "../drawers/HeaderLeftDrawer";

const MobileHeader = ({ showNavigation = true }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { winery } = useSelector((state) => state.auth);

  const [errorImage, setErrorImage] = useState(false);

  const [activeOption, setActiveOption] = useState("home");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [bgImage, setBgImage] = useState(winery?.banner);

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };
  const handleOptionClick = (option) => {
    if (option !== activeOption) {
      setActiveOption(option);
      navigate(`/${option === "home" ? "" : option}`);
    }
  };

  useEffect(() => {
    if (location?.pathname === "/") {
      setActiveOption("home");
    } else if (location?.pathname === "/timeline") {
      setActiveOption("timeline");
    } else if (location?.pathname === "/experiences") {
      setActiveOption("experiences");
    }
  }, [location]);

  useEffect(() => {
    // Check if the original image URL is not empty
    if (winery?.banner) {
      const img = new Image();
      img.src = winery?.banner;

      img.onload = () => {
        // Original image loaded successfully
        setBgImage(winery?.banner);
      };

      img.onerror = () => {
        console.log("Image failed to load");
        // Set the fallback image
        setBgImage(
          require("../../assets/images/headerImages/header-image-background.png")
        );
      };
    } else {
      // If the original image URL is empty, set the fallback image
      setBgImage(
        require("../../assets/images/headerImages/header-image-background.png")
      );
    }
  }, [winery?.banner]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid
          container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            // backgroundImage: `url(${bgImage})`,
            backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), url(${bgImage})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            height: "90px",
            padding: "0px 20px",
          }}
        >
          <Grid item sx={{ cursor: "pointer" }} onClick={handleDrawerOpen}>
            <MenuIcon />
          </Grid>
          <Grid
            item
            sx={{
              width: "auto",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {!errorImage ? (
              <img
                src={winery?.logo}
                alt={winery?.company_name}
                onError={() => setErrorImage(true)}
                style={{
                  width: "auto",
                  height: "50px",
                  color: "#3D3D3D",
                  fontSize: "20px",
                }}
              />
            ) : (
              <>
                <Typography
                  sx={{
                    fontFamily: "inherit",
                    fontSize: "18px",
                    color: "#3D3D3D",
                  }}
                >
                  {winery?.company_name}
                </Typography>
              </>
            )}
          </Grid>
          <Grid item onClick={() => navigate("/about-us")}>
            <HeaderChevronRight />
          </Grid>
        </Grid>
      </Grid>
      {showNavigation && (
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            margin: "10px 0px",
          }}
        >
          {!winery?.hide_home && (
            <HeaderOptionItem
              icon={<HomeIcon active={activeOption === "home"} />}
              title="Home"
              isActive={activeOption === "home"}
              onClick={() => handleOptionClick("home")}
            />
          )}
          {!winery?.hide_timeline && (
            <HeaderOptionItem
              icon={<TimelineIcon active={activeOption === "timeline"} />}
              title="Timeline"
              isActive={activeOption === "timeline"}
              onClick={() => handleOptionClick("timeline")}
            />
          )}
          <HeaderOptionItem
            icon={<ExperiencesIcons active={activeOption === "experiences"} />}
            title="Experiences"
            isActive={activeOption === "experiences"}
            onClick={() => handleOptionClick("experiences")}
          />
        </Grid>
      )}

      <HeaderLeftDrawer
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
      />
    </Grid>
  );
};

export default MobileHeader;
