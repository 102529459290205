import { Avatar, Box, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import { getRandomColor } from "../../utils/colors";
import moment from "moment";

const CommentComponent = ({ item, isCommentDrawer, isDesktop }) => {
  const avatarColor = getRandomColor(item?.user[0]);
  const postDate = moment(item?.date, "DD-MM-YYYY h:mm A").fromNow();

  return (
    <>
      {isDesktop ? (
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", alignItems: "center", flexDirection: "row" }}
          >
            <Box>
              <Avatar
                alt={item?.user}
                src="/"
                sx={{ width: 50, height: 50, backgroundColor: avatarColor }}
              />
            </Box>
            <Box>
              <Typography
                sx={{
                  fontFamily: "inherit",
                  fontSize: "16px",
                  color: isCommentDrawer ? "#FBFBFB" : "#3D3D3D",
                  fontWeight: "700",
                  margin: "0px 10px 0px 5px",
                  maxWidth: { sm: "300px", lg: "400px" },
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {item?.user}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontFamily: "inherit",
                  fontSize: { sm: "10px", md: "12px", lg: "14px" },
                  color: isCommentDrawer ? "#848689" : "#3D3D3D",
                  fontWeight: "500",
                }}
              >
                {postDate?.charAt(0).toUpperCase() + postDate?.slice(1)}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ paddingLeft: "38px" }}>
            <Typography
              sx={{
                fontFamily: "inherit",
                fontSize: "16px",
                color: isCommentDrawer ? "#FBFBFB" : "rgba(61, 61, 61, 0.80)",
                fontWeight: "400",
              }}
            >
              {item?.body}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", alignItems: "center", flexDirection: "row" }}
          >
            <Box>
              <Avatar
                alt={item?.user}
                src="/"
                sx={{ width: 32, height: 32, backgroundColor: avatarColor }}
              />
            </Box>
            <Box>
              <Typography
                sx={{
                  fontFamily: "inherit",
                  fontSize: "13px",
                  color: isCommentDrawer ? "#FBFBFB" : "#3D3D3D",
                  fontWeight: "600",
                  margin: "0px 10px 0px 7px",
                  maxWidth: { xs: "190px", sm: "300px" },
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {item?.user}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontFamily: "inherit",
                  fontSize: "10px",
                  color: isCommentDrawer ? "#848689" : "#3D3D3D",
                  fontWeight: "500",
                }}
              >
                {postDate?.charAt(0).toUpperCase() + postDate?.slice(1)}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ paddingLeft: "38px" }}>
            <Typography
              sx={{
                fontFamily: "inherit",
                fontSize: "13px",
                color: isCommentDrawer ? "#FBFBFB" : "#3D3D3D",
                fontWeight: "400",
              }}
            >
              {item?.body}
            </Typography>
            {!isCommentDrawer && (
              <Divider
                sx={{
                  backgroundColor: "#3D3D3D33",
                  width: "90%",
                  margin: "10px 0px",
                }}
              />
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default CommentComponent;
