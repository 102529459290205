import React from "react";

const CameraIcon = ({ width, height }) => {
  return (
    <svg width={width ? width : "31"} height={height ? height : "30"} viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_i_45_1955)">
      <rect width="31" height="30" rx="15" fill="#EBEADF"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M13.8335 22H17.1665C19.5072 22 20.678 22 21.5187 21.4283C21.8815 21.1818 22.194 20.8636 22.4382 20.4919C23 19.6363 23 18.444 23 16.0609C23 13.677 23 12.4855 22.4382 11.6299C22.194 11.2582 21.8815 10.94 21.5187 10.6935C20.9787 10.3256 20.3022 10.1941 19.2665 10.1475C18.7722 10.1475 18.347 9.76637 18.2502 9.27248C18.1763 8.91071 17.9842 8.58651 17.7064 8.35466C17.4286 8.12282 17.0821 7.99755 16.7255 8.00004H14.2745C13.5335 8.00004 12.8953 8.53281 12.7498 9.27248C12.653 9.76637 12.2278 10.1475 11.7335 10.1475C10.6985 10.1941 10.022 10.3264 9.48125 10.6935C9.11871 10.9401 8.80653 11.2583 8.5625 11.6299C8 12.4855 8 13.677 8 16.0609C8 18.444 8 19.6356 8.56175 20.4919C8.80475 20.8621 9.11675 21.1802 9.48125 21.4283C10.322 22 11.4927 22 13.8335 22ZM15.5 12.879C13.7743 12.879 12.3748 14.3031 12.3748 16.0601C12.3748 17.8179 13.7743 19.242 15.5 19.242C17.2257 19.242 18.6252 17.8179 18.6252 16.0609C18.6252 14.3031 17.2257 12.879 15.5 12.879ZM15.5 14.1515C14.465 14.1515 13.625 15.0062 13.625 16.0609C13.625 17.1148 14.465 17.9696 15.5 17.9696C16.535 17.9696 17.375 17.1148 17.375 16.0609C17.375 15.0062 16.535 14.1515 15.5 14.1515ZM19.0415 13.5152C19.0415 13.1637 19.3212 12.879 19.667 12.879H20.4995C20.8445 12.879 21.125 13.1637 21.125 13.5152C21.1234 13.6855 21.0567 13.8481 20.9396 13.9674C20.8224 14.0867 20.6644 14.1529 20.5002 14.1515H19.667C19.5856 14.1523 19.5049 14.1365 19.4295 14.105C19.354 14.0734 19.2853 14.0268 19.2272 13.9677C19.1691 13.9086 19.1228 13.8383 19.0909 13.7606C19.0591 13.683 19.0423 13.5996 19.0415 13.5152Z" fill="#3D3D3D"/>
      </g>
      <defs>
      <filter id="filter0_i_45_1955" x="0" y="0" width="33" height="34" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dx="2" dy="4"/>
      <feGaussianBlur stdDeviation="2"/>
      <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
      <feBlend mode="normal" in2="shape" result="effect1_innerShadow_45_1955"/>
      </filter>
      </defs>
    </svg>
  );
};

export default CameraIcon;
