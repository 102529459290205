import React from "react";

const ClockIcon = () => {
  return (
    <svg
      width="52"
      height="52"
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26 52C11.6402 52 0 40.3598 0 26C0 11.6402 11.6402 0 26 0C40.3598 0 52 11.6402 52 26C52 40.3598 40.3598 52 26 52ZM26 46.8C31.5165 46.8 36.8071 44.6086 40.7078 40.7078C44.6086 36.8071 46.8 31.5165 46.8 26C46.8 20.4835 44.6086 15.1929 40.7078 11.2922C36.8071 7.39142 31.5165 5.2 26 5.2C20.4835 5.2 15.1929 7.39142 11.2922 11.2922C7.39142 15.1929 5.2 20.4835 5.2 26C5.2 31.5165 7.39142 36.8071 11.2922 40.7078C15.1929 44.6086 20.4835 46.8 26 46.8ZM28.6 26H39V31.2H23.4V13H28.6V26Z"
        fill="#3D3D3D"
        fillOpacity="0.8"
      />
      <defs>
        <clipPath id="clip0_52_322">
          <rect width="52" height="52" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ClockIcon;
