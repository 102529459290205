import React from "react";

const EllipsisIcon = () => {
  return (
    <svg
      width="34"
      height="20"
      viewBox="0 0 34 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0007 11.667C9.55862 11.667 9.1347 11.4914 8.82214 11.1788C8.50958 10.8663 8.33398 10.4424 8.33398 10.0003C8.33398 9.5583 8.50958 9.13438 8.82214 8.82181C9.1347 8.50925 9.55862 8.33366 10.0007 8.33366C10.9257 8.33366 11.6673 9.07533 11.6673 10.0003C11.6673 10.4424 11.4917 10.8663 11.1792 11.1788C10.8666 11.4914 10.4427 11.667 10.0007 11.667Z"
        fill="#3D3D3D"
        fillOpacity="0.8"
      />
      <path
        d="M17.0007 11.667C16.5586 11.667 16.1347 11.4914 15.8221 11.1788C15.5096 10.8663 15.334 10.4424 15.334 10.0003C15.334 9.5583 15.5096 9.13438 15.8221 8.82181C16.1347 8.50925 16.5586 8.33366 17.0007 8.33366C17.9257 8.33366 18.6673 9.07533 18.6673 10.0003C18.6673 10.4424 18.4917 10.8663 18.1792 11.1788C17.8666 11.4914 17.4427 11.667 17.0007 11.667Z"
        fill="#3D3D3D"
        fillOpacity="0.8"
      />
      <path
        d="M24.0007 11.667C23.5586 11.667 23.1347 11.4914 22.8221 11.1788C22.5096 10.8663 22.334 10.4424 22.334 10.0003C22.334 9.5583 22.5096 9.13438 22.8221 8.82181C23.1347 8.50925 23.5586 8.33366 24.0007 8.33366C24.9257 8.33366 25.6673 9.07533 25.6673 10.0003C25.6673 10.4424 25.4917 10.8663 25.1792 11.1788C24.8666 11.4914 24.4427 11.667 24.0007 11.667Z"
        fill="#3D3D3D"
        fillOpacity="0.8"
      />
    </svg>
  );
};

export default EllipsisIcon;
