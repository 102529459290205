import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Footer from "../components/layout/Footer";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const AuthLayout = ({ children }) => {
  const navigate = useNavigate();

  const { access_token, winery } = useSelector((state) => state.auth);
  const [bgImage, setBgImage] = useState(winery?.background);

  useEffect(() => {
    if (access_token) {
      navigate("/");
    }
  }, [access_token]);

  useEffect(() => {
    // Check if the original image URL is not empty
    if (winery?.background) {
      const img = new Image();
      img.src = winery?.background;

      img.onload = () => {
        // Original image loaded successfully
        setBgImage(winery?.background);
      };

      img.onerror = () => {
        // Original image failed to load, set the fallback image
        setBgImage(require("../assets/images/login-logo.jpeg"));
      };
    } else {
      // If the original image URL is empty, set the fallback image
      setBgImage(require("../assets/images/login-logo.jpeg"));
    }
  }, [winery?.background]);

  return (
    <Box style={{ padding: 0 }}>
      <Box
        style={{
          minHeight: "85vh",
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        {children}
      </Box>

      <Footer />
    </Box>
  );
};

export default AuthLayout;
