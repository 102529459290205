import React, { useState } from "react";

//CSS
import "./auth.css";

//Icons
import SignUpIcon from "../../assets/icons/SignUpIcon";
//MUI components
import { Box, Container, Grid, Button, Typography } from "@mui/material";
//Validation and Formik
import * as yup from "yup";
import { Form, Formik } from "formik";
import AuthInputField from "../../components/inputs/AuthInputFIeld";
import { useNavigate } from "react-router-dom";
//Redux/ Api
import { register } from "../../config/api";
import { feedbackContentPage } from "../../redux/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
//Google Sign in component
import GoogleSignIn from "../../components/googleComponent/GoogleSignIn";
//Header Logo
import HeaderLogo from "../../components/headerLogo/HeaderLogo";
//Helmet Component
import HelmetPage from "../../components/helmetPage/HelmetPage";

const Register = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { winery } = useSelector((state) => state.auth);

  const [errorMessage, setErrorMessage] = useState("");

  const validationSchema = yup.object({
    email: yup
      .string()
      .email("Invalid email format")
      .required("Email is required"),
    password: yup
      .string()
      .min(8, "At least 8 characters")
      .matches(/[a-z]/, "At least one lowercase character")
      .matches(/[A-Z]/, "At least one uppercase character")
      .matches(/[0-9]/, "At least one numeric character")
      .matches(/[!@#$%^&*-]/, "At least one special character (@,!,#, etc).")
      .required("Password is required"),
  });
  const handleSubmit = async (values) => {
    let obj = {
      first_name: values.email,
      last_name: values.email,
      email: values.email,
      password: values.password,
    };

    await register(obj).then((res) => {
      if (res.status === 200) {
        if (res.data.message === "user_approved") {
          navigate("/feedback");
          dispatch(
            feedbackContentPage({
              type: "user_approved",
              title: "Registered Successfully!",
              message: res?.data?.success?.message,
            })
          );
        } else if (res.data.message === "not_verified") {
          navigate("/feedback");
          dispatch(
            feedbackContentPage({
              type: "not_verified",
              title: "Verify your email!",
              message: res?.data?.success?.message,
            })
          );
        } else if (res.data.message === "not_approved") {
          navigate("/feedback");
          dispatch(
            feedbackContentPage({
              type: "not_approved",
              title: "You’re on the Waitlist",
              message: res?.data?.success?.message,
            })
          );
        }
      } else {
          setErrorMessage(res?.data?.message);
      }
    });
  };

  return (
    <Container className="main-container">
      <HelmetPage pageTitle={"Sign Up"} />

      <HeaderLogo winery={winery} />

      <Box className="form-container">
        <Box className="header-icon">
          <SignUpIcon />
        </Box>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({
            values,
            handleChange,
            handleBlur,
            touched,
            errors,
            isSubmitting,
          }) => (
            <Form>
              <Grid container className="grid-container">
                <div className="form-title">Sign Up</div>

                <Grid item xs={12}>
                  <AuthInputField
                    name="email"
                    placeholder="Enter your email"
                    type="email"
                    variant="outlined"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email ? errors.email : ""}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AuthInputField
                    name="password"
                    placeholder="Enter your password"
                    type="password"
                    variant="outlined"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.password && Boolean(errors.password)}
                    helperText={touched.password ? errors.password : ""}
                  />
                </Grid>
                {errorMessage && (
                  <Grid
                    item
                    xs={12}
                    sx={{
                      textAlign: "left",
                      width: "100%",
                      color: "#d32f2f",
                      fontSize: "15px",
                      maxWidth: { xs: "250px", sm: "310px", md: "400px" },
                    }}
                  >
                    <Box>{errorMessage}</Box>
                  </Grid>
                )}
                <Grid item xs={12} sx={{ padding: "0px 20px" }}>
                  <Typography
                    sx={{
                      fontFamily: "inherit",
                      color: "#3D3D3D",
                      fontSize: "8px",
                    }}
                  >
                    Use an 8+ character combination of upper and lowercase
                    letters, digits and symbols
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    // disabled={isSubmitting}
                    sx={{
                      backgroundColor: "#3D3D3D",
                      borderRadius: "10px",
                      marginTop: "10px",
                      textTransform: "none",
                      height: { xs: "40px", sm: "45px" },
                      width: { xs: "250px", sm: "310px", md: "350px" },
                      fontSize: { xs: "16px", sm: "20px" },
                      fontFamily: "inherit",
                      "&:hover": {
                        backgroundColor: "#c88080ff",
                      },
                    }}
                  >
                    Sign Up
                  </Button>
                </Grid>

                <Grid item xs={12}>
                  <GoogleSignIn isRegister />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "center",
                    flexDirection: "row",
                    marginTop: { xs: "25px", sm: "33px" },
                  }}
                >
                  <Box
                    sx={{
                      marginTop: "10px",
                      fontSize: { xs: "11px", sm: "18px" },
                      color: "rgba(0, 0, 0, 0.60)",
                    }}
                  >
                    Already have an account?
                  </Box>
                  <Box
                    onClick={() => navigate(`/login`)}
                    sx={{
                      color: "#3D3D3D",
                      fontSize: { xs: "12px", sm: "19px" },
                      fontWeight: "600",
                      cursor: "pointer",
                      marginLeft: "5px",
                    }}
                  >
                    Sign In
                  </Box>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </Container>
  );
};

export default Register;
