import { Box, Typography } from "@mui/material";
import React from "react";
import DiscountIcon from "../../assets/icons/DiscountIcon";

const ExperienceDiscountItem = ({ discount }) => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: 10,
        left: 10,
        width: "128px",
        height: "28px",
        borderRadius: "5px",
        backgroundColor: "rgba(61, 61, 61, 0.80)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <DiscountIcon />
      <Typography
        sx={{
          fontFamily: "inherit",
          color: "#FFFFFF",
          fontSize: { xs: "12px", lg: "14px" },
          marginLeft: "3px",
        }}
      >
        {discount}% discount
      </Typography>
    </Box>
  );
};

export default ExperienceDiscountItem;
