import {createSlice} from "@reduxjs/toolkit";

import {
    experiencePayment,
    getAllExperiences,
    getDiscoverExperiences,
    getAllOrders,
    getOneExperience,
    getOneExperienceWithFeature,
    getOrderDetails,
    guestExperiencePayment,
    getAllCountries,
    getAllCities,
    getOneExperienceWithSingleFeature,
    getAllCategories, addItemToCart, getCartItems, updateCartSeats, removeCartItem, finishCartPayment,
} from "../../config/api";

const initialState = {
    isLoading: false,
    isPaymentLoading: false,
    loadingButton: false,
    error: "",
    paymentError: "",
    countries: [],
    cities: [],
    categories: [],
    allExperiences: [],
    experienceDetails: {},
    selectedFeature: {},
    successfulPayment: {},
    allOrders: [],
    cartItems: [],
    orderDetails: {},
};

const experiencesSlice = createSlice({
    name: "experiences",
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
            state.selectedPhaseTimelineContent = [];
            state.error = "";
        },
        // START PAYMENT LOADING
        startPaymentLoading(state) {
            state.isPaymentLoading = true;
            state.paymentError = "";
        },
        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action?.payload;
        },

        // HAS PAYMENT ERROR
        hasPaymentError(state, action) {
            state.isPaymentLoading = false;
            state.paymentError = action?.payload;
            state.isLoading = false;
            state.loadingButton = false;
        },

        fetchAllExperiencesSuccess: (state, action) => {
            state.isLoading = false;
            state.allExperiences = action.payload.data;
        },
        fetchAllCategoriesSuccess: (state, action) => {
            state.isLoading = false;
            state.categories = action.payload.data;
        },

        fetchOneExperienceSuccess: (state, action) => {
            state.isLoading = false;
            state.experienceDetails = action.payload.data;
            state.paymentError = "";
        },
        setSelectedFeature: (state, action) => {
            state.selectedFeature = action.payload;
        },
        purchaseOneExperienceSuccess: (state, action) => {
            state.isLoading = false;
            state.isPaymentLoading = false;
            state.successfulPayment = action.payload.data;
            state.paymentError = "";
        },
        fetchAllOrdersSuccess: (state, action) => {
            state.isLoading = false;
            state.allOrders = action.payload.user;
        },
        fetchCartSuccess: (state, action) => {
            state.isLoading = false;
            state.cartItems = action.payload.order;
        },
        updateCartSuccess: (state, action) => {
            state.isLoading = false;
        },
        fetchOrderDetailsSuccess: (state, action) => {
            state.isLoading = false;
            state.orderDetails = action.payload.order;
        },
        fetchAllCountriesSuccess: (state, action) => {
            state.isLoading = false;
            state.countries = action.payload.data;
            state.paymentError = "";
        },
        fetchAllCitiesSuccess: (state, action) => {
            state.isLoading = false;
            state.cities = action.payload.data;
            state.paymentError = "";
        },
    },
});
export const {setSelectedFeature, hasPaymentError} = experiencesSlice.actions;

//FECTH ALL EXPERIENCES
export function fetchAllExperiences(category) {
    return async (dispatch) => {
        dispatch(experiencesSlice.actions.startLoading());
        try {
            const res = await getAllExperiences(category);
            if (res.status === 200) {
                dispatch(experiencesSlice.actions.fetchAllExperiencesSuccess(res.data));
            } else {
                dispatch(experiencesSlice.actions.hasError(res?.data?.message));
            }
            return {status: res.status, message: res?.data?.message};
        } catch (error) {
            dispatch(experiencesSlice.actions.hasError(error));
            return {status: 500, message: "Something went wrong"};
        }
    };
}

//Aggregation layer
export function fetchDiscoverExperiences() {
    return async (dispatch) => {
        dispatch(experiencesSlice.actions.startLoading());
        try {
            const res = await getDiscoverExperiences();
            if (res.status === 200) {
                dispatch(experiencesSlice.actions.fetchAllExperiencesSuccess(res.data));
            } else {
                dispatch(experiencesSlice.actions.hasError(res?.data?.message));
            }
            return {status: res.status, message: res?.data?.message};
        } catch (error) {
            dispatch(experiencesSlice.actions.hasError(error));
            return {status: 500, message: "Something went wrong"};
        }
    };
}

// FETCH ONE EXPERIENCE
export function fetchOneExperience(id, tenancy) {
    return async (dispatch) => {
        dispatch(experiencesSlice.actions.startLoading());
        try {
            const res = await getOneExperience(id, tenancy);
            if (res.status === 200) {
                dispatch(experiencesSlice.actions.fetchOneExperienceSuccess(res.data));
            } else {
                dispatch(experiencesSlice.actions.hasError(res?.data?.message));
            }
            return {status: res.status, message: res?.data?.message};
        } catch (error) {
            dispatch(experiencesSlice.actions.hasError(error));
            return {status: 500, message: "Something went wrong"};
        }
    };
}

export function fetchOneExperienceWithFeature(id, feature_id) {
    return async (dispatch) => {
        dispatch(experiencesSlice.actions.startLoading());
        try {
            const res = await getOneExperienceWithFeature(id, feature_id);
            if (res.status === 200) {
                dispatch(experiencesSlice.actions.fetchOneExperienceSuccess(res.data));
            } else {
                dispatch(experiencesSlice.actions.hasError(res?.data?.message));
            }
            return {status: res.status, message: res?.data?.message};
        } catch (error) {
            dispatch(experiencesSlice.actions.hasError(error));
            return {status: 500, message: "Something went wrong"};
        }
    };
}

// EXPERIENCE PAYMENT
export function purchaseOneExperience(
    data,
    navigate,
    paymentMethodSelected,
    setBuyExperienceModal
) {
    return async (dispatch) => {
        dispatch(experiencesSlice.actions.startPaymentLoading());
        try {
            const res = await experiencePayment(data);
            if (res.status === 200) {
                dispatch(
                    experiencesSlice.actions.purchaseOneExperienceSuccess(res.data)
                );
                if (
                    paymentMethodSelected?.method === "stripe" ||
                    paymentMethodSelected?.method === "wallet"
                ) {
                    navigate("/order-success");
                } else {
                    if (window.innerWidth < 768) {
                        navigate(-1);
                    }
                    window.location.href = res?.data?.charge?.hosted_url;
                    setBuyExperienceModal(false);
                }
            } else {
                dispatch(experiencesSlice.actions.hasPaymentError(res?.data?.message));
            }
            return {status: res.status, message: res?.data?.message};
        } catch (error) {
            dispatch(experiencesSlice.actions.hasPaymentError(error));
            return {status: 500, message: "Something went wrong"};
        }
    };
}

export function purchaseGuestExperience(
    data,
    navigate
) {
    return async (dispatch) => {
        dispatch(experiencesSlice.actions.startPaymentLoading());
        try {
            const res = await guestExperiencePayment(data);
            if (res.status === 200) {
                dispatch(
                    experiencesSlice.actions.purchaseOneExperienceSuccess(res.data)
                );
                navigate("/guest-order-success");

            } else {
                if(res?.data?.error?.message) {
                    dispatch(experiencesSlice.actions.hasPaymentError(res?.data?.error?.message));
                } else {
                    dispatch(experiencesSlice.actions.hasPaymentError(res?.data?.message));
                }
            }
            return {status: res.status, message: res?.data?.message};
        } catch (error) {
            dispatch(experiencesSlice.actions.hasPaymentError(error));
            return {status: 500, message: "Something went wrong"};
        }
    };
}

//FECTH ALL ORDERS
export function fetchAllOrders() {
    return async (dispatch) => {
        dispatch(experiencesSlice.actions.startLoading());
        try {
            const res = await getAllOrders();
            if (res.status === 200) {
                dispatch(experiencesSlice.actions.fetchAllOrdersSuccess(res.data));
            } else {
                dispatch(experiencesSlice.actions.hasError(res?.data?.message));
            }
            return {status: res.status, message: res?.data?.message};
        } catch (error) {
            dispatch(experiencesSlice.actions.hasError(error));
            return {status: 500, message: "Something went wrong"};
        }
    };
}

//FECTH ORDER DETAILS
export function fetchOrderDetails(id) {
    return async (dispatch) => {
        dispatch(experiencesSlice.actions.startLoading());
        try {
            const res = await getOrderDetails(id);
            if (res.status === 200) {
                dispatch(experiencesSlice.actions.fetchOrderDetailsSuccess(res.data));
            } else {
                dispatch(experiencesSlice.actions.hasError(res?.data?.message));
            }
            return {status: res.status, message: res?.data?.message};
        } catch (error) {
            dispatch(experiencesSlice.actions.hasError(error));
            return {status: 500, message: "Something went wrong"};
        }
    };
}

export function fetchAllCountries() {
    return async (dispatch) => {
        dispatch(experiencesSlice.actions.startLoading());
        try {
            const res = await getAllCountries();
            if (res.status === 200) {
                dispatch(experiencesSlice.actions.fetchAllCountriesSuccess(res.data));
            } else {
                dispatch(experiencesSlice.actions.hasError(res?.data?.message));
            }
            return {status: res.status, message: res?.data?.message};
        } catch (error) {
            dispatch(experiencesSlice.actions.hasError(error));
            return {status: 500, message: "Something went wrong"};
        }
    };
}

export function fetchAllCities(id) {
    return async (dispatch) => {
        dispatch(experiencesSlice.actions.startLoading());
        try {
            const res = await getAllCities(id);
            if (res.status === 200) {
                dispatch(experiencesSlice.actions.fetchAllCitiesSuccess(res.data));
            } else {
                dispatch(experiencesSlice.actions.hasError(res?.data?.message));
            }
            return {status: res.status, message: res?.data?.message};
        } catch (error) {
            dispatch(experiencesSlice.actions.hasError(error));
            return {status: 500, message: "Something went wrong"};
        }
    };
}


export function fetchOneExperienceWithSingleFeature(id, tenancy, feature_id) {
    return async (dispatch) => {
        dispatch(experiencesSlice.actions.startLoading());
        try {
            const res = await getOneExperienceWithSingleFeature(id, tenancy, feature_id);
            if (res.status === 200) {
                dispatch(experiencesSlice.actions.fetchOneExperienceSuccess(res.data));
            } else {
                dispatch(experiencesSlice.actions.hasError(res?.data?.message));
            }
            return {status: res.status, message: res?.data?.message};
        } catch (error) {
            dispatch(experiencesSlice.actions.hasError(error));
            return {status: 500, message: "Something went wrong"};
        }
    };
}

export function fetchAllCategories() {
    return async (dispatch) => {
        dispatch(experiencesSlice.actions.startLoading());
        try {
            const res = await getAllCategories();
            if (res.status === 200) {
                dispatch(experiencesSlice.actions.fetchAllCategoriesSuccess(res.data));
            } else {
                dispatch(experiencesSlice.actions.hasError(res?.data?.message));
            }
            return {status: res.status, message: res?.data?.message};
        } catch (error) {
            dispatch(experiencesSlice.actions.hasError(error));
            return {status: 500, message: "Something went wrong"};
        }
    };
}


// ADD TO CART
export function addToCart(
    data
) {
    return async (dispatch) => {
        dispatch(experiencesSlice.actions.startPaymentLoading());
        try {
            const res = await addItemToCart(data);
            if (res.status === 200) {
                alert('Added to cart successfully')
            } else {
                // dispatch(experiencesSlice.actions.hasPaymentError(res?.data?.message));
            }
            return {status: res.status, message: res?.data?.message};
        } catch (error) {
            dispatch(experiencesSlice.actions.hasPaymentError(error));
            return {status: 500, message: "Something went wrong"};
        }
    };
}

//Fetch cart items
export function fetchCartItems() {
    return async (dispatch) => {
        dispatch(experiencesSlice.actions.startLoading());
        try {
            const res = await getCartItems();
            if (res.status === 200) {
                dispatch(experiencesSlice.actions.fetchCartSuccess(res.data));
            } else {
                dispatch(experiencesSlice.actions.hasError(res?.data?.message));
            }
            return {status: res.status, message: res?.data?.message};
        } catch (error) {
            dispatch(experiencesSlice.actions.hasError(error));
            return {status: 500, message: "Something went wrong"};
        }
    };
}

export function updateCartNumberOfPersons(data, setNumberOfPersons) {
    return async (dispatch) => {
        dispatch(experiencesSlice.actions.startLoading());
        try {
            const res = await updateCartSeats(data);
            if (res.status === 200) {
                dispatch(experiencesSlice.actions.updateCartSuccess());
            } else {
                dispatch(experiencesSlice.actions.hasError(res?.data?.message));
            }
            setNumberOfPersons(data.nbOfSeats)
            return {status: res.status, message: res?.data?.message};
        } catch (error) {
            dispatch(experiencesSlice.actions.hasError(error));
            return {status: 500, message: "Something went wrong"};
        }
    };
}

export function deleteItemFromCart(item_id) {
    return async (dispatch) => {
        dispatch(experiencesSlice.actions.startLoading());
        try {
            const res = await removeCartItem(item_id);
            if (res.status === 200) {
                dispatch(experiencesSlice.actions.updateCartSuccess());
            } else {
                dispatch(experiencesSlice.actions.hasError(res?.data?.message));
            }
            return {status: res.status, message: res?.data?.message};
        } catch (error) {
            dispatch(experiencesSlice.actions.hasError(error));
            return {status: 500, message: "Something went wrong"};
        }
    };
}

export function finishCart(
    data,
    navigate,
    paymentMethodSelected,
    setBuyModal
) {
    return async (dispatch) => {
        dispatch(experiencesSlice.actions.startPaymentLoading());
        try {
            const res = await finishCartPayment(data);
            if (res.status === 200) {
                dispatch(
                    experiencesSlice.actions.purchaseOneExperienceSuccess(res.data)
                );
                if (
                    paymentMethodSelected?.method === "stripe" ||
                    paymentMethodSelected?.method === "wallet"
                ) {
                    if(data?.order_id == null) {
                        navigate('/login')
                    }
                    navigate("/all-orders");
                } else {
                    if (window.innerWidth < 768) {
                        navigate(-1);
                    }
                    window.location.href = res?.data?.charge?.hosted_url;
                    setBuyModal(false);
                }
            } else {
                dispatch(experiencesSlice.actions.hasPaymentError(res?.data?.message));
            }
            return {status: res.status, message: res?.data?.message};
        } catch (error) {
            dispatch(experiencesSlice.actions.hasPaymentError(error));
            return {status: 500, message: "Something went wrong"};
        }
    };
}


export default experiencesSlice.reducer;
